import { useId } from "react";
import { data } from "./data";
import SFBIItem from "./item";
import * as Styled from "./style";

const SFBInfo = () => {
  const idComponent = useId();

  return (
    <Styled.Wrapper>
      <Styled.Text>
        <span>Improve processes, culture and customer experience </span>
        <span> to drive revenue and scale</span>.
      </Styled.Text>
      {data.map((item, index) => (
        <SFBIItem key={idComponent + "." + index} {...item} />
      ))}
    </Styled.Wrapper>
  );
};

export default SFBInfo;
