import { IDataSoftwareTeam } from "./type";
import team_1 from "../../assets/img/software/team/team_1.png";
import team_2 from "../../assets/img/software/team/team_2.png";
import team_3 from "../../assets/img/software/team/team_3.png";
import team_4 from "../../assets/img/software/team/team_4.png";
import team_5 from "../../assets/img/software/team/team_5.png";
import team_6 from "../../assets/img/software/team/team_6.png";
import team_7 from "../../assets/img/software/team/team_7.png";
import team_8 from "../../assets/img/software/team/team_8.png";
import team_9 from "../../assets/img/software/team/team_9.png"

export const dataSoftwareTeam: IDataSoftwareTeam[] = [
  { img: team_1, title: "Business Analytics" },
  { img: team_2, title: "Project Managers" },
  { img: team_3, title: "UX/UI Progressionals" },
  { img: team_4, title: "UX/UI Designers" },
  { img: team_5, title: "Service Designers" },
  { img: team_6, title: "Application Developers" },
  { img: team_7, title: "Front-End Developers" },
  { img: team_8, title: "Back-End Developers" },
  {img: team_9, title: "Quality Assurance Testing",},
];
