import { IDataOurWork } from "../types";
import imgFirst from "../../../assets/img/works/peerpay/phone_1.png";
import imgSecond from "../../../assets/img/works/peerpay/phone_2.png";

export const peerpayWork: IDataOurWork = {
  id: "peerpay",
  imgFolder: "/assets/works/peerpay",
  mainColor: "#91EFFC",
  img1: imgFirst,
   img2: imgSecond,
  headerInfo: {
    title: "Peerpay",
    text: (
      <div>
        Peerpay is a mobile payment app which enables people from around the
        world and within PNG to transact with Vendors.
        <br />
        <br />
        PNG has been an isolated cash dominated economy. The security of mobile
        payments and the ability to transact remotely provides a new level of
        freedom to users.
        <br />
        <br />
        Peerpay were looking for ways to bring the convenience of mobile
        payments to PNG retailers and service providers.
      </div>
    ),
  },
  about: [
    { title: "Client", text: "PeerPay" },
    { title: "Industry", text: "Payments" },
    { title: "Budget", text: "$200,000+" },
    { title: "Scope", text: "App Development 480 hours" },
    {
      title: "App",
      text: "Web app and mobile app developed in .Net, Flutter and Blockchain",
    },
  ],
  clients_brief: {
    title: "Client’s brief",
    text: "Peerpay briefed Orbit to create a mobile payment system within an app that would be simple to use and work from international destinations to pay vendors and service providers in PNG..",
  },
  business_objectives: [
    {
      title: "REMOTE PAYMENTS",
      text: "The app can be used from countries outside of PNG to pay retailers within PNG",
    },
    {
      title: "SAFETY",
      text: "The app reduces an individual’s reliance on carrying large amounts of cash",
    },
    {
      title: "TRANSPARENCY",
      text: "The app will provide the user with control of their transactions including; exact details of product and services, approval of vendors and the time and date of transactions.",
    },
    {
      title: "TRANSACTION HISTORY",
      text: "The app provides a transaction history and proof of payment",
    },
    {
      title: "SECURITY",
      text: "The app will receive requests for payments from approved individuals or vendors",
    },
    {
      title: "CREDIBILITY",
      text: "Star ratings and customer reviews will ensure that vendors are providing an acceptable level of service and quality to the community",
    },
    {
      title: "CURRENCY EXCHANGE",
      text: "Peerpay allows users to make payments from AUD into PGK or PGK into AUD",
    },
  ],
  problem:
    "Many people living in PNG are reliant on cash for payments, posing a high personal risk to individuals. Retailers and service providers are not set up for e-commerce which limits access to online sales.",
  solution:
    "A mobile peer to peer payment system will allow users to transact with approved retailers using the app. The app will give the user control of the item of service purchased, proof of purchase and delivery options including click and collect.",
  approach: [
    {
      title: "UNDERSTANDING",
      dates: ["WEEK 1", "WEEK 4"],
      colors: [
        "linear-gradient(180deg, rgba(36, 0, 255, 0.12) 0%, rgba(36, 0, 255, 0.2) 49.48%, rgba(36, 0, 255, 0.12) 100%)",
        " #2400FF",
      ],
      tasks: [
        "Stakeholder Interviews",
        "User Interviews",
        "Surveys",
        "Competitor Analysis",
        "Field Studies",
        "User Journeys",
        "User Personas",
      ],
    },
    {
      title: "EXPLORATION",
      dates: ["WEEK 5", "WEEK 9"],
      colors: [
        "linear-gradient(180deg, rgba(255, 0, 229, 0.12) 0%, rgba(255, 0, 229, 0.2) 48.96%, rgba(255, 0, 229, 0.12) 100%);",
        " #FF00E5",
      ],
      tasks: [
        "Paper Prototypes",
        "Wireframes",
        "Hi-Fi Prototypes",
        "User Flow",
        "Interactive Prototypes",
      ],
    },
    {
      title: "TESTING",
      dates: ["WEEK 10", "WEEK 14"],
      colors: [
        "linear-gradient(180deg, rgba(255, 168, 0, 0.12) 0%, rgba(255, 168, 0, 0.2) 49.48%, rgba(255, 168, 0, 0.12) 100%);",
        " #FFA800",
      ],
      tasks: [
        "Hi-Fi Usability Testing",
        "A/B Testing",
        "Customer Service Feedback",
        "Analytics & KPI Review",
      ],
    },
    {
      title: "DEVELOPMENT",
      dates: ["WEEK 15", "WEEK 39"],
      colors: [
        "linear-gradient(180deg, rgba(0, 255, 25, 0.12) 0%, rgba(0, 255, 117, 0.2) 49.48%, rgba(0, 255, 240, 0.12) 100%)",
        " #00FF19",
      ],
      tasks: [
        "Agile Project Methodology",
        "Feature Release plan",
        "2 Weekly sprints",
      ],
    },
  ],
  firstInfo: {
    title: "MVP- Peerpay e-commerce purchases",
    text: (
      <div>
        Peerpay opens the door for approved vendors throughout PNG to accept
        payment for goods and services from within PNG and within Australia,
        through the convenience of a mobile app. This has the potential to
        expand globally. This service means tens of thousands of retailers and
        service providers can now transact remotely with Peerpay users without
        their own investment in an e-commerce store. Peerpay is linked to a
        distribution system which means many of these goods can be delivered
        door to door.
      </div>
    ),
  },
  secondInfo: {
    title: "MVP- safer transactions for users",
    text: (
      <div>
        Carrying large amounts of cash comes with personal risk and PNG is no
        exception. The use of Peerpay means users can make transactions within
        the safety of their home without having to travel with cash. They can
        approve payment with a request for payment directly from a retailer,
        service provider or transfer directly into the Peerpay account of
        another app user. They have the peace of mind of knowing all their
        transactions are verified and within the secure environment of the
        Peerpay app.
      </div>
    ),
  },
  coverInfo: {
    title: "Peerpay",
    text: "Peerpay is a mobile payment app which enables people from around the world and within PNG to transact with Vendors.",
    tag: ["B2C", "Mobile App", "iOS", "Android"],
  },
};
