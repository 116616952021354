const SINTThirdBlock = () => {
  return (
    <div>
      <div>
        Depending on your audience, you may wish to develop in IOS or Android
        first. Native iOS apps are developed in Swift or Objective-C while
        native Android apps are written in Java.
      </div>
      <br />
      <div>Orbit has a dedicated IOS and Android native app team.</div>
    </div>
  );
};

export default SINTThirdBlock;
