import { FC } from "react";
import { IDataSoftwareSolution } from "../../../../commonData/softwareSolution/types";

import * as Styled from "./style";

const SSBItem: FC<IDataSoftwareSolution> = ({ title, img, text }) => {
  return (
    <div>
      <Styled.Img img={img} />
      <Styled.Title>{title}</Styled.Title>
      <Styled.Text>{text}</Styled.Text>
    </div>
  );
};

export default SSBItem;
