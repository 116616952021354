import styled from "styled-components";

export const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  margin-top: 80px;
margin-bottom: 8px;
  text-align: center;

  @media (max-width: 768px) {
    width: 50%;
    text-align: start;
  }

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;

  text-align: center;

  margin-bottom: 40px;

  @media (max-width: 768px) {
    width: 50%;
    text-align: start;
  }
`;
