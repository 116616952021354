import { FC, ReactNode } from "react";
import * as Styled from "./style";

export type TModeButton = "red" | "white" | "black";
export type TSizeButton = "s" | "m" | "l" | "xl";

interface IProps {
  children: ReactNode;
  mode?: TModeButton;
  block?: boolean;
  size?: TSizeButton;
  onClick?: () => void;
  classes?: string;
  width?:string
}

const ButtonComponent: FC<IProps> = ({
  children,
  mode = "red",
  size = "m",
  block = false,
  onClick,
  classes, 

}) => {
  return (
    <Styled.Wrapper  className={`${classes && classes}`} size={size} block={block} mode={mode} onClick={onClick}>
      {children}
    </Styled.Wrapper>
  );
};

export default ButtonComponent;
