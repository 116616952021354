import AskOurTeamComponent from "../../components/askOurTeam";
import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import InsightsFirstBlock from "./firstBlock";
import InsightsSecondBlock from "./secondBlock";
import * as Styled from "./style";

const InsightsPage = () => {
  return (
    <WrappperHeader>
      <InsightsFirstBlock />
      <InsightsSecondBlock />
      <Styled.WrapperAsk>
        <AskOurTeamComponent />
      </Styled.WrapperAsk>
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default InsightsPage;
