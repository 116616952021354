import { FC, useId } from "react";
import icon from "../../assets/img/flexibility-icon.png";
import { IInfo } from "../../type";
import * as Styled from "./style";

interface IProps {
  data: [IInfo, IInfo];
}

const ProblemSolutionComponent: FC<IProps> = ({ data }) => {
  const idComponent = useId();

  return (
    <Styled.Wrapper>
      {data.map(({ title, text }, index) => (
        <Styled.WrapperBlock
          key={idComponent + "." + index}
          bgType={!index ? "reverse" : "straight"}
        >
          <Styled.Title>{title}</Styled.Title>
          <Styled.Text>{text}</Styled.Text>
        </Styled.WrapperBlock>
      ))}

      <Styled.WrapperImg>
        <img src={icon} alt="icon"/>
      </Styled.WrapperImg>
    </Styled.Wrapper>
  );
};

export default ProblemSolutionComponent;
