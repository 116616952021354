import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 600px;
  display: flex;
  align-items: flex-end;
`;

export const WrapperVideo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  & video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const WrapperInfo = styled.div`
  position: relative;
  z-index: 2;
  width: 89%;
  /* width: 60%;
  min-width: 830px; */
  padding: 8px 12px;
  display: flex;
  justify-content: end;

  background-color: #ed0c32;

  @media (max-width: 1400px) {
    justify-content: center;
  }
`;

export const Info = styled.div`
  width: 809px;
  font-weight: 700;
  font-size: 40px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;
