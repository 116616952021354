import styled from "styled-components";

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;

  margin: 16px 0;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
`;
export const ImgContainer = styled.div`
margin-right: auto;
margin-left: auto;
max-width: 1300px;
img{
  width: 100%;
}
`