import { IData, IValue } from "./types";

const getInitValue = <T>(value: T): IValue<T> => ({ value, error: "" });

export const initData: IData = {
  budget: getInitValue(""),
  company: getInitValue(""),
  email: getInitValue(""),
  firstName: getInitValue(""),
  hearAbout: getInitValue(""),
  lastName: getInitValue(""),
  phone: getInitValue(""),
};
