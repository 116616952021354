import { IDataAboutPeople } from "./types";
import * as People from "./people";

export const dataAboutPeople: IDataAboutPeople[] = [
  People.Human_1,
  People.Louise_Kelly,
  People.Pavel_Martinov,
  People.Human_2,
  People.Human_3,
  People.Human_4,
  People.Human_5,
  People.Human_6,
  People.Human_7,
  People.Human_8,
  People.Human_9,
  People.Human_10,
];
