const SIISOThirdBlock = () => {
  return (
    <div>
      <div>
        App Orbit have an experienced team of IOS app developers using Objective C
        and Swift 3.0 programming language, Xcode, Firebase and Testflight,
        creating applications for iPadOS, iOS15, watch OS, and SharePlay for app
        sharing.
      </div>
      <br />
      <div>
        Orbit’s design team have outstanding credentials for IOS App ecosystem
        design. They apply Apple's Human Interface Guidelines to mobile design
        patterns, best practice UIUX with their deep subject matter expertise of
        designing across the senses including body movement, environmental
        immersion, voice and visual experiences. AR, IoT and wearables have
        expanded these experiences beyond screens into a 360 real life
        experience.
      </div>
    </div>
  );
};

export default SIISOThirdBlock;
