import styled from "styled-components";

export const WrapperBG = styled.div`
  height: 600px;
  background: linear-gradient(267.22deg, #212121 0%, #0c0c0c 100%);
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
  padding: 60px 0;
`;

export const Title = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  max-width: 850px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    text-align: start;
  }
`;

interface IImg {
  img: string;
}

export const Img = styled.div<IImg>`
  width: 100%;
  max-width: 1296px;
  height: 438px;

  position: absolute;

  top: 35%;

  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius:10px;
`;
