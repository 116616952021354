import { FC, useMemo } from "react";
import ArrowIcon from "../../../assets/img/arrow";
import CloseIcon from "../../../assets/img/close";
import { dataTechnologies } from "../../../commonData/technologies/data";
import WrapperModal from "../../../wrappers/modal";
import * as Styled from "./style";
import useSize from "../../../utils/useSize";


interface IProps {
  idOpened: number;
  setIdOpened: (id: number) => void;
}

const TCModal: FC<IProps> = ({ idOpened, setIdOpened }) => {
  const {textModal, title, modalImg, img } = useMemo(() => {
    return dataTechnologies[idOpened - 1];
  }, [idOpened]);

  const onClickArrow = (add: number) => {
    const sum = idOpened + add;
    if (!sum) {
      setIdOpened(dataTechnologies.length);
      return;
    }
    if (sum === dataTechnologies.length + 1) {
      setIdOpened(1);
      return;
    }

    setIdOpened(idOpened + add);
  };
  const { width } = useSize();
  return (
    <WrapperModal>
      <Styled.WrapperCenter>
        <Styled.Wrapper>
          <Styled.WrapperPnone>
            <Styled.Phone phone={`${width >768 ? img : modalImg}`} />
          </Styled.WrapperPnone>
          <Styled.WrapperInfo>
            <Styled.WrapperClose>
              <div onClick={() => setIdOpened(0)}>
                <CloseIcon fill="#ffffff" />
              
              </div>
            </Styled.WrapperClose>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Text>{textModal}</Styled.Text>
            <Styled.WrapperArrow>
              <div onClick={() => onClickArrow(-1)}>
                <ArrowIcon circle />
              </div>
              <div onClick={() => onClickArrow(1)}>
                <ArrowIcon circle />
              </div>
            </Styled.WrapperArrow>
          </Styled.WrapperInfo>
        </Styled.Wrapper>
      </Styled.WrapperCenter>
    </WrapperModal>
  );
};

export default TCModal;
