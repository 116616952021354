import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../components/button";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const SofrwareThirdBlock = () => {
  const navigate = useNavigate()

  return (
    <WrapperContainer
      width="100%"
      minWidth="1400px"
      position="start"
      padding="unset"
    >
      <Styled.Wrapper>
        <Styled.Img />
        <Styled.WrapperInfo>
          <Styled.Quote />
          <Styled.Text>
            We have worked with App Orbit over 5 years and the have improved our
            productivity by
          </Styled.Text>
          <Styled.TextX>4x.</Styled.TextX>
          <Styled.TextLast>
            Our App Orbit custom software enables employees work more
            autonomously and remotely. Our purpose built software and real time
            data access has empowered our frontline to solve customer problems
            on the spot.
          </Styled.TextLast>
          <Styled.ButtonWrapper>
             <ButtonComponent
              mode="white"
              size="l"
            onClick={() => navigate("/works/peerpay")}
          >
            READ CASE STUDY
          </ButtonComponent>
          </Styled.ButtonWrapper>
         
        </Styled.WrapperInfo>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default SofrwareThirdBlock;
