import { dataSoftwareTeam } from "../../../commonData/softwareTeam/data";
import SEBHeader from "./header";
import SEBItem from "./item";
import * as Styled from "./style";

const SoftwareEighthBlock = () => {
  return (
    <div>
      <SEBHeader />

      <Styled.WrapperItems>
        {dataSoftwareTeam.map((item, index) => (
         
            <SEBItem {...item} key={index}/>
          
        ))}
      </Styled.WrapperItems>
    </div>
  );
};

export default SoftwareEighthBlock;
