import { useContext } from "react";
import { WorkContext } from "..";
import { useLocation } from "react-router-dom";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";
import phone_1 from '../../../assets/img/works/peerpay/phone_1.png';
import phone_2 from '../../../assets/img/works/peerpay/phone_2.png';
import phone_3 from '../../../assets/img/works/peerpay/phone_3.png';
import phone_4 from '../../../assets/img/works/peerpay/phone_4.png';
import phone_1c from '../../../assets/img/works/coach/phone_1.png';
import phone_2c from '../../../assets/img/works/coach/phone_2.png';
import phone_3c from '../../../assets/img/works/coach/phone_3.png';
import phone_4c from '../../../assets/img/works/coach/phone_4.png';
import phone_1hrm from '../../../assets/img/works/hrm/phone_7.png';
import phone_2hrm from '../../../assets/img/works/hrm/phone_4.png';
import phone_2hrmOld from '../../../assets/img/works/hrm/phone_4-old.png';
import phone_3hrm from '../../../assets/img/works/hrm/phone_8.png';
import phone_4hrm from '../../../assets/img/works/hrm/phone_9.png';


const WorkFirstBlock = () => {
  const { headerInfo } = useContext(WorkContext);
  const { title, text } = headerInfo;
  const {pathname, } = useLocation()
  const { width } = useSize();

  return (
    <Styled.Wrapper>
      <WrapperContainer width="1100px">
        <Styled.WrapperTitle>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Text>{text}</Styled.Text>
        </Styled.WrapperTitle>
      </WrapperContainer>
     <Styled.WrapperPhones>
        <Styled.WrapperColumn translateY={width > 834 ? "-36%" : "-70%"}>
          {pathname === "/works/peerpay" && (
            <>
          <Styled.Phone img={`${phone_3}`} />
          <Styled.Phone img={`${phone_2}`} />
          <Styled.Phone img={`${phone_3}`} />
            </>
          )}
           {pathname === "/works/coach" && (
            <>
          <Styled.Phone img={`${phone_3c}`} />
          <Styled.Phone img={`${phone_2c}`} />
          <Styled.Phone img={`${phone_3c}`} />
            </>
          )}
          {pathname === "/works/hrm" && (
            <>
          <Styled.Phone img={`${phone_2hrm}`} />
          <Styled.Phone img={`${phone_1hrm}`} />
          <Styled.Phone img={`${phone_2hrm}`} />
            </>
          )}
        </Styled.WrapperColumn>
        <Styled.WrapperColumn translateY={width > 834 ? "-60%" : "-5%"}>
          {pathname === "/works/peerpay" && (
            <>
          <Styled.Phone img={`${phone_4}`} />
          <Styled.Phone img={`${phone_1}`} />
          <Styled.Phone img={`${phone_3}`} />
            </>)}
           {pathname === "/works/coach" && (
            <>
          <Styled.Phone img={`${phone_4c}`} />
          <Styled.Phone img={`${phone_1c}`} />
          <Styled.Phone img={`${phone_3c}`} />
            </>
          )}
           {pathname === "/works/hrm" && (
            <>
          <Styled.Phone img={`${phone_3hrm}`} />
          <Styled.Phone img={`${phone_4hrm}`} />
          <Styled.Phone img={`${phone_2hrmOld}`} />
            </>
          )}
        </Styled.WrapperColumn>
      </Styled.WrapperPhones>
    </Styled.Wrapper>
  );
};

export default WorkFirstBlock;

  

