const FirstBlockSystems = () => {
  return (
    <div>
      <div>
      Rising customer experience expectations, the pace of digital disruption and the increased need for business efficiencies require a greater integration of systems and applications. These requirements include;
      </div>
      <br />
     
      <ul>
        <li>Personalised, customer experience that recognises their transactional history across the lifecycle</li><br/>
        <li>Business efficiencies gains from frictionless and accessible data enterprise wide.</li><br/>
        <li>The removal of business siloes and the associated service interruptions.</li><br/>
        
      </ul>
    </div>
  );
};


export default FirstBlockSystems;