import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 100px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 50px;

  text-align: center;
`;
