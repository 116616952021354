import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  @media (max-width: 1270px) {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    
  }
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 40px 0;
  }
`;

interface IWrapperBlock {
  bgType: "straight" | "reverse";
}

const getBG = (type: IWrapperBlock["bgType"]) => {
  const dataColor = ["#212121", "#0C0C0C"];
  if (type === "straight") {
    [dataColor[0], dataColor[1]] = [dataColor[1], dataColor[0]];
  }

  return `linear-gradient(267.22deg, ${dataColor[0]} 0%, ${dataColor[1]} 100%);`;
};

export const WrapperBlock = styled.div<IWrapperBlock>`
  /* width: calc(50% - 20px); */
  max-width: 619px;
  padding: 20px;
  background: ${({ bgType }) => getBG(bgType)};

  border-radius: 10px;
@media (max-width: 1270px) {
    max-width: 432px;
  }
  @media (max-width: 991px) {
    width: 100%;
    max-width: 619px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 13px;
`;

export const WrapperImg = styled.div`
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > img {
    width: 100%;
    height: 100%;
  }

`;
