import { useContext } from "react";
import { WorkContext } from "..";
import WorkInfoWrapper from "../wrappers/infoWrapper";
import WTBBusinessObjective from "./businessObjectives";

const WorkThirdBlock = () => {
  const { clients_brief } = useContext(WorkContext);
  return (
    <WorkInfoWrapper {...clients_brief}>
      <WTBBusinessObjective />
    </WorkInfoWrapper>
  );
};

export default WorkThirdBlock;
