import { IDataTechnologies } from "./types";
import iot from "../../assets/img/technologies/iot/phone.slider.png";
import iotBg from "../../assets/img/technologies/iot/bg.slider.png";
import iotModal from "../../assets/img/technologies/iot/phone.mobile.png";
import iotBgMob from "../../assets/img/technologies/iot/bg.mobile.png";
import ai from "../../assets/img/technologies/ai/phone.slider.png";
import aiBg from "../../assets/img/technologies/ai/bg.slider.png";
import aiModal from "../../assets/img/technologies/ai/phone.mobile.png";
import aiBgMob from "../../assets/img/technologies/ai/bg.mobile.png";
import geolocation from "../../assets/img/technologies/geolocation/phone.slider.png";
import geolocationBg from "../../assets/img/technologies/geolocation/bg.slider.png";
import geolocationModal from "../../assets/img/technologies/geolocation/phone.mobile.png";
import geolocationBgMob from "../../assets/img/technologies/geolocation/bg.mobile.png";
import vr from "../../assets/img/technologies/vr/phone.slider.png";
import vrBg from "../../assets/img/technologies/vr/bg.slider.png";
import vrModal from "../../assets/img/technologies/vr/phone.mobile.png";
import vrBgMob from "../../assets/img/technologies/vr/bg.mobile.png";
import chatbot from "../../assets/img/technologies/chatbots/phone.slider.png";
import chatbotBg from "../../assets/img/technologies/chatbots/bg.slider.png";
import chatbotModal from "../../assets/img/technologies/chatbots/phone.mobile.png";
import chatbotBgMob from "../../assets/img/technologies/chatbots/bg.mobile.png";
import automation from "../../assets/img/technologies/automation/phone.slider.png";
import automationBg from "../../assets/img/technologies/automation/bg.slider.png";
import automationModal from "../../assets/img/technologies/automation/phone.mobile.png";
import automationBgMob from "../../assets/img/technologies/automation/bg.mobile.png";
import blockchain from "../../assets/img/technologies/blockchain/phone.slider.png";
import blockchainBg from "../../assets/img/technologies/blockchain/bg.slider.png";
import blockchainModal from "../../assets/img/technologies/blockchain/phone.mobile.png";
import blockchainBgMob from "../../assets/img/technologies/blockchain/bg.mobile.png";
import ar from "../../assets/img/technologies/ar/phone.slider.png";
import arBg from "../../assets/img/technologies/ar/bg.slider.png";
import arModal from "../../assets/img/technologies/ar/phone.mobile.png";
import arBgMob from "../../assets/img/technologies/ar/bg.mobile.png";

 

export const dataTechnologies: IDataTechnologies[] = [
  {
    title: "IOT",
    textModal: (
      <div>
        Mobile app driven IoT has many applications, including management of
        household devices and interactions with smart buildings.
        <br />
        <br />
        Smart homes can manage energy usage, turn on and off heating, lighting
        and appliances.
        <br />
        <br />
        Sensors in smart buildings can identify the movement of individuals
        through apps geolocation and personalize their experience.
        <br />
        <br />
        Mobile apps are used as a command centre, an IoT gateway connects
        devices into a single system stored on the cloud, where all data is
        aggregated and automated.
      </div>
    ),
    text: "Mobile app driven IoT has many applications, including management of household devices and interactions with smart buildings.",
    imgFolder: "/assets/technologies/iot",
    img: iot,
    bgImg: iotBg,
    modalImg: iotModal,
    modalBg: iotBgMob
    
  },
  {
    title: "Artificial Intelligence",
    textModal: (
      <div>
        AI is used for data mining to collect and analyse data, enabling
        powerful relevant, personalized and predictive features in app.
        <br />
        <br />
        AI can automate user tasks, such as the fastest route to a chosen
        destination.
        <br />
        <br />
        It can recognise faces and uses biometrics to recognize images such as
        plants and food to give specific information.
        <br />
        <br />
        AI enables speech, a conversational user interface allows apps to obey
        voices, while speech synthesis technology allows mobiles to speak in
        reply.
        <br />
        <br />
        AI’s face and voice recognition skills are attuned to read emotions and
        sentiment.
      </div>
    ),
    text: "AI is used for data mining to collect and analyse data, enabling powerful relevant, personalized and predictive features in app.",
    imgFolder: "/assets/technologies/ai",
    img: ai,
    bgImg: aiBg,
    modalImg: aiModal,
    modalBg: aiBgMob
  },
  {
    title: "Geolocation",
    textModal: (
      <div>
        Location based apps enable geo targeted information and services.
        <br />
        <br />
        Your geo targeting enables the weather app to know your location, a ride
        share app to pick up at your address and GPS trackers to take you on
        foot, in car or indoors to your chosen destination.
        <br />
        <br />
        Geolocation is being used to innovate new products such as children
        trackers and route trackers in fitness apps.
        <br />
        <br />
        Geo location has also allowed innovation of services such as; click and
        collect, last minute events and travel, dating apps for people nearby
        and location check in status.
      </div>
    ),
    text: "Your geo targeting enables the weather app to know your location, a ride share app to pick up at your address and GPS trackers to take you on foot, in car or indoors to your chosen destination.",
    imgFolder: "/assets/technologies/geolocation",
    img: geolocation,
    bgImg: geolocationBg,
    modalImg: geolocationModal,
    modalBg: geolocationBgMob
  },
  {
    title: "Virtual Reality",
    textModal: (
      <div>
        VR apps immerse the viewer in an imaginary world where objects can be
        activated and users can move in all directions.
        <br />
        <br />
        VR apps have been applied in education, real estate, retail, health,
        entertainment and fitness. The rapidly growing number of applications
        for VR include;
        <br />
        <br />
        <ul>
          <li><b>Zoom</b> VR can allow you to zoom 'into' or 'out of' your natural world
        view. You can soar like an eagle around the world using Google Earth VR,
        or deep dive into the human anatomy,</li>
          <li><b>Retail</b> VR allows you to virtually
        try on clothes in stores like the Ebay-Myer world first virtual store or
        use ASOS body scanning technology to get the right size of clothes.</li>
            <li><b>Movies</b> 3D movies in virtual reality brings the IMAX theatre experience
        in your own home, both Netflix and Youtube have their own 3D movie
        collection.</li>
              <li><b> Real Estate</b> VR has brought virtual inspections to the world
        of real estate, using Matterport cameras to film a building 3D
        walkthrough</li>
                <li><b>Fitness</b>  The Supernatural work out app can set you up with a
            virtual boxing coach</li>
          <li><b>Medicine</b> VR in medicine is helping PSTD survivors
        recover through exposure therapy</li>

        </ul>
          
           
      </div>
    ),
    text: "VR apps immerse the viewer in an imaginary world where objects can be activated and users can move in all directions.",
    imgFolder: "/assets/technologies/vr",
    img: vr,
    bgImg: vrBg,
    modalImg: vrModal,
    modalBg: vrBgMob
  },
  {
    title: "Chatbots",
    textModal: (
      <div>
        Chatbots are tools that allow people to converse over the internet with
        AI assistants. Messaging apps such as as Telegram and Slack are
        integrating these Chatbots into their ecosystem. The possibilities for
        chatbots are endless. Here are some of the more notable chatbots in the
        market.
        <br />
        <br />
        <ul>
          <li> AI Duolingo helps users learn new languages,</li>
          <li>LARK AI is a fitness training and weight loss coach which aggregates health advice from
        global experts.</li>
          <li>Mona, is your personal assistant chatbot,</li>
          <li>Book Flight is a bot to book travel,</li>
          <li>Joy chatbot can help you with your mental health,</li>
          <li> Digital AI automates your budget,</li>
          <li>H&M chatbot is your personal stylist in your pocket,</li>
          <li>Medwhat is a medical diagnostic tool,</li>
          <li>DoNotPay automates legal advice</li>
          <li>Kuki, a goal orientated chatbot is considered the most
        human like of them all.</li>
        </ul>
         
           
      </div>
    ),
    text: "Chatbots are tools that allow people to converse over the internet with AI assistants. Messaging apps such as as Telegram and Slack are integrating these Chatbots into their ecosystem.",
    imgFolder: "/assets/technologies/chatbots",
    img: chatbot,
    bgImg: chatbotBg,
     modalImg: chatbotModal,
    modalBg: chatbotBgMob
  },
  {
    title: "Automation",
    textModal: (
      <div>
        Automation is freeing us from repetitive tasks and apps are no
        exception. Apps, connected to IoT devices are converting our home, into
        smart homes. Devices such as humidifiers, light dimmers, thermostats,
        cameras, air conditioners and refridgerators, audio and video players,
        smart key locks are being orchestrated by a predefined set of rules.
        <br />
        <br />
        Use the smart home automation to control your home security,
        entertainment, home energy and run time.
        <br />
        <br />
        You can save time with repetitive tasks using Automate app, which allows
        you to automate tasks on your phone using flow charts. Popular mobile
        automations include; 'messages on arrival to a location', 'morning
        messages' or 'apps which read to you out loud and save text to other
        devices'.
      </div>
    ),
    text: "Automation is freeing us from repetitive tasks and apps are no exception. Apps, connected to IoT devices are converting our home, into smart homes.",
    imgFolder: "/assets/technologies/automation",
    img: automation,
    bgImg: automationBg,
     modalImg: automationModal,
    modalBg: automationBgMob
  },
  {
    title: "Blockchain",
    textModal: (
      <div>
        Decentralised applications, Dapps, use smart contracts to complete
        transactions between two anonymous parties. Dapps run on peer-to-peer
        networks such as blockchain. Examples include Cryptoexchanges such as
        Coinbase, crypto lending platforms such as Maker DAO and Uniswap and NFT
        trading platforms such as Opensea. Dapps also have creative
        applications, used to incentivize social good such as Karma Dapp and
        Dapps for fun such as, Azarus the play and bet for tokens gaming
        platform. Crypto kitties took the Dapp world by storm with its cult
        following Dapp which allowed users to trade, breed and collect digital
        cat NFT’s.
        <br />
        <br />
        Dapps will continue to evolve as the technology becomes more ubiquitous
        and the user interface becomes more easy to use.
      </div>
    ),
    text: "Decentralised applications, Dapps, use smart contracts to complete transactions between two anonymous parties. Dapps run on peer-to-peer networks such as blockchain.",
    imgFolder: "/assets/technologies/blockchain",
    img: blockchain,
    bgImg: blockchainBg,
     modalImg: blockchainModal,
    modalBg: blockchainBgMob
  },
  {
    title: "Augmented Reality",
    textModal: (
      <div>
        Augmented reality creates simulated worlds and views that have practical
        applications across many enterprises and endeavours.
        <br />
        <br />
        Google, an early mover in Augmented Reality has utilized its extensive
        knowledgebase in Google Lens, an object and landmark identifying
        application. Google Map AR functions allows users to navigate to a
        destination using a virtual map and directions.
        <br />
        <br />
        AR continues to be applied in novel and innovative ways, here are just
        some of the things you can already do with AR;
        <br />
        <ul>
          <li>Mission to Mars allows you to land a rocket and explore the Martian
            surface.</li>
          <li>BBC civilization 3D renders artifacts from ancient
        civilisations</li>
           <li> Museum Alive give you a 3D view of ancient ecosystems and
        animals. Starwalk will name the stars and constellation in the night
            sky.</li>
          <li>YouCam Makeup will allow you to try on virtual makeup, Inkhunter
            will let you try a tattoo on your skin before its permanent.</li>
          <li>  Ikea catolgues are now in AR, see the furniture placed within your home
        before you buy.</li>
          <li> AR in Snapchat, Instagram and Holo allow you to add your
        own creative special effects to pictures and videos.</li>
        </ul>
        
      </div>
    ),
    text: "Augmented reality created simulated worlds and views that have practical applications across many enterprises and endeavours.",
    imgFolder: "/assets/technologies/ar",
    img: ar,
    bgImg: arBg,
     modalImg: arModal,
    modalBg: arBgMob
  },
];
