import { FC, ReactNode, useEffect, useId, useState } from "react";
import * as Styled from "./style";

interface IProps {
  children: ReactNode[];
  width?: string;
}

const SliderComponent: FC<IProps> = ({ children, width = "100%" }) => {
  const idComponent = useId()
  const [step, setStep] = useState(1);
  const [paused, setPaused] = useState(false);

  //   useEffect(() => {
  //   const sliderTimeout = setTimeout(() => {
  //      setStep((prevStep) => prevStep === children.length  ? 1 : prevStep + 1);
  //   }, 3500);

  //   return () => {
  //     clearTimeout(sliderTimeout);
  //   };
  // }, [children.length, step]);

  useEffect(() => {
    let sliderTimeout: NodeJS.Timeout | null = null;

    const handleSliderTimeout = () => {
      setStep((prevStep) => (prevStep === children.length ? 1 : prevStep + 1));
    };

    if (!paused) {
      sliderTimeout = setTimeout(handleSliderTimeout, 3500);
    }

    return () => {
      if (sliderTimeout) {
        clearTimeout(sliderTimeout);
      }
    };
  }, [children.length, step, paused]);

  const togglePause = () => {
    setPaused((prevPaused) => !prevPaused);
  };

  return (
    <Styled.Wrapper width={width} onClick={togglePause}>
      <Styled.WrapperLine width={width} step={step}>
        {children.map((item, index) => (
          <Styled.WrapperItem
            key={idComponent + "Item" + index}
            width={width}
          >
            {item}
          </Styled.WrapperItem>
        ))}
      </Styled.WrapperLine>
      <Styled.WrapperButtons step={step}>
        {children.map((_, index) => (
          <Styled.Button
            key={idComponent + "Button" + index}
            onClick={() => setStep(index + 1)}
          />
        ))}
      </Styled.WrapperButtons>
    </Styled.Wrapper>
  );
};

export default SliderComponent;
