import styled from "styled-components";

interface IWrapper {
  width: string;
}

export const Wrapper = styled.div<IWrapper>`
  /* width: ${({ width }) => width}; */
  height: fit-content;
   overflow: hidden; 
   max-width: 1290px;
  /* max-width: 1100px; */
  margin-bottom: 20px;
  @media(max-width:1120px){
    max-width: 990px;
  }
`;

interface IWrapperLine {
  step: number;
  width: string;
}

export const WrapperLine = styled.div<IWrapperLine>`
  display: flex;
  transform: ${({ width, step }) =>
    `translateX(calc(${step - 1} * ${width} * -1))`};
  transition: transform ease-out 0.5s;

  /* & > div:not(:nth-child(${({ step }) => step})) {
    height: 0px;
  } */
`;

interface IWrapperItem {
  width: string;
}

export const WrapperItem = styled.div<IWrapperItem>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width};
  height: fit-content;
  overflow: hidden;
  flex: 0 0 auto;
`;
interface IWrapperButtons {
  step: number;
}

export const WrapperButtons = styled.div<IWrapperButtons>`
  width: fit-content;
  display: flex;
  gap: 12px;
  position: relative;
  left: 100%;
  transform: translateX(-100%);
  margin: 12px 0;
  padding-right: 12px;
  & > div:nth-child(${({ step }) => step}) {
    width: 60px;
    background-color: #ed0c32;
    box-shadow: 0px 0px 20px 0px #ED0C32;
     transform: skew(-45deg);

  }
`;

export const Button = styled.div`
  width: 10px;
  height: 10px;
  /* transform: skew(-45deg); */
  background-color: #d6d6d6;
  transition: all 0.3s;
  cursor: pointer;
`;
