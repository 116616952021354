import { FC, ReactNode } from "react";
import WrapperContainer from "../../../../wrappers/container";
import WIWHeader from "./header";

interface IProps {
  title: ReactNode;
  text: ReactNode;
  children: ReactNode;
}

const WorkInfoWrapper: FC<IProps> = ({ children, ...propsHeader }) => {
  return (
    <WrapperContainer width="1100px">
      <WIWHeader {...propsHeader} />
      {children}
    </WrapperContainer>
  );
};

export default WorkInfoWrapper;
