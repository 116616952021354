import styled from "styled-components";

interface IWrapper {
  img: string;
}

export const Wrapper = styled.div<IWrapper>`
  width: calc(100% / 3);
  height: 348px;

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  
  /* @keyframes zoomOut {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  } */
}

  @media (max-width: 1280px) {
    width: 50%;
    height: 289px;
    &:first-child {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

  }
`;

export const Title = styled.div`
  background-color: #000000;
  padding: 15px;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
`;
