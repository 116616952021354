import styled from "styled-components";

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  margin-top: 60px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  margin: 15px 0 50px;
  
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

export const WrapperPeople = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
