import styled from "styled-components";

export const Wrapper = styled.div`
  width: 540px;
  padding: 65px 35px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
