import styled from "styled-components";

interface IWrapper {
  colorBG: string;
}

export const Wrapper = styled.div<IWrapper>`
  width: 275px;
  background: ${({ colorBG }) => colorBG};
  padding: 20px 0 80px;

  @media (max-width: 1024px) {
    width: 334px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  text-align: center;

  margin-bottom: 16px;
`;

export const WrapperDate = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 45px;
`;

interface IDateItem {
  color: string;
}

export const DateItem = styled.div<IDateItem>`
  width: calc(50% - 4px);
  padding: 5px 0;
  margin: 0 2px;

  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: ${({ color }) => color};

  position: relative;

  border-bottom: ${({ color }) => `1px solid ${color}`};

  &::before {
    content: "";
    width: 2px;
    height: 9px;
    background-color: ${({ color }) => color};
    position: absolute;
    top: 100%;
    left: 0%;
    transform: translateY(-50%);
  }

  &::after {
    content: "";
    font-size: 30px;
    width: 2px;
    height: 9px;
    background-color: ${({ color }) => color};
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translateY(-50%);
  }
`;

export const WrapperTask = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: unset;
  }
`;

export const TaskTitle = styled.div`
  width: calc(100% - 34px);
  word-wrap: break-word;
`;
