import styled from "styled-components";
import sixth_block_l from "../../../assets/img/software/sixth_block_l.png";
import sixth_block_m from "../../../assets/img/software/sixth_block_m.png";
import sixth_block_s from "../../../assets/img/software/sixth_block_s.png"

export const Title = styled.div`
  width: 550px;

  text-align: start;
  font-weight: 700;
  font-size: 32px;

  margin: 70px 0;

  & * {
    display: inline;
  }

  & > *:last-child {
    color: #ed0c32;
  }

  @media (max-width: 834px) {
    text-align: center;
    width: 100%;
    font-size: 30px;
  }

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  justify-content: center;
`;

export const ImgImpact = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  width: 730px;
  height: 400px;
  background: url(${sixth_block_l});  
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: 1200px) {
    background: url(${sixth_block_m});  
    background-position: center;
    background-size: contain;
  }

  @media (max-width: 776px) {
    width: 367px;
    height: 435px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background: url(${sixth_block_s});
  }
`;
