import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 300px;
  overflow: hidden;

  margin: 50px 0;
  
  & > video {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
`;
