import { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import {
  dataServicesInfo,
  TServicesInfoKey,
} from "../../commonData/servicesInfo/data";
import SIFirstBlock from "./firstBlock";
import SISecondBlock from "./secondBlock";
import SIThirdBlock from "./thirdBlock";

interface ILocation {
  state?: {
    template?: TServicesInfoKey;
  };
}

const ServicesInfoPage = () => {
  const { template = "" } = (useLocation() as ILocation).state || {};

  const data = useMemo(() => {
    if (!template) return null;
    return dataServicesInfo[template];
  }, [template]);

  if (!data) return <Navigate to={"/services"} />;
  
  return (
    <WrappperHeader>
      <SIFirstBlock>{data.firstBlock}</SIFirstBlock>
      <SISecondBlock {...data.secondBlock} />
      <SIThirdBlock data={data.thirdBlock} />
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default ServicesInfoPage;
