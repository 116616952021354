import styled from "styled-components";

export const Wrapper = styled.div`
  /* width: 1100px; */
  width: 1220px;
  /* height: 440px; */
  height: 621px;
  position: relative;
  display: flex;
  align-items: center;
  `;


interface IBG {
  bg: string;
}

export const BG = styled.div<IBG>`
  width: 980px;
  height: 524px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
  background-image: ${({ bg }) => `url(${bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WrapperLeftSide = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 2;
  height: 621px;
`;
interface IPhone {
  phone: string;
}
export const Phone = styled.div<IPhone>`
  /* width: 217px;
  height: 440px; */
  width: 306px;
  height: 620px;
  background-image: ${({ phone }) => `url(${phone})`};
  background-repeat: no-repeat;
  margin-top: 300px;
`;

export const PhoneContainer=styled.div`
width: 306px;
  height: 620px;
  img{
    width: 306px;
    height: 620px;
  }
`

export const WrapperText = styled.div`
  width: 260px;
  height: 100%;
  background-color: #00000080;
  padding-left: 20px;
  padding-top: 75px;
`;

export const WrapperName = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;

`;

export const LineUnderName = styled.div`
  width: 20px;
  height: 39px;
  transform: skew(-37deg);
  background: linear-gradient(180deg, #ed0c0c 0%, #fa00ff 100%);
  margin-left: 15px;
 
`;

export const Name = styled.div`
  width: 660px;
  position: absolute;
  font-size: 32px;
  font-weight: 600;
  margin-left: 20px;
`;

export const Text = styled.div`
  width: 870px;
  margin-top: 20px;
  font-size: 18px;
`;

export const WrapperButton = styled.div`
  margin-top: 160px;
  font-size: 16px;
  max-width: 174px;
`;
