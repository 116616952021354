import { FC } from "react";
import * as Styled from "./style";

import { useNavigate } from "react-router-dom";
import { IInfo } from "../../../../type";
import ArrowIcon from "../../../../assets/img/arrow";

interface IProps extends IInfo {
  template: string;
}

const AFBServices: FC<IProps> = ({ text, title, template }) => {
  const navigate = useNavigate();

  return (
    <Styled.Wrapper
      onClick={() => navigate("/services/info", { state: { template } })}
    >
      <div>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Text>{text}</Styled.Text>
      </div>
      <Styled.WrapperArrow>
        <ArrowIcon />
      </Styled.WrapperArrow>
    </Styled.Wrapper>
  );
};

export default AFBServices;
