import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  & > div {
    border-bottom: 1px solid #484848;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding-top: 50px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
`;

export const Button = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
color: #ED0C32;
  padding: 30px 0;
  cursor: pointer;
  &:hover{
    color: #0d6efd;
  }
`;
