import styled from "styled-components";
import { TSizeButton, TModeButton } from ".";

interface IDataSize {
  padding: string;
  fontSize: string;
}

interface IDataType {
  bgColor: string;
  border: string;
  hoverBgColor: string;
  hoverBorder: string;
  color: string;
  hoverColor: string;
  colorActive: string;
  fontWeight?:number
}

const dataSize: { [key in TSizeButton]: IDataSize } = {
  s: { padding: "6px 18px", fontSize: "12px" },
  m: { padding: "10px 30px", fontSize: "14px" },
  l: { padding: "10px 26px", fontSize: "16px" },
  xl: { padding: "20px 60px", fontSize: "24px" },
};

const dataType: { [key in TModeButton]: IDataType } = {
  red: {
    bgColor: "#ed0c32",
    border: "unset",
    hoverBgColor: "#c60b2a",
    hoverBorder: "unset",
    color: "white",
    hoverColor: "white",
    colorActive: "#ed0c32",
  },
  white: {
    bgColor: "transparent",
    border: "1px solid white",
    hoverBgColor: "white",
    hoverBorder: "2px solid white",
    color: "white",
    hoverColor: "black",
    colorActive: "white",
  },
  black: {
    bgColor: "#fff",
    border: "1px solid white",
    hoverBgColor: "#fff",
    hoverBorder: "2px solid white",
    color: "#000",
    hoverColor: " #0d6efd",
    colorActive: "white",
    fontWeight:600,
  },
};

interface IWrapper {
  size: TSizeButton;
  block: boolean;
  mode: TModeButton;
}

export const Wrapper = styled.button<IWrapper>`
width: 100%;
  /* width: ${({ block }) => (block ? "100%" : "initial")}; */
  color: ${({ mode }) => dataType[mode].color};
  background-color: ${({ mode }) => dataType[mode].bgColor};
  border: ${({ mode }) => dataType[mode].border};
  padding: ${({ size }) => dataSize[size].padding};
  font-size: ${({ size }) => dataSize[size].fontSize};
  text-transform: uppercase;
  outline: unset;
  box-shadow: unset;
  height: 48px;

  /* border-radius: 4px; */
  cursor: pointer;
  &:hover {
    color: ${({ mode }) => dataType[mode].hoverColor};
    background-color: ${({ mode }) => dataType[mode].hoverBgColor};
    border: ${({ mode }) => dataType[mode].hoverBorder};
  }

  &:active {
    box-shadow: ${({ mode }) => `0px 0px 20px ${dataType[mode].colorActive}`};
  }
`;
