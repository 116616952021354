import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const InsightsFirstBlock = () => {
  const { width } = useSize();

  return (
    <Styled.Wrapper>
      <Styled.BG />
      <WrapperContainer width={width > 1200 ? "1300px" : "734px"}>
        <Styled.WrapperInfo>
          <Styled.Title>Insights</Styled.Title>
          <Styled.Text>
            Deep dive into trending topics with AppOrbit blog. Make better
            decisions. Follow App Orbit company news.
          </Styled.Text>
        </Styled.WrapperInfo>
      </WrapperContainer>
    </Styled.Wrapper>
  );
};

export default InsightsFirstBlock;
