import styled from "styled-components";

export const TitleWrapper = styled.div`
max-width: 1950px;
margin-right: auto;
margin-left: auto;
width: 100%;
height: 136px;
padding: 40px 0;
margin-bottom: 80px;
display: flex;
align-items: center;
background-color: #1f1f1f;

`
export const Title = styled.h1`
font-size: 40px;
font-weight: 700;
color: #fff;
max-width: 1320px;
width: 100%;
margin-right: auto;
margin-left: auto;
@media(max-width:1400px){
    margin-right: 24px;
    margin-left: 24px;
}
@media(max-width:991px){
    font-size: 26px;
}
@media(max-width:699px){
    font-size: 40px;
}
@media(max-width:500px){
    font-size: 35px;
}
`
export const InfoContainer = styled.div`
max-width: 1320px;
margin-left: auto;
margin-right: auto;
padding-bottom: 26px;
border-bottom: 2px solid #1f1f1f;
margin-bottom: 160px;
padding-right: 24px;
padding-left: 24px;
`
export const SubTitle = styled.h2`
font-size: 32px;
font-weight: 500;
color: #fff;
margin-bottom: 24px;
@media(max-width:991px){
    font-size: 30px;
}
@media(max-width:991px){
    font-size: 27px;
}
@media(max-width:500px){
    font-size: 26px;
}
`
export const SubTitleSmall = styled.h3`
font-size: 20px;
font-weight: 500;
margin-bottom: 16px;
`
export const Text = styled.p`
font-size: 14px;
margin-bottom: 24px;
`
export const TextAdditional = styled.p`
font-size: 14px;
margin-bottom: 16px;
`
export const Address = styled.div`
font-size: 14px;
margin-bottom: 24px;
margin-top: 24px;
a{
    text-decoration: none;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
`