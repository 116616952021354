import { IDataSoftwareSolution } from "./types";
import solution_1 from "../../assets/img/software/solutions/solution_1.png";
import solution_2 from "../../assets/img/software/solutions/solution_2.png";
import solution_3 from "../../assets/img/software/solutions/solution_3.png";
import solution_4 from "../../assets/img/software/solutions/solution_4.png";
import solution_5 from "../../assets/img/software/solutions/solution_5.png";
import solution_6 from "../../assets/img/software/solutions/solution_6.png";
import solution_7 from "../../assets/img/software/solutions/solution_7.png";

export const dataSoftwareSolution: IDataSoftwareSolution[] = [
  {
    img: solution_1,
    title: "Product design and development",
    text: "A product is the combined value of business capabilities to deliver on a customer outcome and may include software, hardware and human interaction. App Orbit works with our client in their digital transformation, to develop products and platforms to grow user value and increase productivity. Our product design and development team focuses on the needs of end users and comprises designers, analysts and engineers working with agile principles and design thinking. Our product team includes experienced service and customer experience designers, who have decades of experience in the reimagination and digitisation of business.",
  },
  {
    img: solution_2,
    title: "ERP",
    text: (
      <div>
        <div>
          ERP systems to track and integrated data on business processes and
          resources including HR, sales and marketing, purchasing delivery and
          manufacturing. It increases efficiencies by improving the flow of work
          and information both internally and with external stakeholders.
          Customised ERP allows you to built ERP solutions based on your
          specific needs, and build a competitive advantage relative to
          companies using standard ERP.
        </div>
        <br />
        <div>
          App Orbit Business Analysts and ERP developers will conduct meetings to
          understand requirements and prepare project implementation
          documentation.
        </div>
      </div>
    ),
  },
  {
    img: solution_3,
    title: "CRM",
    text: "Custom CRM development and integration with third party solutions. Our CRM development team will analyse your unique business needs and provide an effective roadmap for integration. A customised CRM allows your business to differentiate itself through a more personalised and predictive customer experience. A customised solution is more cost effective when adding specific features and increases employee productivity by being a better fit with the desired workflow. Customised CRM systems allows your business to be more responsive to changing market conditions.",
  },
  {
    img: solution_4,
    title: "API Integration And Custom development",
    text: "API’s are an interface that allows applications to talk to each other. Our high performing API’s are designed using code, architecture, process and security. App Orbit’S API specialist team have extensive experience in API development and integration using Java, Python, PHP, NODE.JS, Magento and .NET,  C and C++. Our Custom API development applies the REST architecture, utilising the best-in-class tools for successful third party app integration.",
  },
  {
    img: solution_5,
    title: "Chatbots",
    text: (
      <div>
        <div>
          We work to develop artificial agents to assist customers, prospects
          and employees. Our experienced chat bot team develop custom chats and
          work within more established channels such as Telegram and Facebook.
        </div>
        <br />
        <div>
          Our UI/UX team can help you work across the senses working with voice,
          text and gifs. We create conversational design backed by AI which
          learns with each and every customer interaction.
        </div>
      </div>
    ),
  },
  {
    img: solution_6,
    title: "Data Analytics and BI",
    text: (
      <div>
        <div>
        Understand your business performance with enterprise wide KPI’s capturing sales, customer finance, supply chain and HR analytics. Orbit’s team can analyse your business workflow and technology infrastructure to optimise your  BI solution, creating meaningful formats and predictive decision-making activities.
        </div>
        <br />
        <div>
          Our accomplished UI/UX team are have exceptional dashboard design and
          data visualisation skills. Our custom Analytics and BI engineers
          design and implement data warehouse ETL, Data enrichment and OLAP.
        </div>
        <br />
        <div>
          Data structure and database design team enable clients to store and
          manage data and embed it in decisions and interaction. We work with
          clients to ensure processing and delivery of data real time across the
          data ecosystem.
        </div>
      </div>
    ),
  },
  {
    img: solution_7,
    title: "Cloud Migration",
    text: "App Orbit work with business to migrate legacy infrastructure to the cloud including Amazon Web Services and Microsoft Azure. We will work to ensure your cloud migration realises the efficiencies and scalability to drive future growth. Our team will test the performance of your legacy infrastructure on the cloud, where required we can work to modernise your software such as micro-services and database structure to realise the full benefit of cloud ops.  ",
  },
];
