import { createContext, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { dataOurWork } from "../../commonData/ourWork/data";
import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import WorkEigthBlock from "./eigthBlock";
import WorkFifthBlock from "./fifthBlock";
import WorkFirstBlock from "./firstBlock";
import WorkFourthBlock from "./fourthBlock";
import WorkNinthBlock from "./ninthBlock";
import WorkSecondBlock from "./secondBlock";
import WorkSeventhBlock from "./seventhBlock";
import WorkSixthBlock from "./sixthBlock";
import WorkThirdBlock from "./thirdBlock";

export const WorkContext = createContext(dataOurWork[0]);

const WorkPage = () => {
  const { id = "" } = useParams();

  const data = useMemo(() => dataOurWork.find((item) => id === item.id), [id]);

  if (!data) {
    return <Navigate to="/works" />;
  }

  return (
    <WorkContext.Provider value={data}>
      <WrappperHeader>
        <WorkFirstBlock />
        <WorkSecondBlock />
        <WorkThirdBlock />
        <WorkFourthBlock />
        <WorkFifthBlock />
        <WorkSixthBlock />
        <WorkSeventhBlock />
        <WorkEigthBlock />
        <WorkNinthBlock />
        <LetsTalkComponent />
      </WrappperHeader>
    </WorkContext.Provider>
  );
};

export default WorkPage;
