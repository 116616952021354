import styled from "styled-components";

export const Line = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 2px;
  margin: 80px auto;

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 48.44%,
    rgba(255, 255, 255, 0) 100%
  );
`;

export const WrapperAskOurTeam = styled.div`
  margin: 115px 0 65px;
`;
