import { dataOurWork } from "../../../commonData/ourWork/data";
import { IPropsOWSBItem } from "./item";

export const data: IPropsOWSBItem[] = dataOurWork.map(
  ({ imgFolder, coverInfo, id, mainColor, img1, img2 }) => ({
    ...coverInfo,
    imgFolder,
    img1: img1,
    img2:img2,
    to: id,
    bgColor: mainColor,
  })
);
