import { useContext, useId } from "react";
import { WorkContext } from "..";
import WrapperContainer from "../../../wrappers/container";
import WSBHeader from "./header";
import WSBItem from "./item";
import * as Styled from "./style";

const WorkSixthBlock = () => {
  const idComponent = useId();
  const { approach } = useContext(WorkContext);
  return (
    <div>
      <WSBHeader />
      <WrapperContainer width="1100px">
        <Styled.WrapperItems>
          {approach.map((item, index) => (
            <WSBItem key={idComponent + "." + index} {...item} />
          ))}
        </Styled.WrapperItems>
      </WrapperContainer>
    </div>
  );
};

export default WorkSixthBlock;
