import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: block;
  }
`;

interface IWrapperBlock {
  width: string;
}

export const WrapperBlock = styled.div<IWrapperBlock>`
  width: ${({ width }) => width};
  @media (max-width: 991px) {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: unset;
    }
  }
`;

export const FirstText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
  padding-top: 24px;
  
`;

export const SecondText = styled.div`
  max-width: 645px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
  padding-top: 24px;
 
`;
export const ButtonWrapper = styled.div`
max-width: 182px;
height: 48px;
`
export const LineUnderST = styled.div`
  width: 60%;
  height: 6px;
  background: #ed0c32;
`;
