import styled from "styled-components";
import bgImgBorder from "../../../assets/img/main/thirdStep.png";
import bgImg from "../../../assets/img/main/bg.png"

export const WrapperHeader = styled.div`
  margin: 0 auto;
  /* background: url("./assets/main/thirdStep.png"); */
  background: url(${bgImgBorder});  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8px;
  max-width: 1314px;
  width: 100%;

  @media(max-width:991px){
    margin-top: 80px;
      max-width: 720px;
 
  }
   @media(max-width:767px){
      margin-left: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  /* justify-content: space-between; */
  column-gap: 25px;
  align-items: center;
  /* padding: 30px 60px; */
    padding: 25px 15px;
    height: 116px;
  /* background-color: #000000dc; */
    background: url(${bgImg});  
    @media(max-width:991px){
          padding: 25px 15px;
    }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const HeaderTitle = styled.div`
  /* width: calc(50% - 30px); */
  max-width: 650px;
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  @media (max-width: 1400px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const HeaderText = styled.div`
  width: calc(50% - 31px);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  @media (max-width: 1400px) {
    font-size: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const WrapperBlockInfo = styled.div`
  /* width: calc(99% - 200px); */
     max-width: 1160px;
     width: 1160px;
     justify-content: center;
  margin: 66px auto 93px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  @media (max-width: 1400px) {
 
    width: 100%;
    gap: 50px 20px;
    max-width: 750px;
    margin-bottom:50px
  }
    @media (max-width: 767px) {
      max-width: 500px;
      margin-right:auto;
      margin-left: auto;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      margin-left: 12px;
    }
`;
