import styled from "styled-components";

export const WrapperStiky = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 4;
  background-color: black;
  @media(max-width:991px){
    height: 70px;
  }
`;

export const Wrapper = styled.header`
max-width: 1720px;
width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  
  font-size: 12px;
  text-transform: uppercase;
  & > div:nth-child(2) {
    cursor: pointer;
  }
  @media(max-width:1200px){
    max-width: 1040px;
  }
  
    @media(max-width:991px){
    /* justify-content: right;
    max-width: 820px; */
    display: none;
  }
      /* @media(max-width:820px){
    max-width: 655px;
  }
        @media(max-width:820px){
    max-width: 655px;
  } */
  
`;

export const WrapperHeight = styled.div`
  width: 100%;
  height: 100px;
   @media(max-width:991px){
    height: 70px;
  }
`;

export const BurgerIcon = styled.div`
  cursor: pointer;
   display: none;
     /* &.burgerItem{
    color: rgba(255,255,255,.55);
    border-color: rgba(255,255,255,.1);
    height: 40px;
    width: 40px;
   } */
   
   @media(max-width:991px){
    display: flex;
    flex-direction: column;
   
  }
`;

export const BurgerItem = styled.span`

    height: 1px;
    width: 30px;
    color: red;
    border: 1px solid rgba(255,255,255,.55);
    margin-bottom: 3px;
`

export const WrapperLogo = styled.div`
  padding-top: 10px;
  margin-right: 16px;
  img{
    width: 136px;
    height: 22px;
  }
`;
export const WrapperInner = styled.div`
display: flex;
max-width: 1860px;
align-items: center;
margin-top: 20px;
margin-left: 12px;
margin-right: 12px;
 @media(max-width:1400px){
    max-width: 1300px;
  }
 @media(max-width:1200px){
    max-width: 1200px;
  }
  @media(max-width:991px){
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media(max-width:820px){
    /* max-width: 810px; */
  }
  
`