import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  title?: ReactNode;
  children: ReactNode;
}

const DataInsightsItem: FC<IProps> = ({ title, children }) => {
  return (
    <>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Text>{children}</Styled.Text>
    </>
  );
};

export default DataInsightsItem;
