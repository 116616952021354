
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  max-width: 2800px;
  width: 100%;

  @media (max-width: 1400px) {
    height: 1124px;
    padding: 0 50px;
  }
  @media (max-width: 768px) {
    height: 667px;
    max-width: 768px;
    padding: 0 12px;
  }
`;

export const WrapperInfo = styled.div`
  width: 920px;
  position: relative;
  z-index: 2;
  height: 900px;
  top: 150px;
  left: 70px;
  img{
    width: 200px;
    height: 23px;
  }
  @media(max-width:1400px){
  top: 0px;
}
@media(max-width:768px){
   left: 40px;
   height: 270px;
  
}
@media(max-width:700px){
  width: 410px;
}
@media(max-width:500px){
  left: 0px;
}

`;

export const Title = styled.div`
  font-size: 60px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 40px;
  }
    @media (max-width: 500px) {
    max-width: 410px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin: 24px 0;
  max-width: fit-content;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
  @media (max-width: 500px) {
    max-width: 315px;
  }
`;
export const ButtonWrapper = styled.div`
max-width: 220px;
`

export const WrapperVideo = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width:700px){
      width: 100%;
      max-width: 700px;
    }
  }
  
`;
