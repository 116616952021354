import styled from "styled-components";

export const Title = styled.div`
  font-weight: 700;
  font-size: 38px;

  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

export const Text = styled.div`
  width: 740px;

  font-weight: 400;
  font-size: 15px;
  margin: 20px 0;
`;

export const WrapperPhonesBG = styled.div`
  width: 100%;
  height: 415px;
  background: #151515;

  overflow: hidden;

  margin-bottom: 200px;
`;

export const WrapperPhones = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 50px;
`;

interface IPhone {
  size: "small" | "big";
  img: string;
}

export const Phone = styled.div<IPhone>`
  width: ${({ size }) => (size === "small" ? "186px" : "255px")};
  height: ${({ size }) => (size === "small" ? "377px" : "517px")};

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 843px) {
    &:not(:first-child) {
      display: none;
    }
  }
`;
