import styled from "styled-components";

export const WrapperBG = styled.div`
  background: #212121;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
`;

export const Title = styled.div`
  width: 50%;
  font-weight: 700;
  font-size: 48px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 20px;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
  }

  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
