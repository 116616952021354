import { FC, useId } from "react";
import { dataInsights } from "../../../commonData/insights/data";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import ISBItem from "./item";
import * as Styled from "./style";

interface IProps {
  idSkip?: string;
}

const InsightsSecondBlock: FC<IProps> = ({ idSkip }) => {
  const idComponent = useId();
  const { width } = useSize();

  return (
    <WrapperContainer width={width > 1200 ? "1320px" : "734px"}>
      <Styled.Wrapper>
        {dataInsights
          .filter(({ id }) => id !== idSkip)
          .map(({ text, ...props }, index) => (
            <ISBItem key={idComponent + "." + index} {...props} />
          ))}
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default InsightsSecondBlock;
