import styled from "styled-components";

export const WrapperItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 140px;

  @media (max-width: 1024px) {
    gap: 50px;
  }
`;
