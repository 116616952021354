import { useContext } from "react";
import { GlobalContext } from "../../globalContext";
import * as Styled from "./style";

const GlobalModal = () => {
  const { dataModal } = useContext(GlobalContext).data;

  if (!dataModal) return null;

  return <Styled.Wrapper>{dataModal}</Styled.Wrapper>;
};

export default GlobalModal;
