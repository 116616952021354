import { useId } from "react";
import { dataSoftwareSolution } from "../../../commonData/softwareSolution/data";
import TwoColumnComponent from "../../../components/twoColumn";
import WrapperContainer from "../../../wrappers/container";
import SSBHeader from "./header";
import SSBItem from "./item";

const SoftwareSevenBlock = () => {
  const idComponent = useId();

  return (
    <WrapperContainer width="1200px">
      <SSBHeader />

      <TwoColumnComponent
        data={dataSoftwareSolution.map((item, index) => (
          <SSBItem key={idComponent + "." + index} {...item} />
        ))}
        changeWidth={1200}
      />
    </WrapperContainer>
  );
};

export default SoftwareSevenBlock;
