import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 267px;
  background: #151515;
  margin: 300px 0;

  @media (max-width: 834px) {
    height: 514px;
    margin: 60px 0;
  }

  @media (max-width: 700px) {
    overflow: hidden;
  }
`;

export const WrapperPhone = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  gap: 0 100px;

  @media (max-width: 1024px) {
    gap: 0 30px;
  }

  @media (max-width: 834px) {
    width: 100%;
    height: 100%;

    top: 65%;
  }
`;

interface IPhone {
  img: string;
}

export const Phone = styled.div<IPhone>`
  width: 255px;
  height: 517px;

  background: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:nth-child(2) {
    transform: translateY(-15%);
  }

  @media (max-width: 834px) {
    width: 209px;
    height: 424px;
  }

  @media (max-width: 700px) {
    position: absolute;
    top: 50%;
    left: 50%;

    &:first-child {
      width: 255px;
      height: 517px;
      transform: translate(-70%, -50%);
    }

    &:nth-child(2) {
      width: 226px;
      height: 457px;
      transform: translate(-20%, -50%);
    }

    &:last-child {
      display: none;
    }
  }
`;
