import styled from "styled-components";

export const Title = styled.div`
  font-weight: 700;
  font-size: 38px;

  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

export const Text = styled.div`
  width: 740px;

  font-weight: 400;
  font-size: 15px;
  margin: 25px 0;

  @media (max-width: 834px) {
    width: 100%;
  }
`;
