import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import AboutFirstBlock from "./firstBlock";
import AboutFourthBlock from "./fourthBlock";
import AboutSecondBlock from "./secondBlock";
import AboutThridBlock from "./thirdBlock";

const AboutPage = () => {
  return (
    <WrappperHeader>
      <AboutFirstBlock />
      <AboutSecondBlock />
      <AboutThridBlock />
      <AboutFourthBlock />
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default AboutPage;
