import * as Styled from "./style";

const SSBHeader = () => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Orbit customised software solutions</Styled.Title>
      <Styled.Text>
        Delivering solutions with solid user experience design and development
        is central to each of our services. With a range of specialities ranging
        from native app development to rescue projects and validation testing,
        we ensure all deliverables are tailored to the needs of your market.
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default SSBHeader;
