import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  title: ReactNode;
  text: ReactNode;
}

const WIWHeader: FC<IProps> = ({ text, title }) => {
  return (
    <div>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Text>{text}</Styled.Text>
    </div>
  );
};

export default WIWHeader;
