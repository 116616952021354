import { FC, useId } from "react";
import { ISSBInfo } from "../types";
import SSBRItem from "./item";
import * as Styled from "./style";

interface IProps {
  data: ISSBInfo[];
}

const SSBRow: FC<IProps> = ({ data }) => {
  const idComponent = useId();

  return (
    <Styled.Wrapper>
      {data.map((item, index) => (
        <SSBRItem key={idComponent + "." + index} {...item} />
      ))}
    </Styled.Wrapper>
  );
};

export default SSBRow;
