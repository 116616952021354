import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 35px;
  border-bottom: 1px solid #393939;

  &:last-child {
    margin-bottom: unset;
  }

  @media (max-width: 834px) {
    display: block;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 15px;
  width: calc(40% - 15px);

  @media (max-width: 834px) {
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const Text = styled.div`
  width: calc(60% - 15px);
  font-weight: 400;
  font-size: 15px;

  @media (max-width: 834px) {
    width: 100%;
  }
`;
