import { useContext } from "react";
import { WorkContext } from "..";
import WrapperContainer from "../../../wrappers/container";
import ProblemSolutionComponent from "../../../components/problemSolution";
import { IInfo } from "../../../type";

const WorkFourthBlock = () => {
  const { problem, solution } = useContext(WorkContext);

  const data: [IInfo, IInfo] = [
    { title: "Problem", text: problem },
    { title: "Solution", text: solution },
  ];

  return (
    <WrapperContainer width="1320px">
      <ProblemSolutionComponent data={data} />
    </WrapperContainer>
  );
};

export default WorkFourthBlock;
