import { useContext } from "react";
import { InsightContext } from "..";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const IPFirstBlock = () => {
  const { title, img } = useContext(InsightContext);

  const { width } = useSize();

  return (
    <Styled.WrapperBG>
      <WrapperContainer width={width > 1200 ? "1300px" : "730px"} height="100%">
        <Styled.Wrapper>
          <WrapperContainer width={width > 1200 ? "900px" : "730px"}>
            <Styled.Title>{title}</Styled.Title>
          </WrapperContainer>
          <Styled.Img img={`${img}`} />
        </Styled.Wrapper>
      </WrapperContainer>
    </Styled.WrapperBG>
  );
};

export default IPFirstBlock;
