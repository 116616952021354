import { useLocation } from "react-router-dom";
import * as Styled from "./style";
import phone1 from '../../../assets/img/works/peerpay/phone_1.png';
import phone2 from '../../../assets/img/works/peerpay/phone_2.png';
import phone3 from '../../../assets/img/works/peerpay/phone_3.png';
import phone1c from '../../../assets/img/works/coach/phone_1.png';
import phone2c from '../../../assets/img/works/coach/phone_2.png';
import phone3c from '../../../assets/img/works/coach/phone_3.png';
// import phone1hrm from '../../../assets/img/works/hrm/phone_10.png';
// import phone2hrm from '../../../assets/img/works/hrm/phone_12.png';
// import phone3hrm from '../../../assets/img/works/hrm/phone_13.png';

const WorkFifthBlock = () => {
  const { pathname } = useLocation();

  return (
    <Styled.Wrapper>
      <Styled.WrapperPhone>
        {pathname === "/works/coach" && (
          <>
           <Styled.Phone img={phone1c} />
           <Styled.Phone img={phone2c} />
           <Styled.Phone img={phone3c} />
          </>
        )}
         {pathname === "/works/peerpay" && (
          <>
           <Styled.Phone img={phone1} />
           <Styled.Phone img={phone2} />
           <Styled.Phone img={phone3} />
          </>
        )}
         {/* {pathname === "/works/hrm" && (
          <>
           <Styled.Phone img={phone1hrm} />
           <Styled.Phone img={phone2hrm} />
           <Styled.Phone img={phone3hrm} />
          </>
        )} */}
       
      </Styled.WrapperPhone>
    </Styled.Wrapper>
  );
};

export default WorkFifthBlock;
