import { coachWork } from "./templates/coach";
// import { hrm } from "./templates/hrm";
import { peerpayWork } from "./templates/peerpay";
import { IDataOurWork } from "./types";

export const dataOurWork: IDataOurWork[] = [
  peerpayWork,
  coachWork,
  // hrm
];
