import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;

  margin-top: 30px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  & .red {
    color: #ed0c0c;
     cursor: pointer;
    text-decoration: none;
  }

  & .copy {
    color: #909090;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 4px;

    & .red {
      margin-top: 50px;
      font-size: 25px;
      font-weight: 700;
    }

    & .copy {
      font-weight: 400;
      color: #FFF;
    }
  }
`;
