import styled from "styled-components";

interface IWrapper {
  width?: string;
  height?: string;
  background?:string
}

export const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width || "386px"};
  height: ${({ height }) => height || "296px"};

  padding: 95px 20px 0;
  border-radius: 4px;

  position: relative;

  background-color: ${({ background }) => background || "#191919"};

  @media (max-width: 1200px) {
    width: ${({ width }) => width || "367px"};
    height: ${({ height }) => height || "149px"};

    padding: 32px 20px;
  }

`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 17px;
  text-align: center;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
`;

export const WrapperInfo = styled.div`
  position: relative;
  z-index: 1;
`;
