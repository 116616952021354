import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1000px;
  display: flex;
  gap: 60px;
  margin-bottom: 150px;
  &:last-child {
    margin-bottom: unset;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const WrapperInfo = styled.div`
  width: 470px;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const InfoIndex = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-shadow: 0px 4px 16px #ffffff, 0px -4px 16px #ffffff;
`;
export const InfoIndexLab = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 24px;
  text-shadow: 0px 4px 16px #ffffff, 0px -4px 16px #ffffff;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 44px;
  margin: 10px 0 20px;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 30px;
  max-width: 433px;
`;

export const WrapperVideo = styled.div`
  width: 470px;
  height: 380px;

  & > video {
    width: 470px;
    height: 74%;
  }

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const WrapperInfoPicker = styled.div`
  & > div {
    margin-bottom: 15px;
  }
  & > div:last-child {
    margin-bottom: unset;
  }
`;
