import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 160px;

  @media (max-width: 834px) {
    flex-direction: column;
  }
`;

export const WrapperBlock = styled.div`
  width: calc(50% - 10px);
  height: 628px;
  background-color: #151515;
  overflow: hidden;
  position: relative;

  @media (max-width: 834px) {
    width: 100%;
    margin-bottom: 12px;
  }
`;

interface IPhone {
  img: string;
}

export const FirstPhone = styled.div<IPhone>`
  width: 255px;
  height: 517px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -80%);

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SecondPhone = styled(FirstPhone)`
  top: 0%;
  left: 0%;
  transform: translate(-20%, -20%) rotateZ(90deg);
`;

export const ThirdPhone = styled(FirstPhone)`
  top: 100%;
  left: 100%;
  transform: translate(-80%, -80%) rotateZ(-90deg);
`;
