import styled from "styled-components";

export const WrapperBG = styled.div`
  background: #151515;
`;

export const Wrapper = styled.div`
  padding: 40px 0;
`;

export const TypePage = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #ED0C32;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

export const WrapperTitle = styled.div`
  width: 60%;

  font-weight: 700;
  font-size: 40px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;
