import { IDataOurWork } from "../types";
import imgFirst from "../../../assets/img/works/coach/phone_1.png";
import imgSecond from "../../../assets/img/works/coach/phone_2.png";

export const coachWork: IDataOurWork = {
  id: "coach",
  imgFolder: "/assets/works/coach",
  img1: imgFirst,
  img2:imgSecond,
  mainColor: "#91AFFC",
  headerInfo: {
    title: "Beach Coach",
    text: (
      <div>
        Beach coach app is health and fitness app focused on people for whom the
        beach is their gym and spa.
        <br />
        <br />
        The app allows users to set goals, measure performance and receive
        personalized tips for performance improvement. Users commit to routines
        and challenges to meet holistic health and fitness goals.
        <br />
        <br />
        The Beach Coach app is a creator platform where high performance
        individuals and coaches give instructions to learn new skills and
        improve existing ones.
      </div>
    ),
  },
  about: [
    { title: "Client", text: "Beach Coach app" },
    { title: "Industry", text: "Health and Fitness" },
    { title: "Budget", text: "$200,000+" },
    { title: "Scope", text: "App Development 480 hours" },
    {
      title: "App",
      text: "Web app and mobile app developed in .Net, Flutter and Blockchain",
    },
  ],
  clients_brief: {
    title: "Client’s brief",
    text: "Beach Coach briefed app Orbit to create personalised coaching apps to improve the health and fitness outcomes of people who use the beach as their gym and spa. It provides a holistic guide to health and fitness designed around a beach lifestyle.",
  },
  business_objectives: [
    {
      title: "PERSONALISED",
      text: "User experience was designed around personalized goals",
    },
    {
      title: "ACTIVITY TRACKING",
      text: "Tracking of user fitness activity integrated into user experience",
    },
    {
      title: "ECOSYSTEM",
      text: "The app integrated API’s and worked across multiple devices",
    },
    {
      title: "COACHING",
      text: "Effective instruction through learning design within the app",
    },
    {
      title: "USER ENGAGEMENT",
      text: "User experience to drive high levels of user generated content",
    },
    {
      title: "DATA DRIVEN",
      text: "Dynamic user interface and user experience based on individual data.",
    },
  ],
  problem:
    "People who prefer to exercise on the beach rather than a gym lack access to on-beach coaching and personal trainers to help them realise their overall health and fitness goals.",
  solution:
    "A beach coach app that builds health and fitness coaching around personal goals, activity tracking complimenting on-beach activity with a complete health and fitness program, instructed by health and fitness creators.",
  approach: [
    {
      title: "UNDERSTANDING",
      dates: ["WEEK 1", "WEEK 4"],
      colors: [
        "linear-gradient(180deg, rgba(36, 0, 255, 0.12) 0%, rgba(36, 0, 255, 0.2) 49.48%, rgba(36, 0, 255, 0.12) 100%)",
        " #2400FF",
      ],
      tasks: [
        "Creator Interviews",
        "User Interviews",
        "Surveys",
        "Field Studies",
        "Competitor Analysis",
        "User Journeys",
        "User Personas",
      ],
    },
    {
      title: "EXPLORATION",
      dates: ["WEEK 5", "WEEK 9"],
      colors: [
        "linear-gradient(180deg, rgba(255, 0, 229, 0.12) 0%, rgba(255, 0, 229, 0.2) 48.96%, rgba(255, 0, 229, 0.12) 100%);",
        " #FF00E5",
      ],
      tasks: [
        "Paper Prototypes",
        "Wireframes",
        "Hi-Fi Prototypes",
        "User Flow",
        "Interactive Prototypes",
      ],
    },
    {
      title: "TESTING",
      dates: ["WEEK 10", "WEEK 14"],
      colors: [
        "linear-gradient(180deg, rgba(255, 168, 0, 0.12) 0%, rgba(255, 168, 0, 0.2) 49.48%, rgba(255, 168, 0, 0.12) 100%);",
        " #FFA800",
      ],
      tasks: [
        "Ethnographic research",
        "Usability Testing",
        "A/B Testing",
        "Customer Service Feedback",
        "Analytics & KPI Review",
      ],
    },
    {
      title: "DEVELOPMENT",
      dates: ["WEEK 15", "WEEK 39"],
      colors: [
        "linear-gradient(180deg, rgba(0, 255, 25, 0.12) 0%, rgba(0, 255, 117, 0.2) 49.48%, rgba(0, 255, 240, 0.12) 100%)",
        " #00FF19",
      ],
      tasks: [
        "Agile Project Methodology",
        "Feature Release plan",
        "2 Weekly sprints",
      ],
    },
  ],
  firstInfo: {
    title: "MVP- Beach Coach activity tracking",
    text: (
      <div>
        Swimming, surfing, sea kayaking, or general exercise, Beach Coach app
        has you covered, tracking your activity, progress towards goals and
        recommendations from the beach coach app for performance improvement.
        <br />
        <br />
        Apple watch in combination with a smart phone or desktop app provides
        users with a tracking mechanism and their own personal performance
        dashboard.
      </div>
    ),
  },
  secondInfo: {
    title: "MVP- Beach Coach app goal setting and tracking",
    text: (
      <div>
        Beach coach app is designed to help users set and accomplish health and
        fitness goals. By working with health and fitness instructor coaches and
        the structure of an app based training program, users are able to
        participate in self directed activities. The app provides constant
        feedback on their progress towards a goal.
      </div>
    ),
  },
  coverInfo: {
    title: "Beach Coach",
    text: "Beach coach app is health and fitness app focused on people for whom the beach is their gym and spa.",
    tag: ["B2C", "Mobile App", "iOS", "Android"],
  },
};
