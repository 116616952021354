import { useContext, useId } from "react";
import { WorkContext } from "..";
import WrapperContainer from "../../../wrappers/container";
import WorkSBItem from "./item";
import * as Styled from "./style";

const WorkSecondBlock = () => {
  const idComponent = useId();
  const { about } = useContext(WorkContext);
  return (
    <WrapperContainer width="1145px">
      <Styled.Wrapper>
        {about.map((item, index) => (
          <WorkSBItem key={idComponent + "." + index} {...item} />
        ))}
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default WorkSecondBlock;
