const SINCSecondBlock = () => {
  return (
    <div>
      <div>
        No code is recommended for early stage and smaller scale ventures such
        as;
      </div>
      <br />
      <ul>
        <li>High levels of innovation requiring rapid experimentation</li>
        <li>Prototype testing to iterate to success.</li>
        <li>
          Business model testing, validating ideas in market conditions with
          real life users.
        </li>
        <li>Small niche markets or user bases, where affordability is key.</li>
      </ul>
    </div>
  );
};


export default SINCSecondBlock;
