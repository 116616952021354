import WrappperHeader from "../../wrappers/header";
import * as Styled from "./style";
import SoftwareFifthBlock from "./fifthBlock";
import SoftwareFirstBlock from "./firstBlock";
import SoftwareFourthBlock from "./fourthBlock";
import SoftwareSecondBlock from "./secondBlock";
import SoftwareSevenBlock from "./sevenBlock";
import SoftwareSixthBlock from "./sixthBlock";
import SofrwareThirdBlock from "./thirdBlock";
import SoftwareEighthBlock from "./eighthBlock";
import SoftwareNinthBlock from "./ninthBlock";
import AskOurTeamComponent from "../../components/askOurTeam";
import LetsTalkComponent from "../../components/letsTalk";

const SoftwarePage = () => {
  return (
    <WrappperHeader>
      <SoftwareFirstBlock />
      <SoftwareSecondBlock />
      <SofrwareThirdBlock />
      <SoftwareFourthBlock />
      <SoftwareFifthBlock />
      <SoftwareSixthBlock />
      <Styled.Line />
      <SoftwareSevenBlock />
      {/* <Styled.Line /> */}
      <SoftwareEighthBlock />
      <SoftwareNinthBlock />
      <Styled.WrapperAskOurTeam>
        <AskOurTeamComponent />
      </Styled.WrapperAskOurTeam>
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default SoftwarePage;
