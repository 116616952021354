import styled from "styled-components";

export const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  margin-top: 80px;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;

  width: 50%;

  margin: 16px 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
