import { IDataServices } from "./types";

export const dataServices: IDataServices[] = [
  {
    title: "Native Mobile App Development",
    description: (
      <div>
        Native apps are chosen for their high performance and superior user experience. App Orbit's exceptional UIUX team and talented software engineers can utilise the full power of native apps, access all of the device features and deliver a faster and superior UX. Our UIUX team develop and test low and high fidelity designs to ensure each release is best in class.
      </div>
    ),
    smallDescription:
      "sdfsdfsdfsdf App Orbit native apps are engineered for performance and take the user experience to the edge of possibility.",
    template: "nativeMobile",
  },
  
  {
    title: "No Code Apps",
    description: (
      <div>
       App Orbit's no code apps allow rapid prototype experimentation and testing, helping our clients derisk their pathway to achieve product market fit. We work with clients to iterate designs and validate with the market before scale.
      </div>
    ),
    smallDescription:
      "App Orbit no code solutions enable rapid prototype experimentation allowing businesses to iterate to a minimal viable product.",

    template: "noCode",
  },
  {
    title: "Business Applications",
    description: (
      <div>
       App Orbit's recognition as a leader business application development is founded on its origins as a custom software engineering team. App Orbit  has been at the forefront of digtial transformation, enabling companies to make the transition to a high maturity digital organisation achieving breakthroughs in productivty, efficiency and customer experience.
      </div>
    ),
    smallDescription:
      "App Orbit native apps are engineered for performance and take the user experience to the edge of possibility.",
    template: "business",
  },
  {
    title: "VR App Development",
    description: (
      <div>
       Virtual Reality apps create simulated worlds with endless possibilities. App Orbit's VR team work to create mixed reality experiences of virtual and augmented reality for business applications such as construction and personal VR in the health and wellness space.
      </div>
    ),
    smallDescription:
      "App Orbit VR Apps deliver simulated experiences to see into other worlds and zoom into realities inaccesible to the naked eye. By designing across the senses we deliver immersive VR App experiences to solve challenging user problems",

    template: "vr",
  },
 {
    title: "IOS App Development",
    description: (
      <div>
        App Orbit works with solutions that tap into the full potential of the IOS
        ecosystem. Designing seamless app experiences which access the full
        features of Apple devices such as watches and homepods to keep your app
        on the cutting edge with the next generation of experiences.
      </div>
    ),
    smallDescription:
      "App Orbit IOS App ecosystem creates apps that enable IOS users to enjoy a seamless and integrated experience across the ecosystem.",

    template: "ios",
  },
   {
    title: "System Integrations",
    description: (
      <div>
       App Orbit develops all our apps with the end in mind. We understand how critical it is that apps integrate with other applications and systems to provide a coherent and frictionless customer experience.
      </div>
    ),
    smallDescription:
      "App Orbit IOS App ecosystem creates apps that enable IOS users to enjoy a seamless and integrated experience across the ecosystem.",

    template: "systemIntegration",
  },
  {
    title: "UX / UI Design Services",
    description: (
      <div>
      UIUX is a 'must have' to differentiate your app in a saturated marketplace. App Orbit has a team of HCD researchers working with UIUX designers to create meaningful, compelling, intutive, valuable and aesthetically pleasing designs.
      </div>
    ),
    smallDescription:
      "App Orbit recognise that leading UIUX can be the critical differentiator in a market of many 'me too' apps. Orbit's design thinking and interface design engages the senses and delivers on goals.",

    template: "design",
  },

  {
    title: "Website Development",
    description: (
      <div>
      App Orbit creates websites that covert prospects to customers and create valuable enduring relationships with users. Built using the latest UIUX and SEO trends, Orbit's websites integrate user experience with customer experience and work seamlessly within the brand's entire technology ecosystem.
      </div>
    ),
    smallDescription:
      "App Orbit work with best practice web innovation to design and develop websites that perform better in search and build trust and user engagement.",

    template: "website",
  },
  {
    title: "Hybrid Apps",
    description: (
      <div>
        Hybrid's single code for Andorid, IOS and web dramatically speeds up time to market and scale. App Orbit's team work at a high velocity in hyrbid platforms to enable high growth app's outpace their competitors.
      </div>
    ),
    smallDescription:
      "App Orbit hybrid apps can fast track your app launch and scale with a single source of code working across IOS, Andriod and web.",

    template: "hybrid",
  },
 
  
   {
    title: "Web Apps",
    description: (
      <div>
       Web apps give the levels of interaction and personalisation of mobile apps with the discoverability of the web. Our App Orbit web apps can be designed to meet brand awareness, multi touchpoint customer experience and user goals.
      </div>
    ),
    smallDescription:
      "App Orbit work with best practice web innovation to design and develop websites that perform better in search and build trust and user engagement.",

    template: "webapps",
  },
];
