import styled from "styled-components";

interface IImg {
  img: string;
}

export const Img = styled.div<IImg>`
  width: 100%;
  height: 318px;

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1200px) {
    height: 300px;
  }

  @media (max-width: 768px) {
    height: 156px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin: 30px 0 10px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
`;
