import { IDataInsights } from "./type";

import DataInsightsItem from "./item";
import graphic from "../../assets/img/inforgraphic.png"
import * as Styled from "./item/style"
import post1 from "../../assets/img/insights/firstPost/post.png";
import logo1 from "../../assets/img/insights/firstPost/logo.png";
import post2 from "../../assets/img/insights/secondPost/post.png";
import logo2 from "../../assets/img/insights/secondPost/logo.png";
import post3 from "../../assets/img/insights/thirdPost/post.png";
import logo3 from "../../assets/img/insights/thirdPost/logo.png";
import post4 from "../../assets/img/insights/fourthPost/post.png";
import logo4 from "../../assets/img/insights/fourthPost/logo.png";



export const dataInsights: IDataInsights[] = [
  {
    id: "creating_design",
    img: post1,
    logo:logo1,
    title:
      "Creating competitive advantage through customer centric software design",
    subTitle:
      "Competing through customer experience requires a business to think and act differently from its competitors. To be different requires creating...",
    text: (
      <div>
        <DataInsightsItem>
          Competing through customer experience requires a business to think and
          act differently from its competitors. To be different requires
          creating a unique value proposition realised along its end-to-end
          journey and a unique experience created at each customer touchpoint.
          <br />
          Custom software is the ideal platform to bring this difference to
          life, by delivering upon and sustaining competitive advantage in each
          interaction. It ensures customer centricity is both built into the DNA
          of an organisation and is wired into the architecture of its
          technology ecosystem.
        </DataInsightsItem>
        <DataInsightsItem title="Product approach to value">
          Product teams, which generates user value, and platform teams which
          provide the backend capabilities, can both become more agile with
          custom design software. Unlike ‘off the shelf’ software which locks in
          feature functionality and upgrades, custom software can keep up with
          the changing needs of the user and evolve as the business learns.
          Custom software design enables product and platform teams to pivot and
          iterate with ease, as the market dictates.
        </DataInsightsItem>

        <DataInsightsItem title="Unmatched experiences">
          Custom software allows a business to offer unmatched experiences and
          deliver on a value proposition that is more unique and relevant than
          their competitors. While ‘off the shelf’ software features can equate
          to undifferentiated experiences, custom software can be designed to
          deliver upon your competitive point of difference and bring your brand
          to life.
        </DataInsightsItem>

        <DataInsightsItem title="Frictionless">
          Custom software designs reduce friction. Reduced friction leads to an
          increased desire to realise user goals and a reduction in required
          effort, increasing customer success and engagement.
          <br />
          Because custom software is a fit for purpose solution, it reduces
          friction by working more smoothly and intuitively, both with customer
          facing and employee facing interactions. Friction is also reduced by a
          streamlined customer experience, unencumbered by business siloes,
          where workflows and customer journeys are designed into a single
          platform.
        </DataInsightsItem>
      </div>
    ),
  },
  {
    id: "digital_first_organisation",
    img: post2,
    logo:logo2,
    title: "Keys to thriving as a digital first organisation",
    subTitle:
      "The pandemic has caused almost every organisation to go digital, some these organisations moved on to do digital things, while others became ‘digital first’, placing....",
    text: (
      <div>
        <DataInsightsItem>
          The pandemic has caused almost every organisation to go digital, some
          these organisations moved on to do digital things, while others became
          ‘digital first’, placing digital at the heart of everything they do.
          To become digital, they executed a ‘digital first’ strategy, where
          they redefined how they created value through; digital transformation,
          disruption and a boldly reimagined future.
          <br />
          ‘Digital first’ organisations are either born ‘digital natives’, or
          undergo a digital transformation, to become ‘digital incumbents.’ Many
          businesses that are ‘digital first’ are experiencing rapid growth,
          today, 5 of the world’s top 6 most valuable organisations, such as
          Apple, Microsoft and Google are ‘digital natives’.
        </DataInsightsItem>
        <DataInsightsItem title="Digital first organisations innovate faster than their peers.">
          ‘Digital first’ organisations quickly innovate through the product
          lifecycle development. They are faster than their peers due to their
          innovation mindset and an agile culture that responds to change.
          ‘Digital first’ organisations that drive continuous innovation invest
          in new opportunities to; disrupt like a start-up, pivot to embrace
          emerging opportunities, design and iterate customer value and kick
          start and scale ventures.
        </DataInsightsItem>

        <DataInsightsItem title="Digital first organisations grow value in ecosystems and platforms">
          Ecosystem based partnerships are increasingly important for
          sustainable differentiation, especially for ‘digital first’
          organisations. ‘Digital firsts’ apply AI to make sense of the
          third-party data from their partner ecosystem and deploy machine
          learning to automate key ecosystem dependent customer experiences.
          ‘Digital first’ organisations who invest in ecosystems are the highest
          users of AI in the world.
        </DataInsightsItem>

        <DataInsightsItem title="Digital first companies transform data into knowledge.">
          Using AI, ‘digital first’ companies capture data and action the
          insights to create a seamless customer experience and automated
          workflows. ‘Digital first’ data is captured through bots, algorithms
          and IoT sensors, transmitted to data actuators and analysed in
          cloud-based processors. ‘Digital firsts’ make data available
          enterprise wide for both customer and employee facing interactions.
          Employees may apply this data in their workflow to increase
          productivity and provide more personalised and responsive service to
          the customers.
          <br />
          ‘Digital first’ companies use analytics to know their customer better
          than their peers. Analytics allows ‘digital firsts’ to be more attuned
          to customer behavioural shifts, responding quickly to an increase
          propensity to churn or a reduction in marketing effectiveness.
          ‘Digital firsts’ apply analytics to observe online behaviours and
          generate predictive models that manage the product lifecycle more
          effectively.
        </DataInsightsItem>
      </div>
    ),
  },
  {
    id: "usecases",
    img: post3,
        logo:logo3,
    title: "5 use cases to create a data driven competitive advantage in e-commerce.",
    subTitle:
      "Data already separates the winners from the losers in e-commerce and this is set to increase in years ahead. Both insight into and control of data, through an e-commerce Business Manager system, is key to creating data driven competitive advantage.",
    text: (
      <div>
        <DataInsightsItem>
         Data already separates the winners from the losers in e-commerce and this is set to increase in years ahead. Both insight into and control of data, through an e-commerce Business Manager system, is key to creating data driven competitive advantage. Armed with greater insight and control, through a Business Manager system, e-commerce businesses can seize opportunities faster than the competition and provide a more personalized sales experience to their customers
        </DataInsightsItem>
        <DataInsightsItem title="What is a Business Manager system?">
          An e-commerce Business Manager system is one overarching system, which enables all the business systems to talk each other. It aggregates disparate data from other systems within the business and allows Business Managers to have business-wide control, managed through a single interface.<br /><br/>
          A Business Manager system is essential for businesses that have multiple online and bricks and mortar stores, providing a holistic view and control of, multiple e-commerce touchpoints.<br /><br/>
          An ideal Business Manager interface includes a dashboard to make better, faster decisions, powered by data visualisation. A Business Manager system is chosen by businesses seeking greater efficiencies without the expense of ERP systems or the loss of margin to providers such as Shopify.<br /><br/>
          A Business Manager system can be integrated into a custom-built e-commerce store or can sit above legacy systems in an e-commerce dashboard. This dashboard, provides visibility, and is powered through a business intelligence system such as Power BI or Tableau. When added to custom designed controls, the Business Manager combines visibility with control, to allow real time business management and workflow automation.
        </DataInsightsItem>

        <DataInsightsItem title="5 use cases for an e-commerce Business Manager system">
          Ecosystem based partnerships are increasingly important for
          sustainable differentiation, especially for ‘digital first’
          organisations. ‘Digital firsts’ apply AI to make sense of the
          third-party data from their partner ecosystem and deploy machine
          learning to automate key ecosystem dependent customer experiences.
          ‘Digital first’ organisations who invest in ecosystems are the highest
          users of AI in the world.
        </DataInsightsItem>

        <DataInsightsItem title="Digital first companies transform data into knowledge.">
         Use Case 1 - The benefits of integrated data flow<br/><br/>
The feature - A cloud based, cross channel, single point of control data<br/><br/>
Key Benefits<br/>
          <br />
         1. Faster decisions, with integrated data flow.<br/>
2. Real time decision-making data at your fingertips.<br/>
3. 24/4 data access from anywhere.<br/>
4. Data driven decisions.<br/>
5. Real time data updates.
        </DataInsightsItem>
      </div>
    ),
  },
  {
    id: "onlineRetails",
    img: post4,
        logo:logo4,
    title: "The online retailers journey from start up to scale up",
    subTitle:
      "Scaling online stores and hybrid online and bricks and mortar stores, require centralised visibility and control. Lack of automation brings inefficiencies, while lack of control is a barrier to targeted responses to entering threats and opportunities. Online stores quickly outgrow entry level systems like Shopify and grow into more customised or enterprise e-commerce solutions.",
    text: (
      <Styled.ImgContainer>
        <img src={graphic} alt="graphic" />
      </Styled.ImgContainer>
    ),
  },
];
