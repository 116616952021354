import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";
import bgImg from "../../../assets/img/software/header/bg.png"
import video from "../../../assets/img/software/header/video.mp4"

const SoftwareFirstBlock = () => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperVideo>
        <video
          poster={bgImg}
          preload="none"
          muted
          loop
          autoPlay
        >
          <source src={video} type="video/mp4" />
        </video>
      </Styled.WrapperVideo>
      <WrapperContainer
        width="55%"
        minWidth="1400px"
        position="start"
        padding="unset"
      >
        <Styled.WrapperInfo>
          <Styled.Info>
            Customised software solutions to transform your business.
          </Styled.Info>
        </Styled.WrapperInfo>
      </WrapperContainer>
    </Styled.Wrapper>
  );
};

export default SoftwareFirstBlock;
