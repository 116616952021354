import styled from "styled-components";

export const Title = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 40px;

  margin-top: 50px;
  margin-bottom: 24px;
 
  & * {
    display: inline;
  }

  & > *:last-child {
    color: #ed0c32;
  }
`;
