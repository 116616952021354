import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
border-radius: 10px;
overflow: hidden;
  width: 1320px;
  height: 300px;

  margin-bottom: 20px;

  background: linear-gradient(267.22deg, #212121 0%, #0c0c0c 100%);

  &:last-child {
    margin-bottom: unset;
  }

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 734px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: unset;
  }
`;

export const WrapperInfo = styled.div`
  width: 62%;
  padding: 60px 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;

`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin: 24px 0;
  color: #fff;
`;

export const ButtonWrapper = styled.div`
text-transform: capitalize;
max-width: 180px;
`

export const Button = styled.div`
border: 1px solid #ED0C32;
    background-color: #ED0C32;
    color: #fff;
    padding: 8px 10px;
    margin-right: 7px;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background-color: #000;
      color: #ED0C32;
    }
`

interface IImg {
  img: string;
}

export const Img = styled.div<IImg>`
  width: 35%;
  height: 300px;

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: 280px;
  }
`;
