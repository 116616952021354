import styled from "styled-components";

export const Wrapper = styled.div`
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #333333;
  &:last-child {
    border-right: unset;
  }

  @media (max-width: 1400px) {
    width: 100%;
    border-right: unset;
  }
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  & > div:first-child {
    color: #ed0c32;
  }
`;

export const Text = styled.div`
  margin-top: 4px;
  text-align: center;
  @media(max-width:767px){
    font-size:13px
  }
`;
