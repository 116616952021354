import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const WrapperImg = styled.div`
  width: 100%;
  max-width: 538px;
  height: 898px;
  overflow: hidden;
  & > img {
    transform: translateX(-30%);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
