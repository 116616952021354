import WrapperContainer from "../../../../wrappers/container";
import * as Styled from "./style";
const WSBHeader = () => {
  return (
    <Styled.Wrapper>
      <WrapperContainer width="1100px">
        <Styled.WrapprtInfo>
          <Styled.Title>Our Work Approach</Styled.Title>
          <Styled.Text>
            Specialising in both Startups and Enterprises, app Orbit’s dedicated
            and experienced team is well equipped to work alongside you to
            develop and manage your next project.
            <br />
            <br />
            See below to learn more.
          </Styled.Text>
        </Styled.WrapprtInfo>
      </WrapperContainer>
    </Styled.Wrapper>
  );
};

export default WSBHeader;
