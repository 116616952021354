import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  margin: 95px 0;
`;

interface IIcon {
  icon: string;
}

export const Icon = styled.div<IIcon>`
  width: 24px;
  height: 24px;

  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
