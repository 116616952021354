import { useContext } from "react";
import { InsightContext } from "..";
import InsightsSecondBlock from "../../insights/secondBlock";
import * as Styled from "./style";

const IPFourthBlock = () => {
  const { id } = useContext(InsightContext);

  return (
    <Styled.Wrapper>
      <Styled.Title>Related Articles</Styled.Title>
      <InsightsSecondBlock idSkip={id} />
    </Styled.Wrapper>
  );
};

export default IPFourthBlock;
