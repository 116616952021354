import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  children: ReactNode;
}

const WrapperSI: FC<IProps> = ({ children }) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default WrapperSI;
