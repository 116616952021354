import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IDataInsights } from "../../../../commonData/insights/type";
import * as Styled from "./style";

const ISBItem: FC<Omit<IDataInsights, "text">> = ({
  title,
  subTitle,
  id,
  logo,
}) => {
  const navigate = useNavigate();

  return (
    <Styled.Wrapper>
      <Styled.WrapperInfo>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Text>{subTitle}</Styled.Text>
        <Styled.ButtonWrapper>
           <Styled.Button 
          onClick={() => {
            navigate(`/insights/${id}`);
          }}
          
        >
          Continue Reading
        </Styled.Button>
        </Styled.ButtonWrapper>
       
      </Styled.WrapperInfo>
      <Styled.Img img={`${logo}`} />
    </Styled.Wrapper>
  );
};

export default ISBItem;
