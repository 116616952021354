import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

`;

export const WrapperInfo = styled.div`
  display: flex;
  justify-content: space-between;

  position: relative;
  z-index: 2;
`;

export const WrapperTitle = styled.div`
  position: relative;
  z-index: 2;
  width: 475px;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 834px) {
    height: fit-content;
    padding: 60px 0;

    width: 100%;
    max-width: 643px;
    margin: 0 auto;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 35px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;

  & * {
    font-weight: 400;
    font-size: 15px;
  }
`;

export const WrapperPhones = styled.div`
  position: absolute;
  width: 100%;
  height: 700px;

  top: 0%;
  left: 0%;

  padding: 0 100px;

  display: flex;
  justify-content: end;
  gap: 30px;

  overflow: hidden;

  background-image: url("/header_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 834px) {
    position: relative;
    height: 500px;
    justify-content: center;
    padding: unset;
  }
`;

interface IWrapperColumn {
  translateY: string;
}

export const WrapperColumn = styled.div<IWrapperColumn>`
  transform: ${({ translateY }) => `translateY(${translateY})`};
  & > div {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin-bottom: unset;
  }
`;

interface IPhone {
  img: string;
}

export const Phone = styled.div<IPhone>`
  width: 255px;
  height: 517px;

  background-image: ${({ img }) => `url("${img}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 834px) {
    width: 201px;
    height: 407px;
  }
`;
