import "./App.css";
import GlobalModal from "./components/modal";
import WrapperGlobalContext from "./globalContext";
import RoutesApp from "./routes";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <WrapperGlobalContext>
      <ScrollToTop />
      <RoutesApp />
      <GlobalModal />
    </WrapperGlobalContext>
  );
}

export default App;
