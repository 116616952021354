import * as Styled from "./style";

const SNBHeader = () => {
  return (
    <div>
      <Styled.Title>App Orbit Custom Software Technologies</Styled.Title>
      <Styled.Text>
        App Orbit full stack of custom software technology delivered by a
        dedicated team of long serving experts.
      </Styled.Text>
    </div>
  );
};

export default SNBHeader;
