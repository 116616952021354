import styled from "styled-components";

export const WrapperBG = styled.div`
  background: #1f1f1f;

  padding-bottom: 100px;
  margin-bottom: 100px;

  @media (max-width: 990px) {
    background: black;
  }
  
`;

export const Title = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  padding: 80px 0 48px;
  max-width: 856px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 991px) {
    max-width: 456px;
     font-size: 24px;
  }
`;

export const WrapperItems = styled.div`
  @media (max-width: 1600px) {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
`;
