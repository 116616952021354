import styled from "styled-components";

export const Wrapper = styled.div`
  /* height: 600px; */
  display: flex;
  justify-content: center;
`;

export const WrapperImg = styled.div`
  width: 100%;
  height: fit-content;
  .imgL{
    /* max-width: 2800px; */
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .imgM{
    display: none;
  }
  .imgS{
    display: none;
  }
  
  @media (max-width: 991px) {
    .imgL{
      display: none;
    }
     .imgM{
      display: flex;
      max-width: 991px;
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
    @media (max-width: 699px) {
      height: fit-content;
      .imgL{
        display: flex;
      max-width: 699px;
      width: 100%;
      height: auto;
      vertical-align: middle;
  }
  .imgM{
    display: none;
  }
    }
  @media (max-width: 500px) {
    .imgM, .imgL{
    display: none;
  }
  .imgS{
    display: flex;
    max-width: 500px;
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
     }
`;
