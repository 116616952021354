import styled from "styled-components";

interface IWrapper {
  burgerStatus: number;
}

export const Wrapper = styled.div<IWrapper>`
  background-color: #000000;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  transform: ${({ burgerStatus }) =>
    `translateY(calc(-200vh - ${burgerStatus} * -100vh))`};

  transition: transform 0.5s;
 
`;

export const WrapperNav = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  display: flex;

  overflow: hidden;
`;

export const WrapperVideo = styled.div`
  width: 40%;
  height: 100%;
  /* background-image: url("/assets/header/background.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconClose = styled.div`
  position: absolute;
  top: 20px;
  left: calc(100% - 60px);

  cursor: pointer;
`;

export const WrapperLetsTalk = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  padding: 12px;
  overflow: auto;

`;

export const WrapperNavLetsTalk = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;

  & > div {
    cursor: pointer;
  }
`;
