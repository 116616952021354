import styled from "styled-components";

export const Wrapper = styled.div`
  height: 405px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  border:1px solid teal;

  &:last-child {
    margin-bottom: unset;
  }

  @media (max-width: 1200px) {
    width: 336px;
    /* width: 360px; */
    height: 405px;
    height: fit-content;
    flex-direction: column-reverse;
    margin-bottom: unset;
    background: #1f1f1f;
  }
      @media (max-width: 567px) { 
    width: 335px;

  }

`;

export const WrapperServices = styled.div`
width: 1100px; 
margin-left: auto;
margin-right: auto;
height: 435px;
background-color: #000;
position: relative;
display: flex;
align-items: center;

&:last-child {
    margin-bottom: unset;
  }
  &:not(:first-child){
    padding-top: 40px;
  }

  @media (max-width: 1200px) {
    width: 950px; 
    height: 435px;
    height: fit-content;
  

  }
  @media (max-width: 990px) {
    width: 336px; 
    height: 435px;
    height: fit-content;
    flex-direction: column-reverse; 
    margin-bottom: unset;
    background: #1f1f1f; 
   
  }

`;

interface IWrapperImg {
  imgFolder: string;
  bgMob: string;
  phone: string;
  bgImg: string;
  img:string
}

export const WrapperImg = styled.div<IWrapperImg>`
  width: 336px;
  height: 200px;
    /* height: 100%; */
  overflow: hidden;
  position: relative;

  & > div {
    width: 336px;
    height: 530px;
    position: absolute;
    /* top: 50%; */
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: center;
    background-image: ${({ bgImg }) => `url(${bgImg})`};
  }

  & > div:last-child {
    width: 290px;
    height: 590px;
    transform: translate(-50%, calc(-50% + 25px));
       background-image: ${({ img }) => `url(${img})`};
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: 200px;

    & > div {
         background-image: ${({ bgMob }) => `url(${bgMob})`};
    }

    & > div:last-child {
        background-image: ${({ phone }) => `url(${phone})`};
    }
  }
  
`;
export const WrapperContainer=styled.div`
margin-bottom: 40px;
overflow: hidden;
`;

export const WrapperImgAdditional = styled.div<IWrapperImg>`
  width: 400px;
  height: 479px;
  overflow: hidden;
  position: relative;
  margin-left: 90px;
//BG
  & > div {
    width: 370px;
    height: 505px;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: ${({ bgImg }) => `url(${bgImg})`};
    overflow:hidden;
  }

  & > div:last-child {
    width: 336px;
    height: 440px;
    background-size:contain;
    transform: translate(-50%, calc(-28% + 15px));
    background-image: ${({ img }) => `url(${img})`};
    overflow:hidden;
  }

  @media (max-width: 980px) {
    width: 336px;
    height: 200px;
    margin-left: 0px;

    & > div {
      width: 336px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: ${({ bgMob }) => `url(${bgMob})`};
    }

    & > div:last-child {
        background-image: ${({ phone }) => `url(${phone})`};
        width: 336px;
    height: 200px;
    }
  }

  
`;

export const WrapperInfo = styled.div`
max-width: 641px;
  /* width: calc(100% - 500px); */
  /* height: 100%; */
  padding: 150px 50px 50px 100px;
  /* height: 233px; */

  @media (max-width: 1200px) {
    width: 100%;
    padding: 16px;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  
`;

export const LineUnderName = styled.div`
  width: 10px;
  height: 30px;
  transform: skew(-30deg);
  background: linear-gradient(180deg, #ed0c0c 0%, #fa00ff 100%);

`;

export const Title = styled.div`
  position: absolute;
  font-size: 24px;
  font-weight: 700;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  height: 85px;
  
`;
export const TextAdditional = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  height: 85px;
  @media (max-width: 990px) {
    font-size: 13px;
  }
  
`;
export const ButtonWrapper = styled.div`
margin-right: auto;
margin-left: auto;
display: flex;
justify-content: center;
`
export const ButtonWrapperAdditional=styled.div`
  font-size: 16px;
  max-width: 155px;
  max-height: 48px;
  @media (max-width: 990px) {
    max-width: 304px;
  }
`;

export const BtnAdditional=styled.button`
font-size: 16px;
padding:10px 30px;
color: #fff;
cursor: pointer;
background-color: #ed0c32;
border:2px solid  #ed0c32;
&:hover{
  background-color: #000;
}
@media (max-width: 990px) {
  background-color: transparent;
  border:1px solid  #fff;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
}
`
