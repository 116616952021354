import { FC } from "react";
import * as Styled from "./style";
import CloseIcon from "../../../assets/img/close";
import BMNav from "./nav";
import LetsTalkComponent from "../../letsTalk";
import useSize from "../../../utils/useSize";
import ArrowIcon from "../../../assets/img/arrow";
import bgImg from "../../../assets/img/header/background.png"

interface IProps {
  burgerStatus: number;
  setBurgerStatus: (status: number) => void;
}

const BurgerMenu: FC<IProps> = ({ burgerStatus, setBurgerStatus }) => {
  const { width } = useSize();

  return (
    <Styled.Wrapper burgerStatus={burgerStatus}>
      <Styled.WrapperLetsTalk>
        <Styled.WrapperNavLetsTalk>
          <div onClick={() => setBurgerStatus(1)}>
            <ArrowIcon />
          </div>
          <div onClick={() => setBurgerStatus(0)}>
            <CloseIcon fill="white" />
          </div>
        </Styled.WrapperNavLetsTalk>
        <LetsTalkComponent id="lets_talk_burger" />
      </Styled.WrapperLetsTalk>
      <Styled.WrapperNav>
        <BMNav setBurgerStatus={setBurgerStatus} />
        <Styled.WrapperVideo>
          <img src={bgImg} alt="bg"/>
        </Styled.WrapperVideo>
        <Styled.IconClose onClick={() => setBurgerStatus(0)}>
          <CloseIcon fill={width > 768 ? "black" : "white"} />
        </Styled.IconClose>
      </Styled.WrapperNav>
    </Styled.Wrapper>
  );
};

export default BurgerMenu;
