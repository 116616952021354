import { FC } from "react";

interface IProps {
  color?: string;
  circle?: boolean;
  size?: number;
}

const ArrowIcon: FC<IProps> = ({
  color="#858585",
  circle = false,
  size = 50,
}) => {
  return (
    <svg
      width={String(size)}
      height={String(size)}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {circle && (
        <circle
          cx="25"
          cy="25"
          r="24.5"
          transform="rotate(-180 25 25)"
          stroke={color}
        />
      )}

      <path
        d="M23.334 34.666L14.0007 25.3327L23.334 15.9993M35.334 25.3327H14.0007H35.334Z"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
        color={color}
      />
    </svg>
  );
};

export default ArrowIcon;
