import * as Styled from "./style";

const SINTFourthBlock = () => {
  return (
    <div>
      <div>
      App Orbit is a leading developer in React Native, the chosen code for Instagram, Uber Eats, Skype, Soundcloud, Twitter and Airbnb.
      </div>
      <br />
      <Styled.Title>When is a React app recommended</Styled.Title>
      <br />
      <div>
        React is a platform that offers the efficiency of cross-platform
        development and single code base without sacrificing the user experience
        or access to native APIs. React Native enables you to build a real
        mobile native app and native codes can be added as required to gain
        access to the mobile devices full functionality. React is technically a
        hybrid platform but has native features.
      </div>
      <br />
      <div>React is for apps that seek to;</div>
      <br />
      <ul>
        <li>Reduce cost through a single code base.</li>
        <li>Have close to native user experience.</li>
        <li>Access device features such as geolocation and cameras.</li>
        <li>Can integrate with Native code where required.</li>
      </ul>
      <br />
      <Styled.Title>Orbit’s React team</Styled.Title>
      <br />
      <div>
        Orbit has a highly experienced React team with tens of thousands of
        hours of React development experience.
      </div> 
    </div>
  );
};

export default SINTFourthBlock;
