import { useId } from "react";
import WrapperContainer from "../../../wrappers/container";
import { dataInfo } from "./data";
import MTBInfoBlock from "./infoBlock";
import * as Styled from "./style";

const MainTB = () => {
  const idComponent = useId();
  return (
    <WrapperContainer>
      <Styled.WrapperHeader>
        <Styled.Header>
          <Styled.HeaderTitle>
            Orbit’s approach to innovation
          </Styled.HeaderTitle>
          <Styled.HeaderText>
            We work closely with the Design Councils Innovation framework to
            deliver the best design and delivery outcomes for our clients.
          </Styled.HeaderText>
        </Styled.Header>
      </Styled.WrapperHeader>
      <Styled.WrapperBlockInfo>
        {dataInfo.map((item, index) => (
          <MTBInfoBlock key={idComponent + "." + index} {...item} />
        ))}
      </Styled.WrapperBlockInfo>
    </WrapperContainer>
  );
};

export default MainTB;
