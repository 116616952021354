import { useNavigate } from "react-router-dom";
import TechnologiesComponent from "../../../components/technologies";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";


const MainSB = () => {
  const navigate = useNavigate()
  const { width } = useSize();
  

  return (
    <WrapperContainer height={width > 1400 ? "100%" : "fit-content"}>
      <Styled.Wrapper>
        <Styled.Title>
          We work with leading solutions in the app ecosystem
        </Styled.Title>
        <Styled.WrapperButton>
          <Styled.ButtonWork  onClick={() => navigate("/works")}>
          Our Work
          </Styled.ButtonWork>
        </Styled.WrapperButton>
        <TechnologiesComponent slider />
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default MainSB;
