import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/img/arrow";
import WrapperContainer from "../../wrappers/container";
import * as Styled from "./style";

interface IProps {
  onClickBTT?: () => void;
}

const onClickBackToTop = () => {
  const header = document.querySelector("header");

  if (header) window.scrollTo({ behavior: "smooth", top: 0 });
};

const FooterComponent: FC<IProps> = ({ onClickBTT = onClickBackToTop }) => {
  const navigate = useNavigate();
  return (
    <WrapperContainer width="1600px">
      <Styled.Wrapper>
        <Styled.WrapperButtonUpResponsive onClick={onClickBTT}>
          <Styled.WrapperArrow>
            <ArrowIcon size={30} />
          </Styled.WrapperArrow>
          <span>BACK TO TOP</span>
        </Styled.WrapperButtonUpResponsive>
        <Styled.WrapperItem className="item">
          <p>{`© ${new Date().getFullYear()} APP ORBIT`}</p>
          <p>ALL RIGHTS RESERVED</p>
        </Styled.WrapperItem>
        <Styled.WrapperButtonUp onClick={onClickBTT}>
          <Styled.WrapperArrow>
            <ArrowIcon size={30} />
          </Styled.WrapperArrow>
          <span> BACK TO TOP</span>
        </Styled.WrapperButtonUp>
        <Styled.WrapperItem>
          <p onClick={() => navigate("/privacy-policy")}>PRIVACY POLICY</p>
        </Styled.WrapperItem>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default FooterComponent;
