import { FC, ReactNode } from "react";
import { IInfo } from "../../../../type";
import * as Styled from "./style";

export interface IPropsSSBItem extends Partial<IInfo> {
  children?: ReactNode;
  width?: string;
  height?: string;
}

const SSBItem: FC<IPropsSSBItem> = ({
  title,
  text,
  children,
  width,
  height,
}) => {
  return (
    <Styled.Wrapper
      width={width}
      height={height}
      background={children ? "unset" : undefined}
    >
      {children || (
        <Styled.WrapperInfo>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Text>{text}</Styled.Text>
        </Styled.WrapperInfo>
      )}
    </Styled.Wrapper>
  );
};

export default SSBItem;
