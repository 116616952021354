import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 48px;

  & > div {
    width: calc(50% - 13px);
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
