import ButtonComponent from "../../../components/button";
import { scrollToLetsTalk } from "../../../components/letsTalk";
import useSize from "../../../utils/useSize";
import * as Styled from "./style";

const ServicesSecondBlock = () => {
  const { width } = useSize();
  return (

    <Styled.Wrapper>
      <Styled.WrapperTitle>
        <Styled.Title>
          <p>We think outside the  <span>[]</span></p>
         
        </Styled.Title>
      </Styled.WrapperTitle>
      <Styled.WrapperText>
        <Styled.Text>
         Every service is founded on our leading user experience design and development. We have a comprehensive range of apps from mobile to business, working will the latest solutions in the app ecosystem such as IOT, AR and AI.
        </Styled.Text>
        <Styled.ButtonWrapper>
          <ButtonComponent
          size="l"
          onClick={scrollToLetsTalk}
          block={width <= 820}
        >
          GET IN TOUCH
        </ButtonComponent>
        </Styled.ButtonWrapper>
        
      </Styled.WrapperText>
      </Styled.Wrapper>
     
  );
};

export default ServicesSecondBlock;
