import styled from "styled-components";

export const WrapperItems = styled.div`

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
    @media(max-width: 991px) {
      margin-bottom: 80px;
      margin-top: 48px;
    }
  @media(max-width: 767px) {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 680px;
  }
`;

