import styled from "styled-components";

export const Wrapper = styled.div`

  display: flex;
  align-items: center;
  gap: 24px;
  & span {
    color: rgba(255, 255, 255, 0.55);
    font-size: 13px;

  }
  & > div {
    cursor: pointer;
  }
  a{
  text-decoration: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.55);
  font-size: 13px;
  &:hover{
     transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
           color: rgba(255,255,255,.75);
  }
 
}
 @media(max-width:991px){
    display: none;
  }

`;
