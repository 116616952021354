import styled from "styled-components";

export const WrapperCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width:767px){
  background: #000000;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 822px;
  min-height: 472px;
  max-height: calc(100vh - 40px);
   @media(max-width:767px){
    flex-direction: column;
    height: fit-content;
    overflow: auto;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
`;

export const WrapperPnone = styled.div`
  width: 40%;
  min-height: 100%;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width:767px){
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface IPhone {
   imgFolder?: string;
  phone:string
}

export const Phone = styled.div<IPhone>`
  width: 217px;
  height: 440px; 
  /* height: 479px; */
  background-image: ${({ phone }) => `url(${phone})`};

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  
`;

export const WrapperInfo = styled.div`
  /* width: 60%; */
  max-width: 548px;
  min-height: 100%;
  max-height: calc(100vh - 40px);
  background: #000000;
  padding: 20px;
  position: relative;
  @media(max-width:767px){
   max-width: 100%;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  min-height: calc(100% - 150px);
  max-height: calc(100% - 150px);
  /* overflow: auto; */
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const WrapperClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;



  & > * {
    cursor: pointer;
  }

`;

export const WrapperArrow = styled.div`
  /* position: absolute;
  top: 100%; */

  display: flex;
  align-items: center;
  margin-top: 20px;
  /* transform: translateY(calc(-100% - 20px)); */

  & > div {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  & > div:last-child {
    transform: rotateZ(180deg);
    margin-left: 5px;
  }
`;
