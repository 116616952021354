import styled from "styled-components";

export const Title = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 39px;
  margin: 50px 0;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

export const WrapperService = styled.div`
  margin-bottom: 70px;
`;
