import useSize from "../../utils/useSize";
import WrapperContainer from "../../wrappers/container";
import ButtonComponent from "../button";
import { scrollToLetsTalk } from "../letsTalk";
import * as Styled from "./style";
import phoneImgBig from "../../assets/img/askOurTeam/phone_big.png";
import phoneImgSmall from "../../assets/img/askOurTeam/phone_small.png";

const AskOurTeamComponent = () => {
  const { width } = useSize();

  return (
    <WrapperContainer width="1100px">
      <Styled.Wrapper>
        <Styled.WrapperInfo>
          <Styled.Title>
            Ask our team to bring your idea to life with a free clickable
            prototype.*
          </Styled.Title>
          <Styled.Text>
            Talk to our team to discover how custom software can transform your
            business.
            <br />
            <br />
            We provide a free 1-hour consultation, a free proposal and quote and
            a free prototype to eligible customers.*
          </Styled.Text>
          <Styled.ButtonWrapper>
                 <ButtonComponent onClick={scrollToLetsTalk} size="l" mode="black" block={width <= 768}>
            LET'S TALK
          </ButtonComponent>
          </Styled.ButtonWrapper>
     
        </Styled.WrapperInfo>
        <Styled.WrapperPhones>
          <Styled.Phone img={phoneImgBig} size="big" />
          <Styled.Phone img={phoneImgSmall} size="small" />
        </Styled.WrapperPhones>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default AskOurTeamComponent;
