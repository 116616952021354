import { useId } from "react";
import { dataLab } from "../../../../commonData/lab/data";
import * as Styled from "./style";

const LSBNav = () => {
  const idComponent = useId();

  const onClick = (idItem: string) => {
    const element = document.querySelector(`#${idItem}`);

    if (element) element.scrollIntoView({ behavior: "smooth",block:"center" });
  };

  return (
    <Styled.Wrapper>
      {dataLab.map(({ title, idItem }, index) => (
        <Styled.Item
          key={idComponent + "LSBNav" + index}
          onClick={() => onClick(idItem)}
        >
          {title}
        </Styled.Item>
      ))}
    </Styled.Wrapper>
  );
};

export default LSBNav;
