import { IDataServicesInfo } from "../../pages/servicesInfo/types";
import BusinessFirstBlock from "./business/SecondBlock";
import SIDFirstBlock from "./design/firstBlock";
import SIHFirstBlock from "./hybrid/firstBlock";
import SIIOSFirstBlock from "./ios/firstBlock";
import SIISOThirdBlock from "./ios/thirdBlock";
import SINTFirstBlock from "./nativeMobile/firstBlock";
import SINTFourthBlock from "./nativeMobile/fouthBlock";
import SINTThirdBlock from "./nativeMobile/thirdBlock";
import SINCSecondBlock from "./noCode/secondBlock";
import FirstBlockSystems from "./systemIntegration/secondBlock";
import SecondBlockSystems from "./systemIntegration/thirdBlock";
import SIVRFirstBlock from "./vr/firstBlock";
import FirstBlockWebApps from "./webapps/firstBlock";
import SIWSFirstBlock from "./website/firstBlock";

export const nativeMobile: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>Native Mobile</div>
      <div>App Development</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Orbit's team of app designers and developers are ready to work with you on your Native app development project. Our work includes journey mapping, UIUX, prototype and a 1 to 2 year roadmap.",
    secondText:
      "Native apps offer a more exclusive user experience that takes advantage of the device's specific internal features delivering seamless results every time.",
  },
  thirdBlock: [
    {
      title: "When are Native Apps recommended",
      data: <SINTFirstBlock />,
    },
    {
      title: "Orbit Native App team",
      data: "Depending on your audience, you may wish to develop in IOS or Android first. Native iOS apps are developed in Swift or Objective-C while native Android apps are written in Java.Orbit has a dedicated IOS and Android native app team. App Orbit has a dedicated IOS and Android native app team.",
    },
    {
      title: "Microservices",
      data: <SINTThirdBlock />,
    },
    {
      title: "Cross platform Native apps",
      data: <SINTFourthBlock />,
    },
  ],
};

export const noCode: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>No Code - Low Code</div>
    </>
  ),
  secondBlock: {
    firstText:
      "No code or Low code apps, offer a low cost way to test your new product idea or venture in the market. App Orbit can work with your team to design a no code solution with features built in minutes rather than days. We can help you develop 1 to 2 year plans that will help your product scale and migrate into other technologies as required.",
    secondText:
      "No Code and Low Code apps are set to make up 64% of the application activity by 2024. No code apps are used for websites, social platforms, buyer-seller platforms, voice apps, Augmented Reality, automated workflow and Software as a Service. Low code apps are essentially no code apps that are completed and deployed by developers.",
  },
  thirdBlock: [
    {
      title: "",
      data: "No code solutions are enabling smaller businesses opportunities and speculative ventures to enter the app market at a more affordable level. No code is also used for speed to market, allowing Rapid Application development and testing. Templates based on the world’s most popular websites such as AirBnb, Uber, Dropbox allow these sites to be replicated in no code.",
    },
    {
      title: "When is a No Code and Low Code recommended",
      data: <SINCSecondBlock />,
    },
    {
      title: "Benefits of No Code and Low code",
      data: "Features that can take days to be built are created in minutes and hours. Through no code and low code apps, start-ups can get to minimal viable product in an affordable way. No code apps can be linked to enterprise ecosystems, allowing rapid experimentation with new business models, A/B testing and service offerings.",
    },
    {
      title: "Orbit No code and Low code team",
      data: "Orbit app development team are a custom software and application development team that have embraced the power of no-code. This means our team can augment no and low code solutions with native and hybrid technologies to meet your unique needs. As API experts we can enable you to use data and features from other technologies. At App Orbit, we work with our clients to build an understanding of where no code and low code fits within the ecosystem and when more robust technologies are required for scale.",
    },
  ],
};

export const vr: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>VR App</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Virtual reality provides simulations that have applications in health, workplace collaborations, training, education, sales, marketing and experiences. Virtual reality mixes with augmented reality, the overlay of digital content in the real world to create Mixed Reality. Virtual reality is growing at 30% per annum.",
    secondText:
      "App Orbit are ready to work with your team to take your products and services into the simulated world of VR. Our VR technologies will serve your longer term business objectives while meeting your MVP goals.",
  },
  thirdBlock: [
    {
      title: "When are VR apps recommended",
      data: <SIVRFirstBlock />,
    },
    {
      title: "Benefits of VR app design and development",
      data: "Virtual reality can solve business problems by providing experiences that give greater data visualisation and interaction than conventional mobile applications. It can allow a richer level of experience and simulation generating a more expansive and detailed representations of reality. The business case and use case for VR apps are growing year on year.",
    },
    {
      title: "Orbit’s VR app team",
      data: "Orbit's app VR development process spans from creating basic walkthroughs to extensive VR solutions. The VR app team use Unity, Unreal, Blender, Maya, Oculus Medium, Google VR development tools and Adobe. Services for VR app development include virtual production, 3D assets creation, conceptual development. VR tools can be developed in IOS and Android.",
    },
  ],
};

export const hybrid: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>Hybrid App Development</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Orbit's App team can work with you to fast track your Hyrbid app into the market. With tens of thousands hours of experience in Hybrid development App Orbit can tackle the most ambitious projects with scalable quality outcomes.",
    secondText:
      "Many of the world’s leading apps are hybrid including; Evernote, Amazon Store, App Store and Uber. While native has the edge in performance, Hybrid is considered the right choice for many app-based ventures.",
  },
  thirdBlock: [
    {
      title: "When is a Hybrid app recommended",
      data: <SIHFirstBlock />,
    },
    {
      title: "Orbit Hybrid App team",
      data: "Hybrid apps are developed in Javascript, HTML, and CSS and run in a simplified browser within your app, known as webview. Orbit team have decades of experience in Hybrid App development.",
    },
  ],
};

export const ios: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>IOS App ecosystem</div>
    </>
  ),
  secondBlock: {
    firstText:
      "App Orbit's team are passionate about the possibilities of IOS App ecosystem which are designed to more fully engage the user's senses and creative expression. Working with design across the senses and user empowerment, we can work with your team to take your App ecosystem to the next level.",
    secondText:
      "Apple’s device ecosystem enables users who have purchased the entire line of Mac devices to unlock an unparalleled user experience. Apple devices; TV’s watches, iPad, iPhone, pencils, HomePods, AirPods, MacBooks and desktops, allow a seamless and integrated experience where users can synchronise interactions between devices on icloud.",
  },
  thirdBlock: [
    {
      title: "When are IOS Apps recommended",
      data: <SIIOSFirstBlock />,
    },
    {
      title: "Benefits of IOS applications",
      data: "IOS users outspend their peers by 4x and are a highly prized market. IOS applications open up possibilities for unique data and sensory rich experiences which cannot be matched by stand alone mobile apps. The convenience of a seamless ecosystem which recognises users and interactions across devices, opens up a whole new world of possibilities where features work in concert and embed themselves more deeply into the user's life.",
    },
    {
      title: "Orbit’s IOS ecosystem team",
      data: <SIISOThirdBlock />,
    },
  ],
};

export const design: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>User Interface User Experience design</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Orbit’s team of UIUX researchers and designers use the latest trends and technologies to bring your users, experiences that work across the senses. Experiences that are responsive, predictive, and personalised. Our user experience teamwork can take your users into a journey that includes the new dimensions of the metaverse, sound, the internet of things and bots.",
    secondText:
      "As the world becomes more digital, UIUX has become more critical, enabling users to increasingly interact in a digital realm. An increasingly discerning user is seeking personalised, immersive and gamified experiences which are easy, effortless and desirable. User Interface design defines the look and feel and functional interactive design.",
  },
  thirdBlock: [
    {
      title:
        "When is a comprehensive User Interface User Experience design recommended",
      data: <SIDFirstBlock />,
    },
    {
      title: "Orbit UIUX team",
      data: "Orbit have an exceptional UIUX team with product design and customer experience design experience. Orbit UIUX team are able to take an idea to launch with our end to end research, design and testing approach.",
    },
  ],
};

export const website: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>Website Development</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Clean, fast and visually compelling are the three key defining trends of web in 2024-2026. Trends continue to redefine how websites are built and designed. Personalisation, chat bots, voice interfaces, web app functionality, animation and data visualisation, will be the driving forces behind website design. In web UI in 2025 and beyond, expect to see more organic shapes, 3D elements, interactive animation, horizontal scrolls, thumb gestures, minimalism and dark screens.",
    secondText:
      "App Orbit works with your team to create your next generation website, applying the latest trends and technologies. Together, we can co-create a web customer and user experience that puts you years ahead of your competition.",
  },
  thirdBlock: [
    {
      title: "When are improvements in web design and development recommended",
      data: <SIWSFirstBlock />,
    },
    {
      title: "Benefits of web design and development",
      data: "Websites need to keep pace with rising user expectations to realise their business objectives. Cutting edge websites are designed around an end to end multi-device customer experience and more AI driven UX experience. Sites that fail to adopt emerging trends will feel clunky and outdated to an increasingly discerning user. AI is will take over from A/B testing to drive insights for ongoing website optimisation.",
    },
    {
      title: "Orbit’s Web App team",
      data: "Orbit’s app website development team are focused on applying best practice trends in CX, UXUI to your site design. Using human centred design and best practice design trends we can create intuitive and empathetic web design which will both engage users and deliver on business objectives. Our SEO tools and powerhouse web development team will provide future ready technology to ensure your website is never left behind.",
    },
  ],
};

export const business: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>Business Applications</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Business applications deliver on the digital transformation of an organisation, increasing profitability through significant uplifts in cost savings and growth outcomes.",
    secondText:
      "Business applications are developed as digital products, for a single enterprise wide system or a custom solution for a single business requirement.",
  },
  thirdBlock: [
    {
      title: "When are business applications recommended",
      data: <BusinessFirstBlock />,
    },
    {
      title: "Benefits of business applications",
      data: "Business applications are designed to increase productivity, security, performance, reliability and scalability. The rate of innovation and digital transformation is accelerating. Businesses with high levels of digital maturity enjoy better; gross margin, long term financial outcomes, customer experience and product and service quality.",
    },
    {
      title: "Orbit’s business application team",
      data: "Orbit’s app development team are a customer software and application development team with ten of thousands of hours experience from building small proof of concept applications to 7 figure solutions. Our agile development team work with clients on technology strategy, prototyping, test simulations, cost benefit analysis, system integration, API strategy cloud, data and AI.",
    },
  ],
};
export const systemIntegration: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>System Integration</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Successful digital transformation relies on a seamless customer and employee experience and productivity gains through increased business efficiencies, both of these improvements are dependent on successful system and application integration. System integration introduces new applications into the technology architecture to improve efficiency and experiences, while application integration formats the data to be compatible across the IT architecture.",
    secondText:
      "As new products and features are continuoulsy introduced to a business, the importance of successful system integration increases. App Orbit's team have decades of experience integrating systems to work in concert and enabling the real time transfer of data across the organisation. We will work with your team to develop an approach to system integration that will enable your business to be agile at scale.",
  },
  thirdBlock: [
    {
      title: "When is system and application integration recommended",
      data: <FirstBlockSystems />,
    },
    {
      title: "Benefits of system and application integration",
      data: <SecondBlockSystems />
    },
    {
      title: "Orbit’s system integration team",
      data: "Orbit’s system and application integration team work on cloud solutions in Java and .NET capability, API development and cloud integration and microservice design principles. Orbit's systems and application development team work with SAFe agile project management.",
    },
  ],
};
export const webapps: IDataServicesInfo = {
  firstBlock: (
    <>
      <div>Web Apps</div>
    </>
  ),
  secondBlock: {
    firstText:
      "Web apps are meeting the growing demand for 'always on', personalised, interactive and multi device experiences. App Orbit can collaborate with your team to create customer value and improved business capabilities to take your web app to the next level. We can help you deliver MVP's and agile delivery to fast track your web app implementation.",
    secondText:
      "While webapps don’t have the performance of hybrid or native apps, they are still used successfully for some of the world’s top apps such as; Google Analytics, Microsoft Office, Trello, Google Docs, Netflix, MailChimp, and Telegram. Increased user expectation for interaction, personalisation, data integration and custom features mean more and more companies are investing in web apps to replace static websites.",
  },
  thirdBlock: [
    {
      title: "When is a Web app recommended",
      data: <FirstBlockWebApps />,
    },
    {
      title: "Progressive web apps",
      data: "Progressive web apps, PWA’s are boosted web apps that can load without internet connection. Progressive web apps continue to close the gap with Native apps, once limited to Chrome, now Apple, Firefox for Android, MS Edge and Safari accept PWA’s."
    },
    {
      title: "Orbit’s Web App team",
      data: "App Orbit have a dedicated web app team which has developed projects from small budgets to multimillion dollar projects. Our agile project methodology allows us to keep pace with the continuous innovation of Digital First organisations.",
    },
  ],
};