import * as Styled from "./style";
import firstStep from '../../../assets/img/main/firstStep.png'
import video from "../../../assets/img/main/video.mp4"


const AboutThridBlock = ()=>{
  return (
    <Styled.Wrapper>
      <video
        poster={firstStep}
        preload="none"
        muted
        loop
        autoPlay
      >
        <source src={video} type="video/mp4" />
      </video>
    </Styled.Wrapper>
  );
}


export default AboutThridBlock;