import { useContext } from "react";
import { WorkContext } from "..";
import OurWorkSB from "../../ourWork/secondBlock";
import * as Styled from "./style";

const WorkNinthBlock = () => {
  const { id} = useContext(WorkContext)
  return (
    <div>
      <Styled.Title>Check out other projects</Styled.Title>
      <OurWorkSB idSkip={id} />
    </div>
  );
};

export default WorkNinthBlock;
