import { FC, useId } from "react";
import WrapperContainer from "../../../wrappers/container";
import SITBItem, { IPropsSITBItem } from "./item";
import * as Styled from "./style";

interface IProps {
  data: IPropsSITBItem[];
}

const SIThirdBlock: FC<IProps> = ({ data }) => {
  const idComponent = useId();
  return (
    <Styled.Wrapper>
      <WrapperContainer width="1320px">
        {data.map((item, index) => (
          <SITBItem key={idComponent + "Item" + index} {...item} />
        ))}
      </WrapperContainer>
    </Styled.Wrapper>
  );
};

export default SIThirdBlock;
