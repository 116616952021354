import styled from "styled-components";

interface IWrapper {
  width: string;
}

export const Wrapper = styled.div<IWrapper>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
   @media(max-width:1400px){
      max-width: 1116px;
  }
      @media(max-width:1200px){
      max-width: 936px;
  }
`;

export const Label = styled.label`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
`;

export const Input = styled.input`
  width: 100%;
max-width: 636px;
height: 46px;
  background-color: #1a1a1a;
  border: unset;
  outline: unset;
  color: #fff;
  padding: 10px 15px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;


  &:focus {
    border: unset;
    outline: unset;
  }
   @media(max-width:1400px){
      max-width: 546px;

  }
      @media(max-width:1200px){
      max-width: 456px;
  }
          @media(max-width:991px){
      max-width: 336px;
  }
   @media(max-width:767px){
    max-width: 516px;
  }
`;

export const InputLong = styled.input`
  width: 100%;
  max-width: 1296px;
  height: 46px;
  background-color: #1a1a1a;
  border: unset;
  outline: unset;
  color: #fff;
  padding: 10px 15px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;


  &:focus {
    border: unset;
    outline: unset;
  }
   @media(max-width:1400px){
      max-width: 1116px;
  }
      @media(max-width:1200px){
      max-width: 936px;
  }
          @media(max-width:991px){
      max-width: 696px;
  }
   @media(max-width:767px){
    max-width: 516px;
  }
`;

export const Error = styled.div`
  font-size: 13px;
  color: #ed0c32;
  margin-top: 4px;
`;
