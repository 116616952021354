import styled from "styled-components";


export const Wrapper = styled.div`
max-width: 1900px;
 background-color: #000;
display: flex;
justify-content: right;
  margin-bottom: 80px;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const WrapperTitle = styled.div`
  max-width: 440px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 70px;
  @media (max-width: 1200px) {
    padding-right: unset;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }

 
`;

export const Title = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 57px;

  & span:last-child {
    color: #ed0c32;

  }

  @media (max-width: 1200px) {
    width: fit-content;
    margin: 0 auto;
    padding: 40px 0;
    text-align: center;
  }
@media (max-width: 1040px) {
    text-align:left;
    max-width: 300px;
    
  }
  @media (max-width: 991px) {
    font-size: 30px;
    max-width: 215px;
  }
   @media (max-width: 767px) {
  display: flex;
  text-align: center;
  line-height: 30px;
  padding: 0;
  max-width: 380px;
  }
  @media (max-width: 699px) {
    padding: 48px 0;
    font-size: 40px;
    max-width: 100%;
  }
    @media (max-width: 500px) {
 
    text-align: center;
  }

`;

export const WrapperText = styled.div`
  width: 60%;

  background: #151515;
  padding: 48px 0 48px 200px;

  @media (max-width: 1600px) {
    padding: 80px 0px 80px 62px;
  }
 
    @media (max-width: 991px) {
    padding: 48px 0px 48px 24px;

  }
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    padding: 24px 20px;
    margin-left: 180px;
  }
    @media (max-width: 699px) {
    padding: 48px 25px 48px 12px;
    margin-left: 114px;
    max-width: 500px;
  }
  @media (max-width: 500px) {
     max-width: 100%;
     margin-left: 0;
  }
`;

export const Text = styled.div`
  width: 57%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
  @media (max-width: 1600px) {
    width: 70%;
  }
  @media (max-width: 1040px) {
    max-width: 920px;
  }

  @media (max-width: 767px) {
    max-width:499px;
    margin-right: auto;
  }
`;

export const ButtonWrapper = styled.div`
width: 100%;
max-width: 182px;
`
