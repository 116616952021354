import styled from "styled-components";


export const Wrapper = styled.div`
margin-left: auto;
margin-right: auto;
width: 100%;
max-width: 1320px;
/* height: 100vh; */
margin-bottom: 80px;
display: flex;
flex-direction: column;
align-items: center;
 @media(max-width:1400px){
      max-width: 1116px;

  }
      @media(max-width:1200px){
      max-width: 936px;
  }
        @media(max-width:991px){
      max-width: 696px;
  }
   @media(max-width:767px){
    max-width: 516px;
  }
   @media(max-width:560px){
    max-width: 366px;
  }
`;

export const ImgContainer = styled.div`
  max-width: 636px;
  margin-right: auto;
  margin-left: auto;
  img{
      width: 636px;
      height: 85px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 20px;
      display: flex;
    }
      
  @media(max-width:1400px){
    
      max-width: 546px;
      img{
          width: 546px;
          height: 73px;
          margin-bottom: 20px;   
  }
}
   @media(max-width:1200px){
      max-width: 456px;
      img{
          width: 456px;
          height: 61px;
          margin-bottom: 20px;
        }
  }
  @media(max-width:991px){
      max-width: 356px;
      img{
          width: 356px;
          height: 45px;
          margin-bottom: 20px;
        }
  }
   @media(max-width:767px){
       max-width: 516px;
      img{
          width: 516px;
          height: 69px;
          margin-bottom: 48px;
  }}
  @media(max-width:575px){
       max-width: 527px;
      img{
          width: 527px;
          height: 71px;
          margin-bottom: 48px;
  }}
  @media(max-width:550px){
       max-width: 500px;
      img{
          width: 500px;
          height: 67px;
          margin-bottom: 48px;
  }}
  
    @media(max-width:500px){
       max-width: 452px;
      img{
          width: 452px;
          height: 60px;
          margin-bottom: 48px;
  }}
   @media(max-width:460px){
       max-width: 411px;
      img{
          width: 411px;
          height: 55px;
          margin-bottom: 48px;
  }}
  @media(max-width:420px){
       max-width: 372px;
      img{
          width: 372px;
          height: 50px;
          margin-bottom: 48px;
  }}
  
  


`
export const WrapperLetsTalk = styled.div`
  display: flex;
  gap: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 120px;
   line-height: 109px; 

  text-transform: uppercase;
  & > div:first-child {
    color: black;
    text-shadow: 0px 4px 4px #ffffff, 0px -4px 4px #ffffff, 4px 0px 4px #ffffff,
      -4px 0px 4px #ffffff;
    /* -webkit-text-stroke: 2px white; */
  }

  @media (max-width: 820px) {
    font-size: 40px;
  }
`;

export const WrapperField = styled.div`
  width: 100%;
  margin-top: 40px;
  & > div {
    margin-bottom: 20px;
  }
  & > div:last-child {
    margin-bottom: unset;
  }
  @media(max-width:1400px){
      max-width: 1116px;

  }
    @media(max-width:1200px){
      max-width: 936px;
  }
      @media(max-width:991px){
      max-width: 696px;
  }

  @media(max-width:767px){
    max-width: 516px;
  }
`;

export const WrapperTwoField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: initial;
    width: 100%;
    gap: 20px 0;
  }
`;

export const TextareaContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;

& .text{
  max-width: 1296px;
height: 212px;
  background-color: #1a1a1a;
  border: unset; 
  outline: unset;
  color: #fff;
  padding: 10px 15px;

  font-size: 16px;
  font-weight: 400;


  &:focus {
    border: unset;
    outline: unset;
  }

  @media(max-width:1400px){
      max-width: 1116px;
  }
      @media(max-width:1200px){
      max-width: 936px;
  }
   @media(max-width:767px){
    max-width: 516px;
  }
}
`
export const Label = styled.label`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;

  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
`;
export const ButtonWrapper = styled.div`
max-width: 1300px;
`