import { FC, useId } from "react";
import { data } from "./data";
import OWSBItem from "./item";
import * as Styled from "./style";

interface IProps {
  idSkip?: string;
}

const OurWorkSB: FC<IProps> = ({ idSkip }) => {
  const idComponent = useId();
  return (
    <div>
      <Styled.Wrapper>
        {data
          .filter(({ to }) => to !== idSkip)
          .map((item, index) => (
            <OWSBItem key={idComponent + "OurWorkSB" + index} {...item} />
          ))}
      </Styled.Wrapper>
    </div>
  );
};

export default OurWorkSB;
