import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const LabFirstBlock = () => {
  return (
    <Styled.Wrapper>
      <WrapperContainer width="1200px" padding="unset">
        <Styled.WrapperText>
          <div>
            <Styled.Title>Orbit’s approach to innovation</Styled.Title>
            <Styled.Text>
              We work closely with the Design Councils Innovation framework to
              deliver the best design and delivery outcomes for our clients.
            </Styled.Text>
          </div>
        </Styled.WrapperText>
      </WrapperContainer>
    </Styled.Wrapper>
  );
};

export default LabFirstBlock;
