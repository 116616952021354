import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IInfo } from "../../../../type";
import * as Styled from "./style";

export interface IPropsSTBItem extends IInfo {
  template: string;
}

const STBItem: FC<IPropsSTBItem> = ({ title, text, template }) => {
  const navigate = useNavigate();
  console.log('title :>> ', title);
  return (
    <>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Text>{text}</Styled.Text>
      <Styled.Button
        onClick={() =>
          navigate("/services/info", {
            state: { template },
          })
        }
      >
        Learn More
      </Styled.Button>
    </>
  );
};

export default STBItem;
