import { FC, useId } from "react";
import * as Styled from "./style";
import { IDataSoftwareTechnologies } from "../../../../commonData/softwareTechnologies/types";

const SNBItem: FC<IDataSoftwareTechnologies> = ({ title, imgs }) => {
  const idComponent = useId();

  return (
    <div>
      <Styled.WrapperImg>
        {imgs.map((img, index) => (
          <Styled.Img key={idComponent + "." + index} img={img} />
        ))}
      </Styled.WrapperImg>
      <Styled.Title>{title}</Styled.Title>
    </div>
  );
};

export default SNBItem;
