// import { useContext } from "react";
// import { MainContext } from "..";
//  import FooterComponent from "../../../components/footer";
import LetsTalkComponent from "../../../components/letsTalk";
import * as Styled from "./style";

const MainFourthBlock = () => {
  // const { setStep } = useContext(MainContext);

  return (
    <Styled.Wrapper>
      <LetsTalkComponent
        // disableFooter
      />
      {/* <Styled.WrapperFooter>
        <FooterComponent onClickBTT={() => setStep(1)} />
      </Styled.WrapperFooter> */}
    </Styled.Wrapper>
  );
};

export default MainFourthBlock;
