import {
  createContext,
  useRef,
  useState,
} from "react";
import WrappperHeader from "../../wrappers/header";
import { initContext } from "./data";
import MainFB from "./firstBlock";
import MainFourthBlock from "./fouthBlock";
import MainSB from "./secondBlock";
import MainTB from "./thirdBlock";
import * as Styled from "./style"



export const MainContext = createContext(initContext);

const MainPage = () => {


  const [step, setStep] = useState(1);
  const sectionFirst = useRef(null);
  const sectionSecond= useRef(null);
  const sectionThird = useRef(null);
  const sectionFourth = useRef(null);
  
  const scrollToSection = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };



  return (
    <WrappperHeader>
      <MainContext.Provider value={{ step, setStep }}>
       
        <Styled.WrapperDots>
          <Styled.WrapperDot
            active={1 === step}
            onClick={() => { setStep(1);  scrollToSection(sectionFirst)}}
          >
            <Styled.Dot active={1 === step} />
          </Styled.WrapperDot>
          <Styled.WrapperDot
         
            active={2 === step}
            onClick={() => { setStep(2);  scrollToSection(sectionSecond)}}
          >
            <Styled.Dot active={2 === step} />
          </Styled.WrapperDot>
    <Styled.WrapperDot
         
            active={3 === step}
            onClick={() => {setStep(3); scrollToSection(sectionThird)}}
          >
            <Styled.Dot active={3 === step} />
          </Styled.WrapperDot>
          <Styled.WrapperDot
         
            active={4 === step}
            onClick={() => { setStep(4);  scrollToSection(sectionFourth)}}
          >
            <Styled.Dot active={4 === step} />
          </Styled.WrapperDot>
      </Styled.WrapperDots>
        <div ref={sectionFirst}>

          <MainFB />
        </div>
         <div ref={sectionSecond}>
          
          <MainSB />
        </div> 
        <div ref={sectionThird}>
          
          <MainTB />
        </div>
        <div ref={sectionFourth}>
          
          <MainFourthBlock />
         </div> 
      </MainContext.Provider>
    </WrappperHeader>
  );
};

export default MainPage;
