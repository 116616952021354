import { IDataAboutPeople } from "./types";



export const Louise_Kelly: IDataAboutPeople = {
  name: "Louise Kelly",
  photo: `/Louise_Kelly.webp`,
  position: "Founder + CEO",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};

export const Pavel_Martinov: IDataAboutPeople = {
  name: "Pavel Martinov",
  photo: `/Pavel_Martinov.png`,
  position: "Lead UX/UI Designer",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};

export const Human_1: IDataAboutPeople = {
  name: "Strategic Advisor",
  photo: `/human_1.png`,
  position: "Multidisciplinary Team",
  about:
   "There are over 15 developers on the App Orbit team including front end development in React, Angular, Vue, .Net/MVC.Net, PHP Laravel and Backend development in Firebase, MangoDb, Heroku, SQL Server, MySQL .",
};

export const Human_2: IDataAboutPeople = {
  name: "Technology",
  photo: `/human_2.png`,
  position: "Founder + CEO",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_3: IDataAboutPeople = {
  name: "Full stack developer",
  photo: `/human_3.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_4: IDataAboutPeople = {
  name: "WooCommerce Developer",
  photo: `/human_4.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_5: IDataAboutPeople = {
  name: "Word Press Developer",
  photo: `/human_5.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};

export const Human_6: IDataAboutPeople = {
  name: "Shopify Developer",
  photo: `/human_6.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};

export const Human_7: IDataAboutPeople = {
  name: ".Net Deevloper",
  photo: `/human_7.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_8: IDataAboutPeople = {
  name: "React Developer",
  photo: `/human_8.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_9: IDataAboutPeople = {
  name: "Flutter Developer",
  photo: `/human_9.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
export const Human_10: IDataAboutPeople = {
  name: "UIUX Designer",
  photo: `/human_10.png`,
  position: "Multidisciplinary Team",
  about:
    "We've partnered with big brands and enterprise organizations to design and develop innovative solutions to unique problems. Take a look at our past collaborations to learn more about what we're capable of creating.",
};
