import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;

  top: 0;
  left: 0;

  background-color: rgba(51, 51, 51, 0.58);
  backdrop-filter: blur(2px);
`;
