import styled from "styled-components";

export const Wrapper = styled.div`
  padding-right: 50px;

  @media (max-width: 1024px) {
    padding-right: unset;
  }
`;

export const Text = styled.div`
  font-weight: 700;
  font-size: 40px;
  max-width: 920px;

  & * {
    display: inline;
  }

  & > *:last-child {
    color: #ed0c32;
  }

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;
