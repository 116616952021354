
import styled from "styled-components";
import quote from '../../../assets/img/software/quote.png';
import third_block from '../../../assets/img/software/third_block.png';

export const Wrapper = styled.div`
  background-color: #ed0c32;
  height: 434px;
width: 100%;
max-width: 1600px;
  display: flex;
  justify-content: center;
  gap: 0 48px;
  @media (max-width: 1200px) {
    max-width: 900px;
    margin-left: auto;

  }
  @media (max-width: 991px) {
    height: fit-content;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    gap: 0 0px;
  }

   @media (max-width: 767px) {
    flex-direction: column;
    max-width: 564px;
    /* height: fit-content;
    margin-left: auto;
    margin-right: auto;
    gap: 0 0px; */
  }

  @media (max-width: 420px) {
    height: unset;
    flex-direction: column;
  }
`;

export const Img = styled.div`
  width: 297px;
  height: 100%;
  margin-left: -153px;
/* 
  background-image: url("/assets/software/third_block.png"); */
      background: url(${third_block});  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media(max-width:991px){
    margin-left: 0;
    width: 372px;
    height: 544px;
    
  }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 564px;
    height: 544px;
    }

  @media (max-width: 420px) {
    width: 100%;
    height: 824px;
  }
`;

export const WrapperInfo = styled.div`
  max-width: 700px;
  @media (max-width: 1050px) {
    max-width: 600px;
  }
    @media (max-width: 991px) {
         max-width: 372px;
         padding-left: 5px;
    }
    @media (max-width: 767px) {
      width: 100%;
      max-width: 564px;
      padding: 16px 48px;
   
    }
    
  @media (max-width: 420px) {
    width: 100%;
    padding: 0 12px;
  }
`;

export const Quote = styled.div`
  width: 70px;
  height: 111px;

  /* background-image: url("/assets/software/quote.png"); */
        background: url(${quote});  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px ;
`;
export const TextLast = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 24px ;
`;
export const TextX = styled.div`
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 8px;

`;
export const ButtonWrapper = styled.div`
max-width: 215px;
  @media (max-width: 767px) {
    max-width: 468px;
  }
`

export const WrapperName = styled.div`
  margin: 35px 0;
`;

export const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

export const NameDiscription = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
