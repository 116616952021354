import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 600px;
  min-width: 300px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 15px;
  color: #ed0c32;
  margin-bottom: 5px;
  text-align: center;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 22px;
  text-align: center;
`;
