import styled from "styled-components";
import fourth_block from "../../../assets/img/software/fourth_block.png";

export const Wrapper = styled.div`
  display: flex;
  height: 775px;

  @media (max-width: 991px) {
    height: unset;

    flex-direction: column;
  }
`;

export const Img = styled.div`
  width: 55%;
  height: 100%;
  background: url(${fourth_block});  
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 1024px) {
    width: 100%;
    height: 406px;
  }
`;

export const WrapperInfo = styled.div`
  width: 58%;

  padding: 50px 0 45px;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 82px 12px 45px;
  }
`;
