import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
  & > * {
    cursor: pointer;
    font-size: 13px;
    color: rgba(255,255,255,.55);
    &:hover{
      color: rgba(255,255,255,.75);
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
  }
   @media(max-width:991px){
    display: none;
  }
`;
