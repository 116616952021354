import { FC, useId, useState } from "react";
import { dataTechnologies } from "../../commonData/technologies/data";
import useSize from "../../utils/useSize";
import SliderComponent from "../slider";
import TCItem from "./item";
import TCModal from "./modal";
import TCSliderItem from "./sliderItem";
import * as Styled from "./style";

interface IProps {
  slider?: boolean;
}

const TechnologiesComponent: FC<IProps> = ({ slider = false }) => {
  const idComponent = useId();
  const { width } = useSize();

  const [idOpened, setIdOpened] = useState(0);

  if (slider && width > 991) {
    return (
      <>
        <SliderComponent >
          {dataTechnologies.map(({ textModal, ...props }, index) => (
            <TCSliderItem
              key={idComponent + ".SliderItem." + index}
              {...props}
              openModal={setIdOpened}
              idElement={index + 1}
            />
          ))}
        </SliderComponent>
        {!!idOpened && (
          <TCModal idOpened={idOpened} setIdOpened={setIdOpened} />
        )}
      </>
    );
  }

  return (
    <>
      <Styled.WrapperItems>
        {dataTechnologies.map(({ textModal, ...props }, index) => (
          <TCItem
            key={idComponent + ".Item." + index}
            {...props}
            openModal={setIdOpened}
            idElement={index + 1}
          />
        ))}
      </Styled.WrapperItems>
      {!!idOpened && <TCModal idOpened={idOpened} setIdOpened={setIdOpened} />}
    </>
  );
};

export default TechnologiesComponent;
