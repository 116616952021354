import styled from "styled-components";

export const Wrapper = styled.div`
  width: 97%;
  display: flex;
  border-top: 1px solid #333333;

  & > div {
    border-right: 1px solid #333333;
  }

  & > div:last-child {
    border-right: unset;
  }
`;
