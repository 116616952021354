import { FC } from "react";
import { IDataSoftwareTeam } from "../../../../commonData/softwareTeam/type";
import * as Styled from "./style";

const SEBItem: FC<IDataSoftwareTeam> = ({ title, img }) => {
  return (
    <Styled.Wrapper img={img}>
      <Styled.Title>{title}</Styled.Title>
    </Styled.Wrapper>
  );
};

export default SEBItem;
