import styled from "styled-components";

export const WrapperDots = styled.div`
  position: fixed;
  top: 50%;
 right: 0;
  transform: translate(-100%, -50%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
  /* padding-right: 12px; */
    @media(max-width:1400px){
     right: 80px;
  }
  @media(max-width:860px){
     right: -25px;
  }

`;



interface IWrapperDot {
  active?: boolean;
}

export const WrapperDot = styled.div<IWrapperDot>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
border: ${({ active }) => (active ? "1px solid #ED0C32" : "unset")}; 
  cursor: pointer;
`;

interface IDot {
  active?: boolean;
}

export const Dot = styled.div<IDot>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "#ED0C32" : "white")}; 
`;
