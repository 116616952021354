import { FC, useState } from "react";
import useSize from "../../utils/useSize";
import WrapperContainer from "../../wrappers/container";
import ButtonComponent from "../button";
import FooterComponent from "../footer";
import InputComponent from "../input";
import { initData } from "./data";
import * as Styled from "./style";
import LetsTalkText from "./text";
import { IData } from "./types";

import letsTalkImg from "../../assets/img/lets_talk.png";

interface IProps {
  id?: string;
  onClickBTT?: () => void;
  disableFooter?: boolean;
}

export const scrollToLetsTalk = () => {
  const element = document.querySelector("#lets_talk");

  if (element) element.scrollIntoView({ behavior: "smooth" });
};

// const getWidthContainer = (width: number) => {
//   if (width > 1600) return "820px";
//   if (width > 758) return "750px";
//   return "calc(100% - 20px)";
// };

const LetsTalkComponent: FC<IProps> = ({
  id = "lets_talk",
  onClickBTT,
  disableFooter = false,
}) => {
  const [data, setData] = useState(initData);
  const { width } = useSize();

  const getInputProps = (key: keyof IData) => ({
    ...data[key],
    onChange: (value: string) =>
      setData((prev) => ({ ...prev, [key]: { value, error: "" } })),
  });

  return (
    <>
      {/* <WrapperContainer width={getWidthContainer(width)}> */}
        <Styled.Wrapper id={id}>
          {/* <Styled.WrapperLetsTalk>
            <div>let's</div>
            <div>talk</div>
          </Styled.WrapperLetsTalk> */}
          <Styled.ImgContainer>
                <img src={letsTalkImg} alt="talk"/>
          </Styled.ImgContainer>
          <LetsTalkText width={width} />
          <WrapperContainer width={width > 758 ? "1320px" : "100%"}>
            <Styled.WrapperField>
              <Styled.WrapperTwoField>
                <InputComponent
                  {...getInputProps("firstName")}
                  label="FIRST NAME*"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                  placeholder="Enter first name"
                />
                <InputComponent
                  {...getInputProps("lastName")}
                  label="LAST NAME*"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                    placeholder="Enter last name"
                />
              </Styled.WrapperTwoField>
          
           
                   <InputComponent {...getInputProps("email")} label="EMAIL" placeholder="Enter email*"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                />
           
             
          
              <Styled.WrapperTwoField>
                <InputComponent
                  {...getInputProps("phone")}
                  label="PHONE NUMBER*"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                    placeholder="Enter phone number"
                />
                <InputComponent
                  {...getInputProps("company")}
                  label="COMPANY (OPTIONAL)"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                    placeholder="Enter company"
                />
              </Styled.WrapperTwoField>
              <Styled.WrapperTwoField>
                <InputComponent
                  {...getInputProps("budget")}
                  label="EXPECTED BUDGET (OPTIONAL)"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                    placeholder="Enter expected budget"
                />
                <InputComponent
                  {...getInputProps("hearAbout")}
                  label="HOW DID YOU HEAR ABOUT US? (OPTIONAL)"
                  // width={width > 758 ? "calc(50% - 10px)" : "100%"}
                
                  placeholder="Enter here"
                />
              </Styled.WrapperTwoField>
              <Styled.TextareaContainer >
                <Styled.Label htmlFor="message">Message*</Styled.Label>
                <textarea name="message" placeholder="Enter Message here" id="message" className="text" />
            </Styled.TextareaContainer>
            <Styled.ButtonWrapper>
               <ButtonComponent size="l" block>
                SEND
              </ButtonComponent>
            </Styled.ButtonWrapper>
             
            </Styled.WrapperField>
          </WrapperContainer>
        </Styled.Wrapper>
      {/* </WrapperContainer> */}
      {/* {!disableFooter && <FooterComponent onClickBTT={onClickBTT} />} */}
    </>
  );
};

export default LetsTalkComponent;
