import styled from "styled-components";

interface IIsOpen {
  isOpen: boolean;
}

interface IWrapper {
  height: string;
}

export const Wrapper = styled.div<IWrapper>`
  height: ${({ height }) => height};
  padding: 10px 20px;
  background-color: black;
  overflow: hidden;
max-width: 433px;
  transition: height 0.3s;
`;

export const WrapperTitle = styled.div<IIsOpen>`
  height: 32px;
  margin-bottom: ${({ isOpen }) => (isOpen ? "10px" : "unset")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: margin-bottom 0.3s;
`;

export const Title = styled.div<IIsOpen>`
  width: calc(100% - 26px);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${({ isOpen }) => (isOpen ? "normal" : "nowrap")};
`;

export const Arrow = styled.div<IIsOpen>`
  width: 16px;
  transform: ${({ isOpen }) => `rotateZ(${isOpen ? 180 : 0}deg)`};

  transition: transform 0.3s;
  & path {
    fill: ${({ isOpen }) => (isOpen ? "#ED0C32" : "white")};
    transition: fill 0.3s;
  }
`;

export const Text = styled.div<IIsOpen>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s;
  
`;
