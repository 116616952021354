import { Route, Routes } from "react-router-dom";
import AboutPage from "../pages/about";
import InsightsPage from "../pages/insights";
import InsightPostPage from "../pages/insight_post";
import LabPage from "../pages/lab";
import MainPage from "../pages/main";
import OurWorkPage from "../pages/ourWork";
import ServicePage from "../pages/services";
import ServicesInfoPage from "../pages/servicesInfo";
import SoftwarePage from "../pages/software";
import WorkPage from "../pages/work";
import PrivacyPolicy from "../pages/privacyPolicy";

const RoutesApp = () => {
  return (
    <Routes>
      <Route path="" element={<MainPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="lab" element={<LabPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="services">
        <Route path="" element={<ServicePage />} />
        <Route path="info" element={<ServicesInfoPage />} />
      </Route>

      <Route path="works">
        <Route path="" element={<OurWorkPage />} />
        <Route path=":id" element={<WorkPage />} />
      </Route>

      <Route path="software" element={<SoftwarePage />} />

      <Route path="insights">
        <Route path="" element={<InsightsPage />} />
        <Route path=":id" element={<InsightPostPage />} />
      </Route>
    </Routes>
  );
};

export default RoutesApp;
