import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  title: ReactNode;
  text: ReactNode;
}

const WTBBOItem: FC<IProps> = ({ title, text }) => {
  return (
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};


export default WTBBOItem;