import * as Styled from "./style";
import logo from "../../assets/img/header/app.orbit.svg";
import burger from "../../assets/img/header/burger.png";
import HeaderNav from "./nav";
import HeaderLinks from "./links";
import WrapperContainer from "../../wrappers/container";
import { useNavigate } from "react-router-dom";
import {  useState } from "react";
import BurgerMenu from "./burgerMenu";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [burgerStatus, setBurgerStatus] = useState(0);

  // const [firstColumn, secondColumn] = useMemo(() => {
  //   if (width > 992) return [<HeaderLinks />, <HeaderNav />];
  //   return [
  //     <div />,
  //     <Styled.BurgerIcon onClick={() => setBurgerStatus(1)}>
  //       <img src={burger} alt="burger" />
  //     </Styled.BurgerIcon>,
  //   ];
  // }, [width]);

  return (
    <Styled.WrapperHeight>
      <Styled.WrapperStiky>
        <WrapperContainer height="100%">
          <Styled.WrapperInner>
              <Styled.WrapperLogo onClick={() => navigate("/")}>
              <img src={logo} alt="logo"/>
            </Styled.WrapperLogo>
          <Styled.Wrapper>
              <HeaderLinks />
              <HeaderNav />
            </Styled.Wrapper>
             <Styled.BurgerIcon onClick={() => setBurgerStatus(1)}>
                <img src={burger} alt="burger" />
       </Styled.BurgerIcon>
              
          </Styled.WrapperInner>
        
          
          
      
        </WrapperContainer>
      </Styled.WrapperStiky>
      <BurgerMenu
        burgerStatus={burgerStatus}
        setBurgerStatus={setBurgerStatus}
      />
    </Styled.WrapperHeight>
  );
};

export default HeaderComponent;
