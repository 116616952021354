import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const OurWorkFB = () => {
  return (
    <Styled.WrapperBG>
      <WrapperContainer width="874px">
        <Styled.Wrapper>
          <Styled.Title>You love apps. We make them.</Styled.Title>
          <Styled.Text>
            We've partnered with big brands and enterprise organizations to
            design and develop innovative solutions to unique problems. Take a
            look at our past collaborations to learn more about what we're
            capable of creating.
          </Styled.Text>
        </Styled.Wrapper>
      </WrapperContainer>
    </Styled.WrapperBG>
  );
};

export default OurWorkFB;
