import { useContext, useId } from "react";
import { WorkContext } from "../..";
import WTBBOItem from "./item";
import * as Styled from "./style";

const WTBBusinessObjective = () => {
  const idComponent = useId();
  const { business_objectives } = useContext(WorkContext);
  return (
    <Styled.Wrapper>
      <Styled.Title>Business Objectives:</Styled.Title>
      {business_objectives.map((item, index) => (
        <WTBBOItem key={idComponent + "." + index} {...item} />
      ))}
    </Styled.Wrapper>
  );
};

export default WTBBusinessObjective;
