import { createContext, FC, ReactNode, useState } from "react";
import { IDataGlobalContext, IGlobalContext } from "./types";

const initData: IDataGlobalContext = {
  dataModal: null,
};

const initContext: IGlobalContext = {
  data: initData,
  setData: () => {},
};

export const GlobalContext = createContext(initContext);

interface IProps {
  children: ReactNode;
}

const WrapperGlobalContext: FC<IProps> = ({ children }) => {
  const [data, setData] = useState(initData);

  return (
    <GlobalContext.Provider value={{ data, setData }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default WrapperGlobalContext;
