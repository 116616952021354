import { FC, useMemo, useRef } from "react";
import * as Styled from "./style";
import { IInfo } from "../../../../../../type";

interface IProps {
  info: IInfo;
  isOpen: boolean;
  setIsOpen: (id: string) => void;
  idPicker: string;
}

const LSBCIInfoItem: FC<IProps> = ({ isOpen, info, setIsOpen, idPicker }) => {
  const refText = useRef<HTMLDivElement>(null);

  const heightText = useMemo(
    () => refText.current?.offsetHeight || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refText.current, isOpen]
  );

  return (
    <Styled.Wrapper height={isOpen ? ` ${heightText + 65}px` : "52px"}>
      <Styled.WrapperTitle
        isOpen={isOpen}
        onClick={() => setIsOpen(isOpen ? "" : idPicker)}
      >
        <Styled.Title isOpen={isOpen}>{info.title}</Styled.Title>
        <Styled.Arrow isOpen={isOpen}>
          <svg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.724 0.390625L7.99996 6.11463L2.27596 0.390625L0.390625 2.27596L7.99996 9.88529L15.6093 2.27596L13.724 0.390625Z" />
          </svg>
        </Styled.Arrow>
      </Styled.WrapperTitle>
      <Styled.Text isOpen={isOpen} ref={refText}>
        {info.text}
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default LSBCIInfoItem;
