import { useId } from "react";
import { dataServices } from "../../../commonData/services/data";
import WrapperContainer from "../../../wrappers/container";
import AFBServices from "./services";
import * as Styled from "./style";

const AboutFourthBlock = () => {
  const idComponent = useId();
  return (
    <WrapperContainer width="800px">
      <Styled.Title>Our services</Styled.Title>
      <Styled.WrapperService>
        {dataServices.map(({ title, smallDescription, template }, index) => (
          <AFBServices
            key={idComponent + "." + index}
            title={
              <>
               
                <span>{title}</span>
              </>
            }
            text={smallDescription}
            template={template}
          />
        ))}
      </Styled.WrapperService>
    </WrapperContainer>
  );
};

export default AboutFourthBlock;
