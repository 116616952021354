import * as Styled from "./style";
import bgL from "../../../assets/img/headerBG/banner-bg-l.png";
 import bgM from "../../../assets/img/headerBG/banner-bg-m.png"
  import bgS from "../../../assets/img/headerBG/banner-bg-s.png"


const ServicesFirstBlock = () => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperImg >
        <img src={bgL} alt="bg" className="imgL" />
        <img src={bgM} alt="bg" className="imgM"/>
        <img src={bgS} alt="bg" className="imgS"/>
      </Styled.WrapperImg>
    </Styled.Wrapper>
  );
};

export default ServicesFirstBlock;
