
import TechnologiesComponent from "../../../components/technologies";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const ServicesFourthBlock = () => {
  return (
    <Styled.WrapperBG>
      <WrapperContainer width="1200px">
        <Styled.Title>
          We work with leading solutions in the app ecosystem
        </Styled.Title>
        <TechnologiesComponent  />
        
      </WrapperContainer>
    </Styled.WrapperBG>
  );
};

export default ServicesFourthBlock;
