const SIHFirstBlock = () => {
  return (
    <div>
      <div>
        Hybrid mobile apps appear on a mobile device, like a mobile app, but are
        in fact accessed by the internet, through a browser.
      </div>
      <br />
      <div>Hybrid apps are for clients seeking;</div>
      <br />
      <ul>
        <li>Faster development time, half that of native apps.</li><br/>
        <li>More affordable solutions.</li><br/>
        <li>
          Faster deployment on the app store if the native code is untouched.
        </li>
      </ul>
      <br />
      <div>
        Hybrid apps will not be able to mimic the experience of a native app
        perfectly, UX features such as gestures need extensive additional work
        to get the hybrid app on par with native, too much customisation can put
        in on price parity with native development. The speed of a Hybrid app is
        dependent on the user’s internet speed.
      </div>
    </div>
  );
};


export default SIHFirstBlock;
