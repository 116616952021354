const BusinessFirstBlock = () => {
  return (
    <div>
      <div>
        Business application opportunities are identified in the digital transformation roadmap which may include;
      </div>
      <br />
      <ul>
        <li>Modernisation of existing applications.</li><br/>
        <li>Opportunities for digital products and new business models and</li><br/>
        <li>Migration of legacy systems to the cloud.</li>
          </ul>
          <br />
        <div>Digital product design requires the development of a digital strategy to deliver on the business objectives and desired use cases, by;</div>
        <br />
        <ul>
        <li>Defining and prioritising initiatives.</li><br/>
        <li>Recognising gaps from actual to desired state.</li><br/>
              <li>Mapping required capabilities, processes and technology.</li><br />
              <li>Service and customer experience design and</li><br/>
              <li>Agile delivery.</li>
          </ul>
          <div>Legacy systems and assets are typically modernised and migrated as a business application to the cloud. This moderrnisation involves a decision to refactor, rebuild or retire the legacy system.

</div>
    </div>
  );
};

export default BusinessFirstBlock;