import styled from "styled-components";

export const WrapperImg = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

interface IImg {
  img: string;
}

export const Img = styled.div<IImg>`
  width: 32px;
  height: 32px;
  overflow: hidden;

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
`;
