export const dataInfo = [
  {
    indexItem: "01.",
    title: "Mobile App Development",
    text:
      "Native, hybrid or low code apps, we apply best practice code and design to  your mobile app to create an outstanding user experience .",
  },
  {
    indexItem: "02.",

    title: "Product Development",
    text:
      "We solve user problems with product and service design of high levels of utility including mobile apps, digital services and software as a service solution.",
  },
  {
    indexItem: "03.",

    title: "Customer Experience & Service Design",
    text:
      "Service and customer experience designed around an end to end journey. We design seamless, user-centric designs that showcases your brand across multuple touchpoints.",
  },
  {
    indexItem: "04.",

    title: "Webapps",
    text:
      "Our webapps are designed to offer engaging interactivity and compelling functionality accessible from your browser on the desktop and mobile.",
  },
];
