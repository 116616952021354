import { FC } from "react";
import { IInfo } from "../../../type";
import ButtonComponent from "../../button";

import * as Styled from "./style";

interface IProps extends IInfo {
  imgFolder: string;
  idElement: number;
  openModal: (id: number) => void;
  img?: string,
  bgImg?:string
}

const TCSliderItem: FC<IProps> = ({
  title,
  text,
  openModal,
  idElement,
  img,
  bgImg

}) => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperLeftSide>
        {/* <Styled.Phone phone={`${img}`} /> */}
        <Styled.PhoneContainer>
          <img src={img} alt="phone" />
        </Styled.PhoneContainer>
        <Styled.WrapperText>
          <Styled.WrapperName>
            <Styled.LineUnderName />
            <Styled.Name>{title}</Styled.Name>
          </Styled.WrapperName>
          <Styled.Text>{text}</Styled.Text>
          <Styled.WrapperButton>
            <ButtonComponent  size="l" onClick={() => openModal(idElement)} mode="white">
              Learn more
            </ButtonComponent>
          </Styled.WrapperButton>
        </Styled.WrapperText>
      </Styled.WrapperLeftSide>
      <Styled.BG bg={`${bgImg}`} />
    </Styled.Wrapper>
  );
};

export default TCSliderItem;
