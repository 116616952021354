import { FC } from "react";
import { ISSBInfo } from "../../types";
import * as Styled from "./style";

const SSBRItem: FC<ISSBInfo> = ({ title, text }) => {
  return (
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default SSBRItem;
