import { FC, ReactNode } from "react";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

interface IProps {
  children: ReactNode;
}

const SIFirstBlock: FC<IProps> = ({ children }) => {
  return (
    <Styled.WrapperBG>
      <WrapperContainer width="1320px">
        <Styled.Wrapper>
          <Styled.TypePage>services</Styled.TypePage>
          <Styled.WrapperTitle>{children}</Styled.WrapperTitle>
        </Styled.Wrapper>
      </WrapperContainer>
    </Styled.WrapperBG>
  );
};

export default SIFirstBlock;
