import { useId } from "react";
import { useNavigate } from "react-router-dom";
import { dataHeader } from "../data";
import * as Styled from "./style";

const HeaderNav = () => {
  const idComponent = useId();
  const navigate = useNavigate();
  return (
    <Styled.Wrapper>
      {dataHeader.map(({ title, to }, index) => (
        <div key={idComponent + "." + index} onClick={() => navigate(to)}>
          {title}
        </div>
      ))}
    </Styled.Wrapper>
  );
};

export default HeaderNav;
