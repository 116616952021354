import { FC } from "react";
import * as Styled from "./style";

interface IProps {
  width: number;
}

const onClickText = (text = "HELLO@APPORBIT.COM.AU") => {
  navigator.clipboard.writeText(text);
};

const LetsTalkText: FC<IProps> = ({ width }) => {
  if (width < 992) {
    return (
      <Styled.Wrapper onClick={() => onClickText()}>
         <a className="red" href="mailto:hello@apporbit.co">HELLO@APPORBIT.CO</a>
        <div className="copy">(☝🏻CLICK TO COPY)</div>
      </Styled.Wrapper>
    );
  }
  return (
    <Styled.Wrapper onClick={() => onClickText()}>
      <p>FILL IN THE FORM BELOW, OR REACH OUT TO US DIRECTLY —{" "}
        <a className="red" href="mailto:hello@apporbit.co">HELLO@APPORBIT.CO</a>
      {/* <span className="red">HELLO@APPORBIT.COM.AU</span>{" "} */}
      <span className="copy">(CLICK TO COPY)</span></p>
    </Styled.Wrapper>
  );
};

export default LetsTalkText;
