const SIIOSFirstBlock = () => {
  return (
    <div>
      <div>
        IOS apps are recommended when the connectivity of data and interactivity
        between devices, creates a premium user experience. User experiences
        which are enhanced by the specific capabilities of a single device are
        ideally suited to IOS App design. Certain apps work better across
        ecosystem devices, such as;
      </div>
      <br />
      <ul>
        <li>Wearables for exercise and health tracking.</li><br/>
        <li>Macbooks and desktops for intensive and complex applications.</li><br/>
        <li>Ipads for hand writing, drawing and content browsing and</li><br/>
        <li>HomePods and AirPods for voice interfaces.</li>
      </ul>
    </div>
  );
};

export default SIIOSFirstBlock;
