import { FC, ReactNode } from "react";
import * as Styled from "./style";

interface IProps {
  title?: ReactNode;
  text: ReactNode;
}

const SFBIItem: FC<IProps> = ({ title, text }) => {
  return (
    <Styled.Wrapper>
      {title && <Styled.Title>{title}</Styled.Title>}
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default SFBIItem;
