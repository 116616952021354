const SIDFirstBlock = () => {
  return (
    <div>
      <div>
        Many apps and websites short cut the UIUX process, leaping straight to
        UI design without the rigor of good UIUX research and planning, and they
        do so at a great price. In order, for an app, website or multichannel
        customer journey to be usable, it needs to be designed using UIUX design
        tools and principles. To keep current, UIUX designs must embrace the new
        technologies such as biometric authentications, in-app gestures, voice
        interfaces, AI predictive responses, data visualisation and a move into
        3D worlds.
      </div>
      <br />
      <div>
        For simple and low-cost projects there are lean UX methodologies, design
        patterns and no code solutions that will enable rapid design. For
        complex projects working across devices and technologies, with
        significant sunk costs, upfront research, planning, prototype design and
        testing is critical.
      </div>
      <br />
      <div>
        Every project will apply UIUX tools in a unique way specific to the
        project, the tools include:
      </div>
      <br />
      <ul>
        <li>
          User experience research including: emotional design, Human Centred
          Design and ethnography
        </li><br/>
        <li>
          User experience artifacts including; Personas, affinity maps and
          empathy maps
        </li><br/>
        <li>Information architecture</li><br/>
        <li>Content design</li><br/>
        <li>
          Onboarding design, a gradual engagement of users in the product and
          its features
        </li><br/>
        <li>
          Graphic UI for visual, Voice UI for voice interactions and Gesture UI
          for VR movements
        </li><br/>
        <li>Design pattern and Apple UI design kit utilisation</li><br/>
        <li>Behavioural and interaction design</li><br/>
        <li>Low fidelity prototype design and testing</li><br/>
        <li>High fidelity prototype design and testing</li>
      </ul>
    </div>
  );
};


export default SIDFirstBlock;