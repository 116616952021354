import { FC, ReactNode } from "react";
// import WrapperSI from "../../wrapper";
import * as Styled from "./style";

export interface IPropsSITBItem {
  title: string;
  data: ReactNode;
}

const SITBItem: FC<IPropsSITBItem> = ({ title, data }) => {
  return (
    // <WrapperSI>
    <Styled.Wrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Data>{data}</Styled.Data>
  
      </Styled.Wrapper>
    // </WrapperSI>
  );
};

export default SITBItem;
