import styled from "styled-components";

interface IWrapperImg {
  photo: string;
}

export const WrapperImg = styled.div<IWrapperImg>`
  width: 285px;
  height: 460px;
  overflow: hidden;
  position: relative;

  background-image: ${({ photo }) => `url(${photo})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & > div {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    & > div {
      opacity: 1;
    }
  }

  @media (max-width: 1200px) {
    width: 285px;
    height: 279px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 231px;
    background-position: 50% 30%;
  }
`;

export const About = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #00000096;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0;
`;

export const Position = styled(Name)`
  font-weight: 400;
`;
