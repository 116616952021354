import * as Styled from "./style"

const HeaderLinks = () => {
  return (
    <Styled.Wrapper>
      
      <a className="red" href="mailto:hello@apporbit.co">HELLO@APPORBIT.CO</a>
      <span>/</span> 
      <a href="https://www.linkedin.com/company/app-360-agency/" className="red">LINKED IN</a>
    </Styled.Wrapper>
  );
};

export default HeaderLinks;
