import { useId } from "react";
import { dataSoftwareTechnologies } from "../../../commonData/softwareTechnologies/data";
import TwoColumnComponent from "../../../components/twoColumn";
import WrapperContainer from "../../../wrappers/container";
import SNBHeader from "./header";
import SNBItem from "./item";

const SoftwareNinthBlock = () => {
  const idComponent = useId();
  return (
    <WrapperContainer width="1100px">
      <SNBHeader />

      <TwoColumnComponent
        data={dataSoftwareTechnologies.map((item, index) => (
          <SNBItem key={idComponent + "." + index} {...item} />
        ))}
      />
    </WrapperContainer>
  );
};

export default SoftwareNinthBlock;
