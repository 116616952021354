import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  padding: 24px 0px;
  width: 100%;
  max-width: 1310px;
  margin-left: auto;
  margin-right: auto;
 
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  /* text-transform: uppercase;
  bottom: -160px; */
   @media(max-width:1400px){
 max-width: 1090px;
   }
      @media(max-width:1200px){
 max-width: 910px;
   }
      @media(max-width:992px){
 max-width: 670px;
   }
        
  @media(max-width:767px){
    flex-direction: column;
      padding: 0px 40px;
      width: 380px;
      margin-left: auto;
      margin-right: auto;
  }

`;

export const WrapperButtonUp = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media(max-width:991px){
left: 64%;
  }
    @media(max-width:767px){
      display: none;
    }
`;


export const WrapperButtonUpResponsive = styled.div`
  display: none;
  @media(max-width:767px){
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  gap: 0 70px;
  p{
    cursor: pointer;
  }
    @media(max-width:767px){
     gap: 0 10px;
     margin-top:-15px;
     &.item{
      margin-top: 20px;
     }

    }

`;

export const WrapperArrow = styled.div`
  transform: rotateZ(90deg);
`;
