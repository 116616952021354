import styled from "styled-components";
import { TPositionWP } from ".";

interface IContainer {
  width: string;
  minWidth: string;
  maxWidth: string;
  height: string;
  position: TPositionWP;
  padding: string;
}

const dataMargin: { [key in TPositionWP]: string } = {
  start: "0 auto 0 0",
  center: "0 auto",
  end: "0 0 0 auto",
};

export const Container = styled.div<IContainer>`

  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  margin: ${({ position }) => dataMargin[position]};
  @media (max-width: ${({ width, minWidth }) =>
      minWidth !== "unset" ? minWidth : width}) {
    width: 100%;
    min-width: unset;
    padding: ${({ padding }) => padding};
    margin: unset;
  }
`;
