import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { WorkContext } from "..";
import WorkInfoWrapper from "../wrappers/infoWrapper";
import * as Styled from "./style";
import phone_1hrm from '../../../assets/img/works/hrm/phone_14.png';
import phone_2hrm from '../../../assets/img/works/hrm/Phone_1.png';
import phone_3hrm from '../../../assets/img/works/hrm/phone_7.png';
import phone_4 from '../../../assets/img/works/peerpay/phone_4.png';
import phone_3 from '../../../assets/img/works/peerpay/phone_3.png';
import phone_2 from '../../../assets/img/works/peerpay/phone_2.png';


const WorkEigthBlock = () => {
  const { secondInfo } = useContext(WorkContext);
  const { pathname } = useLocation();

  return (
    <WorkInfoWrapper {...secondInfo}>
      <Styled.Wrapper>
        <Styled.WrapperBlock>
          {pathname==="/works/hrm" ? <Styled.FirstPhone img={phone_1hrm} /> : <Styled.FirstPhone img={phone_4} />}
          
        </Styled.WrapperBlock>
        <Styled.WrapperBlock>
          {pathname === "/works/hrm" ? <Styled.SecondPhone img={phone_2hrm} /> : <Styled.SecondPhone img={phone_2} />}
            {pathname==="/works/hrm" ? <Styled.ThirdPhone img={phone_3hrm} /> :  <Styled.ThirdPhone img={phone_3} />}
        </Styled.WrapperBlock>
      </Styled.Wrapper>
    </WorkInfoWrapper>
  );
};

export default WorkEigthBlock;
