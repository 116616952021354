import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../components/button";
import { IInfo } from "../../../../type";
import * as Styled from "./style"


interface IProps extends IInfo {
  indexItem:string;
}

const MTBInfoBlock: FC<IProps> = ({indexItem,text,title}) => {
  const navigate = useNavigate()

  return (
    <Styled.Wrapper>
      <Styled.Index>{indexItem}</Styled.Index>
      <Styled.Title>{title}</Styled.Title>
      <Styled.SubTitle>{text}</Styled.SubTitle>
      <Styled.ButtonWrapper>
          <ButtonComponent  mode="white" size="l" onClick={() => navigate("/services")}>
        Learn more
      </ButtonComponent>
      </Styled.ButtonWrapper>
    
    </Styled.Wrapper>
  );
};


export default MTBInfoBlock;
