import ProblemSolutionComponent from "../../../components/problemSolution";
import { IInfo } from "../../../type";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const data: [IInfo, IInfo] = [
  {
    title: "Flexibility",
    text: "Fast paced disruption, regulatory changes, organisational changes can all impact on software requirements and necessitate immediate change. Organisations operating in dynamic marketplaces are better able to respond to that change with customised software.",
  },
  {
    title: "Workflow Automation",
    text: "Workflow automation can cut out repetitive work tasks within your organisation, freeing up your workforce to focus on value adding tasks. The beauty of workflow automation is its ability to integrate a business process, operating across multiple systems, into one platform.",
  },
];

const SoftwareFifthBlock = () => {
  return (
    <WrapperContainer width="1300px">
      <Styled.Title>
        <div>Increase your business </div>
        <div>agility and efficiency</div>
      </Styled.Title>

      <ProblemSolutionComponent data={data} />
    </WrapperContainer>
  );
};

export default SoftwareFifthBlock;
