import LSBContent from "./content";
import LSBNav from "./nav";
import * as Styled from "./style";

const LabSecondBlock = () => {
  return (
    <Styled.Wrapper>
      <LSBNav />
      <LSBContent />
    </Styled.Wrapper>
  );
};

export default LabSecondBlock;
