import styled from "styled-components";

export const Wrapper = styled.div`
  background-image: url("/approach_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 834px) {
    background: unset;
  }
`;

export const WrapprtInfo = styled.div`
  padding: 40px 0;
  width: 490px;

  @media (max-width: 834px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
`;
