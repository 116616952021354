import styled from "styled-components";

export const WrapperSize = styled.div`
  width: 60%;
  height: 100%;
  padding: 33px 240px 33px 40px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
  }
`;

export const Wrapper = styled.div`
  width: 405px;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const WrapperLogo = styled.div`
  height: 40px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img{
    width: 136px;
    height: 22px;
  }
  @media(max-width:991px){
    margin-bottom: 30px;
  }
`;

export const NavText = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;

  cursor: pointer;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
export const ButtonWrapper = styled.div`
max-width: 182px;
@media(max-width:991px){
  margin-bottom: 24px;
}
`
export const EmailContainer = styled.div`

height: 56px;
align-items: center;
justify-content: center;
display: flex;
border-top: 1px solid #C4C4C4;
border-bottom: 1px solid #C4C4C4;
.red{
  text-decoration: none;
  cursor: pointer;
}
`