import styled from "styled-components";
import headerImg from "../../../assets/img/lab/header.png"

export const Wrapper = styled.div`
  height: 390px;
  position: relative;
  display: flex;
  align-items: center;
  background: url(${headerImg});  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1880px;
  width: 100%;
`;

export const WrapperImg = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const WrapperText = styled.div`
  width: 54%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: 1200px) {
    width: 100%;
    height: fit-content;

    padding: 35px 85px;

    background: rgba(0, 0, 0, 0.38);
    backdrop-filter: blur(22px);
  }

  @media (max-width: 768px) {
    padding: 35px 20px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 8px;

  @media (max-width: 1200px) {
    width: 50%;
    font-size: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  max-width: 600px;

  @media (max-width: 1400px) {
    width: 50%;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
