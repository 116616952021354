import * as Styled from "./style";

const SEBHeader = () => {
  return (
    <div>
      <Styled.Title>App Orbit Custom Software Team</Styled.Title>
      <Styled.Text>
       A multidisciplinary team of subject matter experts.
      </Styled.Text>
    </div>
  );
};

export default SEBHeader;
