import styled from "styled-components";

export const Wrapper = styled.div`
  height: 120%;
  position: relative;
  width: 100%;

   @media(max-width:860px){
    height: fit-content;
   }
`;

export const WrapperFooter = styled.div`
  position: absolute;
  top: 113%;
  left: 50%;
  transform: translate(-50%, -100%);
  @media(max-width:1600px){
    max-width: 1440px;
    width: 100%;
    
  }
  @media(max-width:860px){
    display: flex;
    flex-direction: column;
  }
`;
