import { IDataLab,  } from "./types";
import research from "../../assets/img/lab/research/video.mp4";
import researchImg from "../../assets/img/lab/research/bg.png";
import strategy from "../../assets/img/lab/strategy/video.mp4";
import strategyImg from "../../assets/img/lab/strategy/bg.png";
import design from "../../assets/img/lab/design/video.mp4";
import designImg from "../../assets/img/lab/design/bg.png";
import development from "../../assets/img/lab/development//video.mp4";
import developmentImg from "../../assets/img/lab/development/bg.png";
import launch from "../../assets/img/lab/launch//video.mp4";
import launchImg from "../../assets/img/lab/launch//bg.png";

export const dataLab: IDataLab[] = [
  {
    idItem: "Research",
    assetsFolder: "/assets/lab/research",
    title: "Research",
    text: "Through greater empathy with users and customers, greater value can be created. App Orbit develop and apply contextual research to design more human products that solve genuine problems.",
    info: [
      {
        title: "Competitor Analysis",
        text: "The app landscape is a crowded one that requires a strong competitive point of difference to break away from the pack. App Orbit's competitve research helps our clients discover market gaps and disruptive value that can position them for success.",
      },
      {
        title: "Ethnographic and behavioural research",
        text: "Through ethnographic research techniques, App Orbit studies users in context through observation, face to face interviewing and diaries. Our behavioural research looks at the trade off between motivations and rewards driving user behaviours.",
      },
      {
        title: "Stakeholder Interviews",
        text: "Stakeholder interviews and co creation processes ensures the line of interaction within an app touchpoint is supported by back of stage service value chains and front of stage customer or service interactions. It begins the change management positioning exercise ensuring the application is embraced and adopted when deployed.",
      },
    ],
    id: 1,
    src: research,
    poster: researchImg
  },

  {
    idItem: "Strategy",
    assetsFolder: "/assets/lab/strategy",
    title: "Strategy",
    text: "Strategy requires a focus on the things that matter, the compelling problems to be solved. It is about developing a plan to take ideas into action. We can adopt a client’s existing strategy or co-create a product strategy with our clients in workshops and innovative activities.",
    info: [
      {
        title: "Growth",
        text: "App Orbit work with our clients to develop a growth engine to move from minimal viable product to product market fit and scale. We consider growth opportunities within the product ecosystem, execute rapid experimentation and test for product market fit as we scale.",
      },
      {
        title: "Roadmap",
        text: "We develop a shared vision for the product development over time. The roadmap informs priorities and a plan of action that aligns team members and stakeholders to achieve milestones and goals.",
      },
      {
        title: "Market",
        text: "We investiage the business case through a deep dive into understanding the market, the key players, existing competitive offerings and the sweet spot of opportunity.",
      },
      {
        title: "Validation",
        text: "Using business model tests or small bets of protofeatures, App Orbit can test the likelihood a given set of features will meet the unaddressed need of the target audience.",
      },
    ],
    id: 2,
    src: strategy,
    poster: strategyImg
  },
  {
    idItem: "Design",

    assetsFolder: "/assets/lab/design",
    title: "Design",
    text: "App Orbit drives a discovery and delivery process that deep dives into your users insights and stages rapid tests of low fidelity prototypes.",
    info: [
      {
        title: "Brand Design",
        text: "We use research and stakeholder insights to understand the problem including its impact and effect. We apply these insights to define the design challenge.",
      },
      {
        title: "UX/UI Design",
        text: "Our UIUX research includes persona development, empathy maps, storyboards, journey mapping, interaction design, information architecture and application of the latest design standards. Our UI team develop a consistent visual language and a clean minimalistic, aesthetic app design.",
      },
      {
        title: "Design Prototype",
        text: "App Orbit develop blueprints and wireframes around user scenrios and interation. We test low fidelity and high fidelity prototypes to iterate rapid prototypes to a sucessful design. Usability testing is used to refine designs to reach MVP.",
      },
    ],
    id: 3,
    src: design,
    poster: designImg
  },
  {
    idItem: "Development",

    assetsFolder: "/assets/lab/development",
    title: "Development",
    text: "Our development team will work with you to bring your product to life using a lean agile project methodology. We have a multidisplinary team composed of mobile app, and front end and back end developers.",
    info: [
      {
        title: "Backend & API Development",
        text: "App Orbit will make recommendations for a technology architecture including the optimisation of backend technologies and configuration of databases and servers such as Django, Firebase aand Stack Rest API. The backend will also allow the integration of third party API's, allowing their data to work within the application.",
      },
      {
        title: "Mobile App Development & Web Platforms",
        text: "App Orbit develops the full range of mobile applications, native, hybrid, web and no-code, as such we are technology agnostic and choose a mobile application technology that best suits your needs.",
      },
      {
        title: "QA Testing & Analytics",
        text: "App Orbit's Quality Assurance systematically checks the application under development is meeting specified requirements. Our Quality Assurance process safeguards against flaws and weaknesses which will cumulate into technical debt over time.",
      },
    ],
     id: 3,
    src: development,
    poster: developmentImg
  },
  {
    idItem: "Launch",

    assetsFolder: "/assets/lab/launch",
    title: "Launch & Growth",
    text: "We craft research-backed product strategies that help our process move through the key execution stages in an efficient manner, without the need to backtrack.",
    info: [
      {
        title: "App Store Setup & Approvals",
        text: "App Orbit work on app store approval for Apple and Google app stores. We develop and test creative sets to engage users within the app store. We work with proven App Store Optimisation strategies to ensure the app has a healthy organic download rate.",
      },
      {
        title: "Ongoing Measurement & Prioritization",
        text: "Using app analytics such as FireBase and Google Analytics we are able to track user behaviour which will inform constant improvement in app design, user engagement and user acquisition and onboarding.",
      },
      {
        title: "Agile Planning & Release Management",
        text: "Our agile and iterative approach means we release features regularly to deliver on the product roadmap. Our ongoing testing ensures we can iterate or pivot to meet new market opportunities.",
      },
    ],
    id: 5,
    src: launch,
    poster: launchImg
  },
];

