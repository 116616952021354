import { FC } from "react";
import { IInfo } from "../../../type";
import ButtonComponent from "../../button";

import * as Styled from "./style";
import { useLocation } from "react-router-dom";

interface IProps extends IInfo {
  imgFolder: string;
  idElement: number;
  openModal: (id: number) => void;
  modalBg?: string;
  modalImg?: string;
  bgImg:string;
  img:string
}

const TCItem: FC<IProps> = ({
  title,
  imgFolder,
  text,
  openModal,
  idElement,
  modalBg,
  modalImg,
  bgImg,
  img
}) => {
  const {pathname}=useLocation();
  console.log('pathname :>> ', pathname);
  return (
    <>
      {pathname ==="/services" ?(
        <Styled.WrapperContainer>
        <Styled.WrapperServices>
        <Styled.WrapperInfo>
          <Styled.WrapperTitle>
            <Styled.LineUnderName />
            <Styled.Title>{title}</Styled.Title>
          </Styled.WrapperTitle>
          <Styled.TextAdditional>{text}</Styled.TextAdditional>
          <Styled.ButtonWrapperAdditional>
              <Styled.BtnAdditional  onClick={() => openModal(idElement)} >
            Learn More
          </Styled.BtnAdditional>
          </Styled.ButtonWrapperAdditional>
        
        </Styled.WrapperInfo>
        <Styled.WrapperImgAdditional imgFolder={imgFolder} img={`${img}`} bgImg={`${bgImg}`} bgMob={`${modalBg}`} phone={`${modalImg}`}>
          <div />
          <div />
        </Styled.WrapperImgAdditional>
      </Styled.WrapperServices>
      </Styled.WrapperContainer>
      ) : ( 
    <Styled.Wrapper>
      <Styled.WrapperInfo>
        <Styled.WrapperTitle>
          <Styled.LineUnderName />
          <Styled.Title>{title}</Styled.Title>
        </Styled.WrapperTitle>
        <Styled.Text>{text}</Styled.Text>
        <Styled.ButtonWrapper>
            <ButtonComponent mode="white" onClick={() => openModal(idElement)} size="l">
          Learn more
        </ButtonComponent>
        </Styled.ButtonWrapper>
      
      </Styled.WrapperInfo>
      <Styled.WrapperImg imgFolder={imgFolder} img={`${img}`} bgImg={`${bgImg}`} bgMob={`${modalBg}`} phone={`${modalImg}`}>
        <div />
        <div />
      </Styled.WrapperImg>
    </Styled.Wrapper>
      )
}
</>
  );
};

export default TCItem;
