import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import ServicesFirstBlock from "./firstBlock";
import ServicesFourthBlock from "./fourthBlock";
import ServicesSecondBlock from "./secondBlock";
import ServicesThirdBlock from "./thirdBlock";

const ServicePage = () => {
  return (
    <WrappperHeader>
      <ServicesFirstBlock />
      <ServicesSecondBlock />
      <ServicesThirdBlock />
      <ServicesFourthBlock />
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default ServicePage;
