import { FC } from "react";

interface IProps {
  fill?: string;
}

const CheckMark: FC<IProps> = ({ fill = "#2400FF" }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V2C14 1.46957 13.7893 0.960859 13.4142 0.585786C13.0391 0.210714 12.5304 0 12 0H2ZM6 10.414L3.293 7.707L4.707 6.293L6 7.586L9.793 3.793L11.207 5.207L6 10.414Z"
        fill={fill}
      />
    </svg>
  );
};


export default CheckMark;