import * as Styled from "./style";
import Linkedin from "../../../assets/img/icon/linkedin.png";
import Twitter from "../../../assets/img/icon/twitter.png";
import Facebook from "../../../assets/img/icon/facebook.png";

const dataIcon = [
  { src: Linkedin},
  {src: Twitter},
  {src: Facebook }
];

const IPThirdBlock = () => {
  return (
    <Styled.Wrapper>
      {dataIcon.map((icon, index) => (
        <Styled.Icon icon={icon.src} key={index}/>
      ))}
    </Styled.Wrapper>
  );
};

export default IPThirdBlock;
