import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import LabFirstBlock from "./firstBlock";
import LabSecondBlock from "./secondBlock";

const LabPage = () => {
  return (
    <WrappperHeader>
      <LabFirstBlock />
      <LabSecondBlock />
      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default LabPage;
