const SINTFirstBlock = () => {
  return (
    <div>
      <div>
      Native apps are part of our everyday including Whatsapp and Spotify. These apps use a device's features to its full potential. Native apps are used for clients seeking a high-performance app. Native apps are stored on the device, meaning load times are much faster. Native apps directly access the hardware of the device, such as camera and microphone.
      </div>
      <br />
      <div>Native apps are a higher cost and are chosen when;</div>
      <br />
      <ul>
        <li>Performance of the app is vital to user experience</li>
        <li>
          The app is designed to use device specific features such as geo
          location and camera.
        </li>
        <li>Budget allows</li>
      </ul>
      <br />
      <div>
        Cost comes not only from a longer time to build a native app, but also
        because separate apps are required for Android and IOS.
      </div>
    </div>
  );
};

export default SINTFirstBlock;
