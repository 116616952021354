import { useId } from "react";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import { data } from "./data";
import SSBItem from "./item";
import * as Styled from "./style";

const SoftwareSixthBlock = () => {
  const idComponent = useId();
  const { width } = useSize();

  return (
    <WrapperContainer width="1200px">
      <Styled.Title>
        <div>App Orbit customsied software is increasing productivity </div>
        <br />
        <div>by 2X up to 10X?</div>
      </Styled.Title>
      <Styled.WrapperItem>
        {data.map(({ children, ...props }, index) => (
          <SSBItem
            key={idComponent + "." + index}
            {...props}
            width={children && width <= 1200 ? "100%" : undefined}
            height={children && width <= 1200 ? "345px" : undefined}
          >
            {children}
          </SSBItem>
        ))}
      </Styled.WrapperItem>
    </WrapperContainer>
  );
};

export default SoftwareSixthBlock;
