import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  border-bottom: 1px solid #424242;

  cursor: pointer;
  & > div:first-child {
    width: 90%;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;

 

  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
`;

export const WrapperArrow = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;

  & > * {
    transform: rotateZ(180deg);
  }
`;
