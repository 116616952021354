const SIVRFirstBlock = () => {
  return (
    <div>
      <div>
        Virtual reality is an area of rapid growth, applications need to factor
        in user requirements which may include 3D videos, gaming, computer
        tethered headsets and untethered headsets. Establishing the headset
        requirements, based on use cases, is a key starting point for VR design.
      </div>
      <br />
      <div>VR apps have been used to solve many business problems such as;</div>
      <br />
      <ul>
        <li>Retail product trial before purchase</li><br/>
        <li>Test driving cars from a virtual showroom</li><br/>
        <li>Healthcare, PTSD treatment and surgical training</li><br/>
        <li>Real estate ‘dolls house’ and walkthroughs</li><br/>
        <li>Point of view experiences to remote events</li><br/>
        <li>Prototype testing in manufacturing</li>
      </ul>
    </div>
  );
};


export default SIVRFirstBlock;
