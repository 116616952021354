import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import OurWorkFB from "./firstBlock";
import OurWorkSB from "./secondBlock";

const OurWorkPage = () => {
  return (
    <WrappperHeader>
      <OurWorkFB />
      <OurWorkSB />

      <LetsTalkComponent />
    </WrappperHeader>
  );
};

export default OurWorkPage;
