import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 8px;
  padding: 10px;
  border-bottom: 1px solid #333333;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
