import { FC, useId } from "react";
import * as Styled from "./style";

interface IProps {
  label?: string;
  error?: string;
  value: string;
  onChange: (value: string) => void;
  width?: string;
  placeholder:string
}

const InputComponent: FC<IProps> = ({
  label,
  onChange,
  value,
  error,
  width = "100%",
  placeholder
}) => {
  const idComponent = useId();

  return (
    <Styled.Wrapper width={width}>
      {label && <Styled.Label htmlFor={idComponent}>{label}</Styled.Label>}
      {label === "EMAIL" ?
        <Styled.InputLong
        id={idComponent}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
      />
       : <Styled.Input
        id={idComponent}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
      />}
     
      {error && <Styled.Error>{error}</Styled.Error>}
    </Styled.Wrapper>
  );
};

export default InputComponent;
