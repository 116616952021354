import { useId } from "react";
import { dataServices } from "../../../commonData/services/data";
import TwoColumnComponent from "../../../components/twoColumn";
import WrapperContainer from "../../../wrappers/container";
import STBItem from "./item";

const ServicesThirdBlock = () => {
  const idComponent = useId();

  return (
    <WrapperContainer width="1306px">
      <TwoColumnComponent
        data={dataServices.map(({ title, description, template }, index) => (
          <STBItem
            key={idComponent + "." + index}
            title={title}
            text={description}
            template={template}
          />
        ))}
      />
    </WrapperContainer>
  );
};

export default ServicesThirdBlock;
