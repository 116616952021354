import { useId } from "react";
import { dataAboutPeople } from "../../../commonData/aboutPeople/data";
import WrapperContainer from "../../../wrappers/container";

import ASBInfo from "./info";
import * as Styled from "./style";

const AboutSecondBlock = () => {
  const idComponent = useId();
  return (
    <WrapperContainer width="1200px">
      <Styled.Title>The team</Styled.Title>
      <Styled.Text>
        Our headquarters are located in Sydney, Australia.
        We work with a talented team of subject matter experts spanning from
        Australia, to Europe and Asia serving clients across the globe..
      </Styled.Text>
      <Styled.WrapperPeople>
        {dataAboutPeople.map((item, index) => (
          <ASBInfo key={idComponent + "." + index} {...item} />
        ))}
      </Styled.WrapperPeople>
    </WrapperContainer>
  );
};

export default AboutSecondBlock;
