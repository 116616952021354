const SecondBlockSystems = () => {
  return (
    <div>
      <div>
     Data lakes accessed by cloud and on premise apps allow one, two way or on demand data access across applications, to utilise data in workflows enterprise wide. System and application integration allows a seamless hand off of data to a variety of systems and workflows to optimise automation and efficiency. This can enable;
      </div>
      <br />
     
      <ul>
        <li>Concurrent processing between applications</li><br/>
        <li>Robotic process automation of manual tasks</li><br/>
        <li>Data automation that feeds process orchestration.</li><br />
        <li>Frictionless and interoperable systems.</li><br />
        <li>Visibility and accessibility of data across all workflows.</li><br />
        <li>Scalability.</li><br />
        <li>A synchronisation of workflow and data across applications.</li>
      </ul>
    </div>
  );
};


export default SecondBlockSystems;