import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  min-height: 300px;
  background-color: #0c0c0c;

  @media (max-width: 834px) {
    flex-direction: column-reverse;
    height: unset;
  }
`;

export const WrapperInfo = styled.div`
  width: 50%;
  
  padding: 40px 30px;
  @media (max-width: 834px) {
    width: 100%;
  }
`;

interface IWrapperImg {
  bgColor: string;
}

export const WrapperImg = styled.div<IWrapperImg>`
  position: relative;
  width: 50%;
  background: ${({ bgColor }) => bgColor};
  overflow: hidden;

  @media (max-width: 834px) {
    width: 100%;
    height: 271px;
  }
`;

interface IPhone {
  imgFolder: string;
  img1?:string;
  img2?:string
}

export const FirstPhone = styled.div<IPhone>`
  position: absolute;
  width: 175px;
  height: 353px;
  top: 10%;
  left: 50%;

  transform: translateX(-10%);

  z-index: 2;

  /* background-image: ${({ imgFolder }) => `url(${imgFolder}/phone_1.png)`}; */
  background-image: ${({ img1 }) => `url(${img1})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const SecondPhone = styled(FirstPhone)`
  width: 165px;
  height: 335px;

  top: 20%;
  left: 50%;

  z-index: 1;

  transform: translateX(-90%);
background-image: ${({ img2 }) => `url(${img2})`};
  /* background-image: ${({ imgFolder }) => `url(${imgFolder}/phone_2.png)`}; */
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;

  margin: 10px 0 30px;
`;

export const WrapperTags = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  font-weight: 400;
  font-size: 15px;

  padding: 10px;
  border: 1px solid #ed0c32;
  /* background: #ed0c32; */
  border-radius: 4px;

  white-space: nowrap;
`;
