import { IPropsSSBItem } from "./item";
import * as Styled from "./style";

export const data: IPropsSSBItem[] = [
  {
    title: "Frictionless Interactions",
    text: "Fit for purpose designs are more intuitive for customers and employees and speed up interactions manifold.",
  },
  {
    title: "Agility At Scale",
    text: "Customised software gives your business control over feature upgrades when opportunities require pivots and improvements.",
  },
  {
    title: "Seamless Customer Interactions",
    text: "A seamless end to end customer journey built upon an integrated platform ensures an uninterrupted fluid experience.",
  },
  {
    title: "Innovative Product Focus ",
    text: "Designing products that better serve the customer needs. Using agile methods to make rapid improvement.",
  },
  { children: <Styled.ImgImpact /> },
  {
    title: "Customer Experience centricity",
    text: "Fit for purpose designs are more intuitive for customers and employees and speed up interactions manifold.",
  },
  {
    title: "Turn Organisations To Platforms",
    text: "Digitising and integrating business capabilities designed as products and delivered by one enterprise-wide common platform. ",
  },
  {
    title: "Turn Platforms To Ecosystems",
    text: "Adding API’s to bring other players into the ecosystem. Allowing players to add value to existing customers and their own customers through a partnership.",
  },
  {
    title: "Data Driven Interactions",
    text: "Using data and analytics to drive business decisions and provide a richer customer experience.",
  },
];
