import { FC, ReactNode } from "react";
import * as Styled from "./style";

export type TPositionWP = "start" | "center" | "end";

interface IProps {
  children: ReactNode;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  position?: TPositionWP;
  padding?: string;
}

const WrapperContainer: FC<IProps> = ({
  children,
  width = "100%",
  height = "initial",
  position = "center",
  minWidth = "unset",
  maxWidth = "unset",
  padding = "0 12px",
  
}) => {
  return (
    <Styled.Container
      position={position}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      height={height}
      padding={padding}
    >
      {children}
    </Styled.Container>
  );
};

export default WrapperContainer;
