import AFBInfo from "./info";
import * as Styled from "./style";
import earth from "../../../assets/img/earth_about.svg"

const AboutFirstBlock = () => {
  return (
    <Styled.Wrapper>
      <Styled.WrapperImg>
        <img src={earth} alt="globe"/>
      </Styled.WrapperImg>
      <AFBInfo />
    </Styled.Wrapper>
  );
};

export default AboutFirstBlock;
