import { FC, ReactNode, useContext, useEffect } from "react";
import { GlobalContext } from "../../globalContext";

interface IProps {
  children: ReactNode;
}

const WrapperModal: FC<IProps> = ({ children }) => {
  const { setData } = useContext(GlobalContext);

  useEffect(() => {
    setData((prev) => ({ ...prev, dataModal: children }));
    return () => setData((prev) => ({ ...prev, dataModal: null }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  return null;
};

export default WrapperModal;
