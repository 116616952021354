import { FC, useId } from "react";
import { IDataLab } from "../../../../../commonData/lab/types";
import LSBCIInfoItem from "./infoItem";
import * as Styled from "./style";

interface IProps extends IDataLab {
  indexItem: string;
  idOpenPicker: string;
  setIdOpenPicker: (id: string) => void;
}

const LSBCItem: FC<IProps> = ({
  info,
  assetsFolder,
  text,
  title,
  indexItem,
  idOpenPicker,
  setIdOpenPicker,
  idItem,
  src,
  poster
}) => {
  const idComponent = useId();
  return (
    <Styled.Wrapper id={idItem}>
      <Styled.WrapperInfo>
        <Styled.InfoIndexLab>{indexItem}</Styled.InfoIndexLab>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Text>{text}</Styled.Text>
        <Styled.WrapperInfoPicker>
          {info.map((item, index) => {
            const idPicker = idComponent + "LabPicker" + index;
            return (
              <LSBCIInfoItem
                key={idPicker}
                info={item}
                idPicker={idPicker}
                isOpen={idOpenPicker === idPicker}
                setIsOpen={setIdOpenPicker}
              />
            );
          })}
        </Styled.WrapperInfoPicker>
      </Styled.WrapperInfo>
      <Styled.WrapperVideo>
        <video
          poster={`${poster}`}
          preload="none"
          muted
          loop
          autoPlay
        >
          <source src={`${src}`} type="video/mp4" />
        </video>
      </Styled.WrapperVideo>
    </Styled.Wrapper>
  );
};

export default LSBCItem;
