import { useId, useMemo } from "react";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import { data } from "./data";
import SSBRow from "./row";
import * as Styled from "./style";

const SoftwareSecondBlock = () => {
  const idComponent = useId();
  const { width } = useSize();

  const dataInfo = useMemo(() => {
    if (width > 991) {
      const response = [];
      for (let i = 0; i < Math.ceil(data.length / 2); i++) {
        response.push([data[i * 2], data[i * 2 + 1]]);
      }
      return response;
    }
    return data.map((item) => [item]);
  }, [width]);

  return (
    <WrapperContainer
      width="1900px"
      minWidth="1400px"
      position="end"
      padding="unset"
    >
      <Styled.Wrapper>
        <WrapperContainer width="1320px" position="start" padding="unset">
          <Styled.Title>Australian Owned</Styled.Title>
          {dataInfo.map((item, index) => (
            <SSBRow key={idComponent + "." + index} data={item} />
          ))}
        </WrapperContainer>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default SoftwareSecondBlock;
