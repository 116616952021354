const SIWSFirstBlock = () => {
  return (
    <div>
      <div>
       High performing websites designed using the current UIUX trends are more likely to engage and build trust with customers.
      </div>
      <br />

      <ul>
        <li>A high performing website;</li><br/>
        <li>Positions a business as a market leader;</li><br/>
        <li>Meets more user intentions;</li><br/>
        <li>Converts desired user goals;</li><br/>
        <li>Ranks better with google;</li><br />
            <li>Has an experience that is seamless across devices.</li><br/>
      </ul>
      <br />
      <div>
        Cutting edge web design will utilise AI driven predictive and personalised content, to drive deeper engagement. PWA’s, progressive web apps and AMP’s, accelerated mobile pages, improve site speed. Interactive 3D animations, more animated elements which spin and bounce and bolder use of colours, will engage users visually like never before. Cloud based servers will avoid system overwhelm and data loss as sites become more personalised.
      </div>
    </div>
  );
};

export default SIWSFirstBlock;
