import { IDataServicesInfo } from "../../pages/servicesInfo/types";
import * as templates from "./templates";

export type TServicesInfoKey =
  | "nativeMobile"
  | "noCode"
  | "vr"
  | "hybrid"
  | "ios"
  | "design"
  | "website"
  | "business"
  | "systemIntegration"
  |"webapps";


const { nativeMobile, noCode, vr, hybrid, ios, design, website, business, systemIntegration, webapps } = templates;

export const dataServicesInfo: {
  [key in TServicesInfoKey]: IDataServicesInfo;
} = {
  nativeMobile,
  noCode,
  vr,
  hybrid,
  ios,
  design,
  website,
  business,
  systemIntegration,
  webapps
};
