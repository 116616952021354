import { useContext, useId } from "react";
// import { useLocation } from "react-router-dom";
import { WorkContext } from "..";
import WorkInfoWrapper from "../wrappers/infoWrapper";
import * as Styled from "./style";
import phone_3 from "../../../assets/img/works/peerpay/phone_3.png";
// import phone_4 from "../../../assets/img/works/hrm/phone_1-1.png";
// import phone_5 from "../../../assets/img/works/hrm/phone_7.png";
// import phone_5hrm from "../../../assets/img/works/hrm/phone_2-2.png";
// import phone_6hrm from "../../../assets/img/works/hrm/phone_8.png";
import phone_6 from "../../../assets/img/works/coach/phone_6.png";
import phone_1 from "../../../assets/img/works/coach/phone_2.png";
import phone_2 from "../../../assets/img/works/peerpay/phone_2.png";

// const dataImg = [phone_4, phone_5, phone_6, phone_3];
// const dataHrmImg = [phone_4, phone_5, phone_5hrm, phone_6hrm];
const dataImg = [phone_6, phone_1, phone_3, phone_2];


const WorkSeventhBlock = () => {
  const idComponent = useId();
  const { firstInfo } = useContext(WorkContext);
  // const { pathname } = useLocation();

  return (
    <WorkInfoWrapper {...firstInfo}>
      <Styled.WrapperPhonesBG>
        <Styled.WrapperPhones>
          {
          // pathname==="/works/hrm" ? dataHrmImg.map((item, index) => (
          //   <Styled.Phone
          //     key={idComponent + "." + index}
          //     size={!index ? "big" : "small"}
          //     img={`${item}`}
          //   />)) : 
            dataImg.map((item, index) => (
            <Styled.Phone
              key={idComponent + "." + index}
              size={!index ? "big" : "small"}
              img={`${item}`}
            />
          ))}
        </Styled.WrapperPhones>
      </Styled.WrapperPhonesBG>
    </WorkInfoWrapper>
  );
};

export default WorkSeventhBlock;
