import styled from "styled-components";

export const Wrapper = styled.div`
  height: 300px;

  display: flex;
  align-items: center;

  position: relative;
`;

export const BG = styled.div`
  position: absolute;

  width: 100%;
  height: 400px;

  top: 0%;

  background-image: url("/header_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 834px) {
    height: 300px;
    background-image: url("/header_bg_mobile.png");
  }
`;

export const WrapperInfo = styled.div`
  position: relative;
  z-index: 2;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  color: #ed0c32;
  margin-bottom: 8px;

  @media (max-width: 1024px) {
    font-size: 30px;
  }
`;

export const Text = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.025em;

  @media (max-width: 834px) {
    width: 100%;
    font-size: 15px;
  }
`;
