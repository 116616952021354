import { createContext, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { dataInsights } from "../../commonData/insights/data";
import LetsTalkComponent from "../../components/letsTalk";
import WrappperHeader from "../../wrappers/header";
import IPFirstBlock from "./firstBlock";
import IPFourthBlock from "./fourthBlock";
import IPSecondBlock from "./secondBlock";
import IPThirdBlock from "./thirdBlock";

export const InsightContext = createContext(dataInsights[0]);

const InsightPostPage = () => {
  const { id = "" } = useParams();

  const data = useMemo(() => dataInsights.find((item) => id === item.id), [id]);
  console.log('data :>> ', data);

  if (!data) {
    return <Navigate to="/insights" />;
  }

  return (
    <InsightContext.Provider value={data}>
      <WrappperHeader>
        <IPFirstBlock />
        <IPSecondBlock />
        <IPThirdBlock />
        <IPFourthBlock />
        <LetsTalkComponent />
      </WrappperHeader>
    </InsightContext.Provider>
  );
};

export default InsightPostPage;
