import styled from "styled-components";

interface IWrapper {
  changeWidth: number;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  column-gap: 24px;
  /* justify-content: space-between; */
  & > div {
    width: calc(50% - 20px);
  }
@media(max-width:991px){
  max-width: 664px;
  margin-right: auto;
  margin-left: auto;
}
  @media (max-width: ${({ changeWidth }) => `${changeWidth}px`}) {
    & > div {
      width: 100%;
    }
    & > div:last-child {
      display: none;
    }
  }
`;
