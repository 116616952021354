import styled from "styled-components";

export const Wrapper = styled.div`
  width: 612px;
  height: 197px;
  /* width: 335px; */
  padding: 30px 20px 20px 20px;
  position: relative;
  background: radial-gradient(
    68.91% 377.28% at 80.17% 28.36%,
    #111111 0%,
    #292929 100%
  );
  @media (max-width: 1400px) {
    /* width: 335px; */
    width: 100%;
    height: 240px;
  }
   @media (max-width: 767px) {
    width: 492px;
    margin-right:auto;
    margin-left: auto;
  }
   @media (max-width: 492px) {
    width: 335px;
  }
`;

export const Index = styled.div`
  position: absolute;
  top: 7px;
  left: 20px;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 49px;
  /* text-shadow: 0px 4px 16px #ffffff, 0px -4px 16px #ffffff; */
  text-shadow: 0px 4px 56px #FFFFFF;

  color: linear-gradient(180deg, #FFFFFF 0%, #A9A9A9 100%);
/* text-shadow: 0px 4px 56px 0px #FFFFFF; */

  @media (max-width: 1400px) {
    left: 7%;
  }
  @media (max-width: 991px) {
    left: 50%;
      top: 0px;
  }


`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
   @media (max-width: 767px) {
  font-size: 22px;
  }
`;

export const SubTitle = styled.div`
  font-size: 14px;
  margin: 8px 0 15px;
  font-weight: 300;
  height: 54px;
  @media (max-width: 1400px) {
    height: 70px;
  }
`;
export const ButtonWrapper = styled.div`
width: 100%;
max-width: 174px;
font-size: 16px;
@media (max-width: 991px) {
   margin-right: auto;
   margin-left:auto;
   max-width:272px;
  }

`