import { FC } from "react";
import { IDataAboutPeople } from "../../../../commonData/aboutPeople/types";
import * as Styled from "./style";

const ASBInfo: FC<IDataAboutPeople> = ({ about, name, photo, position }) => {
  return (
    <div>
      <Styled.WrapperImg photo={photo}>
        <Styled.About>{about}</Styled.About>
      </Styled.WrapperImg>
     { name ? <Styled.Name>{name}</Styled.Name> : <br/>}
      <Styled.Position>{position}</Styled.Position>
    </div>
  );
};

export default ASBInfo;
