import { FC, ReactNode } from "react";
import ButtonComponent from "../../../components/button";
import { scrollToLetsTalk } from "../../../components/letsTalk";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import WrapperSI from "../wrapper";
import * as Styled from "./style";

export interface ISISecondBlock {
  firstText: ReactNode;
  secondText: ReactNode;
}

const SISecondBlock: FC<ISISecondBlock> = ({ firstText, secondText }) => {
  const { width } = useSize();

  return (
    <WrapperContainer width="1320px">
      <WrapperSI>
        <Styled.Wrapper>
          <Styled.WrapperBlock
            width={width > 991 ? "calc(45% - 65px)" : "100%"}
          >
            <Styled.FirstText>{firstText}</Styled.FirstText>
            <Styled.ButtonWrapper>
              <ButtonComponent size="l" onClick={scrollToLetsTalk}>
              GET IN TOUCH
            </ButtonComponent>
            </Styled.ButtonWrapper>
            
          </Styled.WrapperBlock>
          <Styled.WrapperBlock
            width={width > 991 ? "calc(53% - 65px)" : "100%"}
          >
            <Styled.SecondText>{secondText}</Styled.SecondText>
            <Styled.LineUnderST />
          </Styled.WrapperBlock>
        </Styled.Wrapper>
      </WrapperSI>
    </WrapperContainer>
  );
};

export default SISecondBlock;
