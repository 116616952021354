import { FC, useId } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../../components/button";
import { IInfo } from "../../../../type";
import WrapperContainer from "../../../../wrappers/container";

import * as Styled from "./style";

export interface IPropsOWSBItem extends IInfo {
  tag: string[];
  imgFolder: string;
  to: string;
  bgColor: string;
  img1: string;
  img2: string;
}

const OWSBItem: FC<IPropsOWSBItem> = ({
  title,
  text,
  imgFolder,
  tag,
  to,
  bgColor,
  img1,
  img2
}) => {
  const idComponent = useId();
  const navigate = useNavigate();
  console.log('imgFolder :>> ', imgFolder);

  return (
    <WrapperContainer width="1000px">
      <Styled.Wrapper>
        <Styled.WrapperInfo>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Text>{text}</Styled.Text>
          <Styled.WrapperTags>
            {tag.map((item, index) => (
              <Styled.Tag key={idComponent + "OWSBItemTag" + index}>
                {item}
              </Styled.Tag>
            ))}
          </Styled.WrapperTags>
          <ButtonComponent size="l" onClick={() => navigate(`/works/${to}`)}>
            Case Study
          </ButtonComponent>
        </Styled.WrapperInfo>
        <Styled.WrapperImg bgColor={bgColor}>
          <Styled.FirstPhone imgFolder={imgFolder} img1={img1} img2={img2}/>
          <Styled.SecondPhone imgFolder={imgFolder} img1={img1} img2={img2}/>
        </Styled.WrapperImg>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default OWSBItem;
