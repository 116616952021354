import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import * as Styled from "./style";

export interface IPropsSTBItem {
  children: ReactNode;
}

const TCItem: FC<IPropsSTBItem> = ({ children }) => {
  return (
    <Styled.Wrapper>
      <div>{children}</div>
    </Styled.Wrapper>
  );
};

export default TCItem;
