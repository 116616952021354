import { ISSBInfo } from "./types";
import * as Styled from "./style";

export const data: ISSBInfo[] = [
  {
    title: (
      <Styled.WrapperTitleItem>
        <div>50+</div>
        <div>implementations</div>
      </Styled.WrapperTitleItem>
    ),
    text: "With a diverse team with years of leading design and development experience.",
  },
  {
    title: (
      <Styled.WrapperTitleItem>
        <div>large & medium</div>
        <div>projects</div>
      </Styled.WrapperTitleItem>
    ),
    text: "App Orbit has worked on projects with budgets from thousands to millions.",
  },
  {
    title: (
      <Styled.WrapperTitleItem>
        <div>3 year</div>
        <div>software warranty</div>
      </Styled.WrapperTitleItem>
    ),
    text: "We stand by every product we create with a 3 year warranty.",
  },
  {
    title: (
      <Styled.WrapperTitleItem>
        <div>24/7 product</div>
        <div>maintenance</div>
      </Styled.WrapperTitleItem>
    ),
    text: "A support team that is always on, ready to help night and day.",
  },
];
