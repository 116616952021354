import { useId } from "react";
import AFBIStatus from "./status";
import * as Styled from "./style";

const dataStatus = [
  { count: "20+", name: "professionals", text: "and growing" },
  { count: "20+", name: "solutions", text: "delivered" },
  { count: "10,000", name: "hours", text: "spent" },
];

const AFBInfo = () => {
  const idComponent = useId();

  return (
    <Styled.Wrapper>
      <Styled.WrapperText>
        <Styled.WrapperTitle>
          <div>We create apps that are beautiful to use and solve complex problems: </div>
          <div>
            users are at the heart of all we do.
          </div>
        </Styled.WrapperTitle>
        <Styled.Text>
          We are a digital product agency which combines the experience of a large custom software development team with leading edge thinking in product design, customer, user and employee experience.<br /><br/>
          We believe in working in multidisciplinary teams, with subject expert leads working within an agile framework, co-creating best in class applications for our clients.<br /><br/>
          We are restless in our pursuit of cutting-edge technology and best practice. Our portfolio of projects and clients represent emerging ventures, the digital transformation of enterprise organizations and applications to solve the most pressing customer and business problems.
        </Styled.Text>
      </Styled.WrapperText>
      <Styled.WrapperStatus>
        {dataStatus.map((item, index) => (
          <AFBIStatus key={idComponent + "." + index} {...item} />
        ))}
      </Styled.WrapperStatus>
    </Styled.Wrapper>
  );
};

export default AFBInfo;
