import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 85px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
  margin-bottom: 45px;
`;
