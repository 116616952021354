import { FC, ReactNode, useId, useMemo } from "react";
import useSize from "../../utils/useSize";
import TCItem from "./item";
import * as Styled from "./style";

interface IProps {
  data: ReactNode[];
  changeWidth?: number;
}

const TwoColumnComponent: FC<IProps> = ({ data, changeWidth=991 }) => {
  const idComponent = useId();
  const { width } = useSize();

  const dataFirstColumn = useMemo(() => {
    if (width > changeWidth) return data.filter((_, index) => !(index % 2));
    return data;
  }, [changeWidth, data, width]);

  return (
    <Styled.Wrapper changeWidth={changeWidth}>
      <div>
        {dataFirstColumn.map((children, index) => (
          <TCItem key={idComponent + ".First." + index}>{children}</TCItem>
        ))}
      </div>
      <div>
        {data
          .filter((_, index) => !((index % 2) - 1))
          .map((children, index) => (
            <TCItem key={idComponent + ".Second." + index}>{children}</TCItem>
          ))}
      </div>
    </Styled.Wrapper>
  );
};

export default TwoColumnComponent;
