import React from 'react';
import * as Styled from "./style";

const MainSection = () => {
    return (
      <>
      <Styled.TitleWrapper>
          <Styled.Title>Privacy Policy</Styled.Title>
      </Styled.TitleWrapper>
      <Styled.InfoContainer>
                <Styled.SubTitle>Our Privacy Policy</Styled.SubTitle>
                <Styled.Text>
                App Orbit,  a wholly owned subsidary of Hearts and Minds CRM Pty Ltd, ABN 26 622 643 216
                    {/* App 360, ABN 76 468 841 780, upholds your rights in accordance to the Australian Privacy Principles contained in the Privacy Act 1988. You can find more information about your privacy rights here; <b>https://www.oaic.gov.au/privacy/australian-privacy-principles.</b> */}
                </Styled.Text>
                <Styled.Text>App Orbit’s Privacy Policy outlines what personal information of yours we retain, to whom we disclose this information, how we use it, and how you can access your personal information retained by App Orbit. You can inform us to make changes to any inaccurate personal information or opt out of future communications.
                </Styled.Text>
                <Styled.SubTitleSmall>What personal information about you does App Orbit retain?</Styled.SubTitleSmall>
                <Styled.TextAdditional>At App Orbit, we design and develop custom software and applications for our clients. We collect information on a needs basis to satisfactorily perform our tasks, and this can include user, stakeholder, employer, and customer research. In these cases, names, contact details, IP addresses, device usage, and, if required, banking details are obtained.
                </Styled.TextAdditional>
                <Styled.TextAdditional>Our consulting services can sometimes require the disclosure of sensitive information. Under such circumstances, we will anonymize the source of the information, protecting the identity of disclosing individuals.
                </Styled.TextAdditional>
                 <Styled.SubTitleSmall>Personal information and user data</Styled.SubTitleSmall>
                <Styled.TextAdditional>App Orbit is involved in data design and usage as part of its delivery of software and applications. This may involve the use of sensitive personal data, which is used in accordance with the Australian Privacy Act and the respective Privacy Act for other legal jurisdictions as required.
                </Styled.TextAdditional>
                <Styled.TextAdditional>In the case of a data breach, App Orbit will notify the Office of the Australian Information Commissioner (Commissioner) or its equivalent body in other jurisdictions and the affected individuals at likely risk of serious harm in accordance with the Privacy Amendment (Notifiable Data Breaches) Act 2017.</Styled.TextAdditional>
                 <Styled.SubTitleSmall>Data Retention</Styled.SubTitleSmall>
                <Styled.TextAdditional>Personal data is retained by App Orbit as necessary for the duration of a specific business relationship. Data can be stored for longer to protect App Orbit against legal claims. We will respond to any reasonable requests to delete personal data.
                </Styled.TextAdditional>
                   <Styled.SubTitleSmall>Who will App Orbit disclose your personal information to?</Styled.SubTitleSmall>
                <Styled.TextAdditional>App Orbit does not disclose user or client personal information without prior consent and in accordance with Australian law. App Orbit takes reasonable steps to ensure any service providers and contractors comply with the Privacy Act, App Orbit’s Privacy Policy, and have their own appropriate privacy policies in place to ensure your rights are enforceable.
                </Styled.TextAdditional>
                <Styled.SubTitleSmall>Queries, Questions and complaints</Styled.SubTitleSmall>
                <Styled.TextAdditional>You have the right to reach out to App Orbit to request a copy of any of the personal information of yours we keep on file. Should you have any questions about this Privacy Policy or have concerns about how we handle your personal information, please reach out to us using the following contact details: hello@apporbit.co
                </Styled.TextAdditional>
                {/* <Styled.Address>
                    Attention Privacy Officer<br/>
                    Address: 36/4 Alexandra Drive, Camperdown<br/>
                    Email: <a href="mailto:hello@apporbit.co">HELLO@APPORBIT.CO</a><br/>
                    Phone: +61 (02) 8068 7086<br/>
                </Styled.Address>
                 <Styled.TextAdditional>In our response we will advise if we agree with your complaint or not and if we agree we will advise corrective action. If we do not satisfy your concerns, we will direct you to the Office of the Australian Information Commissioner on the following contact details:
                </Styled.TextAdditional>
                <Styled.Address>
                    Phone: 1300 363 992<br/>
                    Post: GPO Box 2999 Canberra ACT 2601<br/>
                    Fax: +61 2 9284 9666<br/>
                    Email: <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a><br/>
                 </Styled.Address> */}
      </Styled.InfoContainer>
      </>
  )
}

export default MainSection