import WrapperContainer from "../../../wrappers/container";
import SFBInfo from "./info";
import * as Styled from "./style";

const SoftwareFourthBlock = () => {
  return (
    <WrapperContainer
      width="100%"
      minWidth="1400px"
      position="end"
      padding="unset"
    >
      <Styled.Wrapper>
        <Styled.WrapperInfo>
          <SFBInfo />
        </Styled.WrapperInfo>
        <Styled.Img />
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default SoftwareFourthBlock;
