import { IDataSoftwareTechnologies } from "./types";
import aws from '../../assets/img/software/technologies/aws.png';
import azure from '../../assets/img/software/technologies/azure.png';
import js from '../../assets/img/software/technologies/js.png';
import vue from '../../assets/img/software/technologies/vue.png';
import react_js from '../../assets/img/software/technologies/react_js.png';
import flutter from '../../assets/img/software/technologies/flutter.png';
import angular from '../../assets/img/software/technologies/angular.png';
import node_js from '../../assets/img/software/technologies/node_js.png';
import ios from '../../assets/img/software/technologies/ios.png';
import linux from '../../assets/img/software/technologies/linux.png';
import windows from '../../assets/img/software/technologies/windows.png';
import sql from '../../assets/img/software/technologies/sql.png';
import oracle from '../../assets/img/software/technologies/oracle.png';
import mongodb from '../../assets/img/software/technologies/mongodb.png';
import c from '../../assets/img/software/technologies/c.png';
import dot_net from '../../assets/img/software/technologies/dot_net.png';
import swift from '../../assets/img/software/technologies/Swift.png';
import android from '../../assets/img/software/technologies/android.png';
import xamarin from '../../assets/img/software/technologies/xamarin.png';
import java from '../../assets/img/software/technologies/java.png';
import python from '../../assets/img/software/technologies/python.png';


export const dataSoftwareTechnologies: IDataSoftwareTechnologies[] = [
  {
    title: "Cloud Ops AWS (Amazon Web Services)  and Azure",
    imgs: [aws, azure],
  },
  {
    title: "JavaScript , Vue, React, Flutter, Angular, Node",
    imgs: [
     js,
      vue,
      react_js,
      flutter,
      angular,
      node_js,
    ],
  },
  {
    title: "IOS, Linux, Windows operating systems",
    imgs: [
      ios,
      linux,
      windows,
    ],
  },
  {
    title: "Database development Microsoft SQL Server, Oracle, MongoDB",
    imgs: [
      sql,
      oracle,
      mongodb,
    ],
  },
  {
    title: "C# development / .NET developer",
    imgs: [c, dot_net],
  },
  {
    title: "Mobile apps (Swift, iOS, Android, Xamarin, React Native)",
    imgs: [
      swift,
      ios,
      android,
      xamarin,
      react_js,
    ],
  },
  {
    title: "Java",
    imgs: [java],
  },
  {
    title: "Python",
    imgs: [python],
  },
];
