import { FC, ReactNode } from "react";
import HeaderComponent from "../../components/header";
import * as Styled from "./style";
import FooterComponent from "../../components/footer";

interface IProps {
  children: ReactNode;
}

const WrappperHeader: FC<IProps> = ({ children }) => {
  return (
    <Styled.Wrapper>
      <HeaderComponent />
      {children}
      <FooterComponent />
    </Styled.Wrapper>
  );
};

export default WrappperHeader;
