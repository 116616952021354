const FirstBlockWebApps = () => {
  return (
    <div>
      <div>
     Some of the leading advantages of a web app is that it is fast to build and deploy, accessible across all devices, offers app interoperability and search engine discovery. A web app is great solution for desktop users. This makes a web app a great choice for;
      </div>
      <br />
     
      <ul>
        <li>Desktop usage.</li><br/>
        <li>Search engine discoverability.</li><br/>
              <li>Speed to market.</li><br />
              <li>Affordability.</li>
          </ul>
           <div>
Web apps don’t have the full features of a native app such as push notifications and location. They are slower, less responsive and less interactive and are not considered as effective as native and hybrid apps for building loyalty but they can work very well with smaller apps and bypass the need for app store listing.
      </div>
          
    </div>
  );
};


export default FirstBlockWebApps;