import React from 'react'
import WrappperHeader from '../../wrappers/header'
import LetsTalkComponent from '../../components/letsTalk'
import MainSection from './mainSection'

const PrivacyPolicy = () => {
  return (
      <WrappperHeader>
          <MainSection />
          
    <LetsTalkComponent />
    </WrappperHeader>
  )
}

export default PrivacyPolicy