import styled from "styled-components";

export const Wrapper = styled.div`
  background: #151515;
  padding: 30px;
  margin-top: 5px;
  position: relative;
  z-index: 2;

  @media (max-width: 1200px) {
    margin-top: 170px;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 20px;
`;
