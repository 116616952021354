import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 70px 0;
  & > div {
    margin-bottom: 40px;
  }

  & > div :last-child {
    margin-bottom: unset;
  }
`;
