import styled from "styled-components";

export const Wrapper = styled.div`
  width: 25%;
  height: fit-content;
  position: sticky;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 100px 20px;

  @media (max-width: 1600px) {
    width: 15%;
  }

  @media (max-width: 1400px) {
    width: 250px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Item = styled.div`
  padding: 12px 0;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;
