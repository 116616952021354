import { useContext } from "react";
import { InsightContext } from "..";
import useSize from "../../../utils/useSize";
import WrapperContainer from "../../../wrappers/container";
import * as Styled from "./style";

const IPSecondBlock = () => {
  const { text } = useContext(InsightContext);

  const { width } = useSize();


  return (
    <WrapperContainer width={width > 1200 ? "900px" : "730px"}>
      <Styled.Wrapper>
        <Styled.Text>{text}</Styled.Text>
      </Styled.Wrapper>
    </WrapperContainer>
  );
};

export default IPSecondBlock;
