import { FC, useCallback, useContext } from "react";
import ButtonComponent from "../../../components/button";
import useSize from "../../../utils/useSize";
import { scrollToLetsTalk } from "../../../components/letsTalk";
import { MainContext } from "..";
import * as Styled from "./style";
import headerLine from "../../../assets/img/header-line.png";
import videBg from "../../../assets/img/main/video.mp4";
import firstStep from "../../../assets/img/main/firstStep.png";

const MainFB: FC = () => {
  const { setStep } = useContext(MainContext);
  const { width } = useSize();

  const onClickGetInTouch = useCallback(() => {
    if (width > 1400) {
      setStep(4);
      return;
    }

    scrollToLetsTalk();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <Styled.Wrapper>
      <Styled.WrapperVideo>
        <video
          poster={firstStep}
          preload="none"
          muted
          loop
          autoPlay
        >
          <source src={videBg} type="video/mp4" />
        </video>
      </Styled.WrapperVideo>
      {/* <WrapperContainer padding="unset"> */}
        <Styled.WrapperInfo>
          <img src={headerLine} alt="line" />
          <Styled.Title>Apps designed and engineered for growth</Styled.Title>
          <Styled.Text>
          We design and develop apps for startups, scaleups, product businesses, service design and platform organisations.
        </Styled.Text>
        <Styled.ButtonWrapper>
            <ButtonComponent
            size="l"
            block={width < 480}
            onClick={onClickGetInTouch}
          >
            get in touch
          </ButtonComponent>
        </Styled.ButtonWrapper>
        
        </Styled.WrapperInfo>
      {/* </WrapperContainer> */}
    </Styled.Wrapper>
  );
};

export default MainFB;
