import styled from "styled-components";

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;

`;

export const Data = styled.div`
  font-weight: 400;
  font-size: 14px;

`;

export const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1300px;
  border-bottom: 2px solid #484848;

`;
