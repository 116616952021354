import { FC } from "react";
import * as Styled from "./style";

interface IProps {
  count: string;
  name: string;
  text: string;
}

const AFBIStatus: FC<IProps> = ({ count, name, text }) => {
  return (
    <Styled.Wrapper>
      <div>
        <Styled.WrapperTitle>
          <div>{count}</div>
          <div>{name}</div>
        </Styled.WrapperTitle>
        <Styled.Text>{text}</Styled.Text>
      </div>
    </Styled.Wrapper>
  );
};

export default AFBIStatus;
