import styled from "styled-components";

export const Wrapper = styled.div`
padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 1400px) {
    height: fit-content;
    /* margin: 50px 0; */
  }
   @media (max-width: 500px) {
    padding-top: 30px;
  }
`;

export const Title = styled.div`
  max-width: 850px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
   @media (max-width: 991px) {
    font-size: 24px;
    width: 46%;
  }
  @media (max-width: 767px) {
   
    width: 65%;
  }
  @media (max-width: 699px) {
    width: 70%;
    font-size: 40px;
  }
  @media (max-width: 500px) {
    width: 100%;
    font-size: 25px;
    text-align: left;
    margin-left: 24px;
  }
`;

export const WrapperButton = styled.div`
  margin: 11px auto;

`;
export const ButtonWork=styled.button`
  width: 160px;
  height: 43px;
  background-color: #ED0C32;
  border: 1px solid #ED0C32;
  font-size:16px;
  padding:10px 30px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  &:hover{
    background-color: #000;
  }
`
