import {useId, useState } from "react";
import { dataLab } from "../../../../commonData/lab/data";
import LSBCItem from "./item";
import * as Styled from "./style";


const LSBContent = () => {
  const idComponent = useId();
  const [idOpenPicker, setIdOpenPicker]= useState("");

  return (
    <Styled.Wrapper>
      {dataLab.map((item, index) => (
        <LSBCItem
          key={idComponent + "Item" + index}
          {...item}
          indexItem={`${String(index + 1).padStart(2, "0")}.`}
          idOpenPicker={idOpenPicker}
          setIdOpenPicker={setIdOpenPicker}
        />
      ))}
    </Styled.Wrapper>
  );
};

export default LSBContent;
