import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #151515;
  padding: 35px;
  max-width: 1676px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const WrapperTitleItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;

  & > div {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
    white-space: nowrap;
  }

  & > div:first-child {
    color: #ed0c32;
  }
`;
