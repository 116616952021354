import { IInfo } from "../../../../type";

export const data: IInfo[] = [
  {
    title: "",
    text: (
      <div>
        App Orbit customized software team have decades of experience in full
        stack start up and scale up development.
        <br />
        <br />
        App Orbit customised software solutions are chosen by clients seeking:
      </div>
    ),
  },
  {
    title: "Competitive Edge",
    text: "Achieve a competitive edge through a more unique customer experience, greater operational agility and productivity gains.",
  },
  {
    title: "Effectiveness",
    text: "Solutions designed around your business’s unique objectives, they are more fit for purpose, and fully integrated. ",
  },
  {
    title: "Digital Maturity",
    text: "Making the leap from low digital maturity, 'the doing of disparate digital things', to high digital maturity, where they become a 'truly integrated digital organisation'.",
  },
];
