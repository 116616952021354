import { FC, useId } from "react";
import CheckMark from "../../../../assets/img/checkMark";
import { IApproach } from "../../../../commonData/ourWork/types";
import * as Styled from "./style";

const WSBItem: FC<IApproach> = ({ title, dates, tasks, colors }) => {
  const idComponent = useId();
  return (
    <Styled.Wrapper colorBG={colors[0]}>
      <Styled.Title>{title}</Styled.Title>

      <Styled.WrapperDate>
        {dates.map((value, index) => (
          <Styled.DateItem
            key={idComponent + ".Date." + index}
            color={colors[1]}
          >
            {value}
          </Styled.DateItem>
        ))}
      </Styled.WrapperDate>

      {tasks.map((value, index) => (
        <Styled.WrapperTask key={idComponent + ".Task." + index}>
          <Styled.TaskTitle>{value}</Styled.TaskTitle>
          <CheckMark fill={colors[1]} />
        </Styled.WrapperTask>
      ))}
    </Styled.Wrapper>
  );
};

export default WSBItem;
