import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #ed0c32;
  height: 400px;

  display: flex;
  padding: 45px;

  overflow: hidden;

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    height: unset;

    padding: 12px;
  }
`;

export const WrapperInfo = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperPhones = styled.div`
  width: 45%;
  height: 400px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

interface IPhone {
  size: "small" | "big";
  img: string;
}

export const Phone = styled.div<IPhone>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${({ size }) =>
    `translate(${size === "small" ? "0%, -38%" : "-50%, -43%"})`};
  width: ${({ size }) => (size === "small" ? "224px" : "228px")};
  height: ${({ size }) => (size === "small" ? "453px" : "463px")};

  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 768px) {
    transform: ${({ size }) =>
      `translate(${size === "small" ? "-30%, -28%" : "-65%, -33%"})`};
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;

  @media (max-width: 1200px) {
    font-size: 30px;
    line-height: 32px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 15px;
  margin: 16px 0;
`;
export const ButtonWrapper = styled.div`
max-width: 173px;
font-weight: 600;
`