import * as Styled from "./style";
import logo from "../../../../assets/img/header/app.orbit.svg";
import { dataHeader } from "../../data";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../../button";
import { FC, useId } from "react";

interface IProps {
  setBurgerStatus: (status: number) => void;
}

const BMNav: FC<IProps> = ({ setBurgerStatus }) => {
  const idComponent = useId();
  const navigate = useNavigate();

  return (
    <Styled.WrapperSize>
      <Styled.Wrapper>
        <Styled.WrapperLogo onClick={() => navigate("/")}>
          <img src={logo} alt="logo"/>
        </Styled.WrapperLogo>
        {dataHeader.map(({ title, to }, index) => (
          <Styled.NavText
            key={idComponent + "." + index}
            onClick={() => navigate(to)}
          >
            {title}
          </Styled.NavText>
        ))}
        <Styled.ButtonWrapper>
          <ButtonComponent onClick={() => setBurgerStatus(2)} size="l">
          GET IN TOUCH
        </ButtonComponent>
        </Styled.ButtonWrapper>
        <Styled.EmailContainer>
          <a className="red" href="mailto:hello@apporbit.co">HELLO@APPORBIT.CO &nbsp;/</a>&nbsp;
          <a href="https://www.linkedin.com/company/app-360-agency/" className="red" target="_blank" rel="noreferrer">LINKED IN</a>
        </Styled.EmailContainer>
        
      </Styled.Wrapper>
    </Styled.WrapperSize>
  );
};

export default BMNav;
