import styled from "styled-components";

export const Wrapper = styled.div`
  width: 75%;
  background: #1f1f1f;
  padding: 110px 90px;

  @media (max-width: 1600px) {
    width: 100%;
  }

  /* @media (max-width: 1400px) {
    width: calc(100% - 250px);
  } */

  @media (max-width: 1024px) {
    padding: 50px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;
