import styled from "styled-components";

export const Wrapper = styled.div`
  width: 70%;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const WrapperText = styled.div`
  max-width: 754px;
  padding: 0 40px;
  margin: 64px 0 56px;
  @media(max-width:767px){
    padding: 0 24px;
    max-width: 750px;
  }
`;

export const WrapperTitle = styled.div`
max-width: 600px;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;

  & > div:first-child {
    color: #ed0c32;
  }

  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 767px) {
    font-size: 26px;
    max-width: 750px
  }
`;

export const Text = styled.div`
max-width: 600px;
  margin-top: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  @media (max-width: 1024px) {
    font-size: 15px;
  }
   @media (max-width: 767px) {
    font-size: 16px;
    max-width: 750px
  }
`;

export const WrapperStatus = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #151515;
  padding: 30px 85px;

  @media (max-width: 1600px) {
    padding: 30px;
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
