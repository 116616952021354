import { IDataHeader } from "./types";

export const dataHeader: IDataHeader[] = [
  {
    title: "CUSTOMISED SOFTWARE",
    to: "/software",
  },
  {
    title: "OUR WORK",
    to: "/works",
  },
  {
    title: "SERVICES",
    to: "/services",
  },
  {
    title: "LAB",
    to: "/lab",
  },
  {
    title: "ABOUT",
    to: "/about",
  },
  {
    title: "INSIGHTS",
    to: "/insights",
  },
];
